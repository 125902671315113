import { useState } from "react";
import {
  Button,
  Form,
  Modal,
  Radio,
  Header,
  Input,
  Checkbox,
  Message,
  Popup,
} from "semantic-ui-react";
import { useRequest } from "resources/hooks";
import { Column, Row, VerticalDivider } from "components/common";
import * as optionService from "services/options";
import "styles/common.scss";
import {
  amountToCentimes,
  centimesToAmount,
} from "../../resources/helpers/PriceHelper";

const DEFAULT_CHOICE = {
  label: "",
  price: null,
  originalPrice: null,
  available: true,
  labelError: null,
};

const OptionsModal = ({
  optionUpdate,
  onOptionUpdate,
  productId,
  closeModal,
}) => {
  const [label, setLabel] = useState(optionUpdate?.label ?? "");
  const [type, setType] = useState(optionUpdate?.type ?? "SINGLE");
  const [required, setRequired] = useState(optionUpdate?.required ?? false);
  const [minimum, setMinimum] = useState(optionUpdate?.minimum ?? undefined);
  const [maximum, setMaximum] = useState(optionUpdate?.maximum ?? undefined);
  const [choices, setChoices] = useState(
    optionUpdate?.productOptionChoices ?? []
  );

  const [labelError, setLabelError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const create = useRequest({
    service: optionService.createOption,
    requestOnInit: false,
  });
  const assignToProduct = useRequest({
    service: optionService.assignOption,
    requestOnInit: false,
  });
  const productOptionUpdate = useRequest({
    service: optionService.productOptionUpdate,
    requestOnInit: false,
  });

  const areChoicesValid = () => {
    let hasErrors = false;
    let message = "";

    if (choices.length === 0) {
      return { hasErrors: true, message: "Au minimum un choix est requis" };
    }

    setChoices(
      choices.map((choice) => {
        if (!choice.label) {
          hasErrors = true;
          message = "Certains choix sont vides";
          return { ...choice, labelError: { content: "Obligatoire" } };
        } else {
          return choice;
        }
      })
    );

    return { hasErrors, message };
  };

  const handleOptionCreation = async () => {
    let hasErrors = false;
    if (!label) {
      setLabelError({
        content: "Obligatoire",
      });
      hasErrors = true;
    }

    const { hasErrors: choicesError, message } = areChoicesValid();
    setErrorMessage(message);

    if (maximum > choices.length) {
      setErrorMessage("Le maximum ne doit pas dépasser le nombre de choix");
      hasErrors = true;
    }

    if (choicesError) hasErrors = true;

    if (hasErrors) return;

    const cleanedChoices = choices.map((choice) => ({
      label: choice.label,
      price: choice.price ?? 0,
      originalPrice: choice.originalPrice ?? 0,
      available: choice.available,
    }));

    if (!optionUpdate) {
      const result = await create.request({
        params: {
          label,
          type,
          required,
          minimum: minimum ?? undefined,
          maximum: maximum ?? undefined,
          choices: cleanedChoices,
        },
      });

      if (result.response) {
        const result2 = await assignToProduct.request({
          params: { productId, optionId: result.response.id },
        });

        if (result2.response) {
          closeModal({ withReload: true });
        }
      }
    } else {
      const result = await productOptionUpdate.request({
        params: {
          productId,
          optionId: optionUpdate.id,
          label,
          type,
          required,
          minimum: minimum ?? undefined,
          maximum: maximum ?? undefined,
          choices: cleanedChoices,
        },
      });

      if (result.response) {
        closeModal({ withReload: true });
      }
    }
  };

  const handleChoiceUpdate = (value, type, index) => {
    let toUpdate = [...choices];
    if (type === "label") toUpdate[index].labelError = null;
    if (type === "price" || type === "originalPrice")
      toUpdate[index][type] = Number(value);
    else toUpdate[index][type] = value;
    setChoices(toUpdate);
  };

  const handleTypeChange = (value) => {
    setMinimum(null);
    setMaximum(null);
    setType(value);
  };

  const handleRangeChange = (value, type) => {
    const newValue = parseInt(value);
    if (type === "minimum") {
      if (maximum === null || value <= maximum)
        setMinimum(value ? newValue : null);
    } else if (type === "maximum") {
      if (minimum === null || value >= minimum)
        setMaximum(value ? newValue : null);
    }
  };

  return (
    <Modal onClose={closeModal} open={true} className="options-modal">
      <Modal.Header>Options de produit</Modal.Header>

      <Modal.Content className="options-modal">
        <Row fluid>
          <Column style={{ flexBasis: "40%" }}>
            <Form>
              <Form.Field>
                <Header as="h3">Nom</Header>
                <Form.Input
                  placeholder="Nouvelle option"
                  value={label}
                  onChange={(e, { value }) => {
                    setLabelError(null);
                    setLabel(value);
                  }}
                  error={labelError}
                />
              </Form.Field>
            </Form>

            <Form style={{ marginTop: "2rem" }}>
              <Header as="h3">Type</Header>
              <Form.Field>
                <Radio
                  label="Selection à choix unique"
                  name="radioGroup"
                  value="SINGLE"
                  checked={type === "SINGLE"}
                  onChange={(e, { value }) => handleTypeChange(value)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Selection à choix multiple"
                  name="radioGroup"
                  value="MULTIPLE"
                  checked={type === "MULTIPLE"}
                  onChange={(e, { value }) => handleTypeChange(value)}
                />
              </Form.Field>
            </Form>

            <Form style={{ marginTop: "2rem" }}>
              <Header as="h3">Divers</Header>
              <Form.Field>
                <Checkbox
                  checked={required}
                  onChange={(e, { checked }) => setRequired(checked)}
                  toggle
                  label="Requis pour la commande"
                />
              </Form.Field>

              {type === "MULTIPLE" && (
                <Form.Group inline>
                  <Form.Field>
                    <Input
                      value={minimum}
                      onChange={(e, { value }) =>
                        handleRangeChange(value, "minimum")
                      }
                      placeholder="Min"
                      type="number"
                      min="0"
                      className="choice-range-input"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      value={maximum}
                      onChange={(e, { value }) =>
                        handleRangeChange(value, "maximum")
                      }
                      placeholder="Max"
                      type="number"
                      min={minimum}
                      className="choice-range-input"
                    />
                  </Form.Field>
                </Form.Group>
              )}
            </Form>
          </Column>

          <VerticalDivider />

          <Column fluid>
            <Row align="center" fluid justify="space-between">
              <Header as="h3">Choix selection</Header>
              <Button
                icon="plus"
                color="blue"
                content="Nouveau choix"
                basic
                style={{ marginLeft: "1rem" }}
                onClick={() => setChoices([...choices, { ...DEFAULT_CHOICE }])}
              />
            </Row>

            {choices.map((choice, index) => (
              <Row
                className="choice-row"
                childrenStyle={{ margin: "0 1rem " }}
                align="center"
                key={`choice-${index}`}
              >
                {type === "SINGLE" ? (
                  <Radio checked={false} disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )}

                <Input
                  value={choice.label}
                  onChange={(e, { value }) =>
                    handleChoiceUpdate(value, "label", index)
                  }
                  autoFocus
                  placeholder="Nouveau choix"
                  error={choice.labelError}
                />

                <Popup
                  content="Prix public"
                  inverted
                  trigger={
                    <Input
                      value={centimesToAmount(choice.price)}
                      onChange={(e, { value }) =>
                        handleChoiceUpdate(
                          isNaN(Number(value))
                            ? 0
                            : amountToCentimes(Number(value)),
                          "price",
                          index
                        )
                      }
                      label="€"
                      fluid
                      placeholder="Prix"
                      type="number"
                      step="any"
                      min="0"
                      className="choice-price-input"
                    />
                  }
                />

                <Popup
                  content="Prix restaurant"
                  inverted
                  trigger={
                    <Input
                      value={centimesToAmount(choice.originalPrice)}
                      onChange={(e, { value }) =>
                        handleChoiceUpdate(
                          isNaN(Number(value))
                            ? 0
                            : amountToCentimes(Number(value)),
                          "originalPrice",
                          index
                        )
                      }
                      label="€"
                      fluid
                      placeholder="Prix"
                      type="number"
                      step="any"
                      min="0"
                      className="choice-price-input"
                    />
                  }
                />

                <Checkbox
                  checked={choice.available}
                  onChange={(e, { checked }) =>
                    handleChoiceUpdate(checked, "available", index)
                  }
                  toggle
                />

                <Button
                  icon="trash"
                  negative
                  basic
                  onClick={() =>
                    setChoices(choices.filter((c, j) => j !== index))
                  }
                />
              </Row>
            ))}
          </Column>
        </Row>

        {errorMessage && (
          <Message negative>
            <Message.Header>Erreur</Message.Header>
            <p>{errorMessage}</p>
          </Message>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          color="black"
          onClick={closeModal}
          loading={create.loading || assignToProduct.loading}
        >
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          positive
          onClick={handleOptionCreation}
          loading={create.loading || assignToProduct.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default OptionsModal;
