import { Checkbox } from "semantic-ui-react";
import { Clickable, Column, Row } from "../common";
import { ReactComponent as CartIcon } from "resources/svg/minimum-required.svg";
import { ReactComponent as DeliveryFee } from "resources/svg/delivery-fee.svg";
import { ReactComponent as DeliveryTime } from "resources/svg/delivery-time.svg";
import "styles/DeliveryAreas.scss";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";

const AreaCard = ({ area, onClick, handleToggleChange }) => {
  return (
    <Clickable className="area-card" highlightOnHover onClick={onClick}>
      <div className="color" style={{ backgroundColor: area.color }} />
      <Row justify="space-between" align="center">
        <Column>
          <span className="label">{area.label}</span>
          <Row childrenMargin="small" className="area-parameters">
            <Row align="flex-end" className="area-parameter">
              <CartIcon />
              <span>{renderCentimesAsAmount(area.orderMinimumAmount)}</span>
            </Row>
            <Row align="flex-end" className="area-parameter">
              <DeliveryFee />
              <span className="small-margin">
                {renderCentimesAsAmount(area.deliveryFee)}
              </span>
            </Row>
            <Row align="flex-end" className="area-parameter">
              <DeliveryTime />
              <span className="small-margin">{`${area.deliveryTime}mn`}</span>
            </Row>
          </Row>
        </Column>

        <Checkbox
          checked={area.active}
          toggle
          onChange={(e, { checked }) => {
            e.stopPropagation();
            handleToggleChange(checked);
          }}
        />
      </Row>
    </Clickable>
  );
};

export default AreaCard;
