const Divider = ({ fluid, dark, darker, margin, children }) => {
  return children ? (
    <div
      className="separator-with-children"
      style={{
        width: fluid && "100%",
        marginTop: margin,
        marginBottom: margin,
      }}
    >
      <div
        className={`separator${dark ? " dark" : ""}${darker ? " darker" : ""}`}
      />
      <span>{children}</span>
      <div
        className={`separator${dark ? " dark" : ""}${darker ? " darker" : ""}`}
      />
    </div>
  ) : (
    <div
      className={`separator${dark ? " dark" : ""}${darker ? " darker" : ""}`}
      style={{
        width: fluid && "100%",
        marginTop: margin,
        marginBottom: margin,
      }}
    />
  );
};

export default Divider;
