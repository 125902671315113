import { OrderRow } from ".";
import { Column, Divider, Row } from "../../common";

const byEstimatedPickupAt = (a, b) =>
  a.restaurantOrders[0].estimatedPickupAt >
  b.restaurantOrders[0].estimatedPickupAt
    ? 1
    : -1;

const PreparationTab = ({ orders }) => {
  const deliverymanMap = orders.reduce((acc, order) => {
    const deliverymanId = order.orderDelivery.deliveryman.id;
    if (acc.has(deliverymanId)) {
      const deliverymanOrders = acc.get(deliverymanId, [order]);
      acc.set(deliverymanId, [...deliverymanOrders, order]);
    } else {
      acc.set(deliverymanId, [order]);
    }

    return acc;
  }, new Map());

  let ordersByDeliveryman = Array.from(deliverymanMap.entries());

  for (const index in ordersByDeliveryman) {
    ordersByDeliveryman[index][1] =
      ordersByDeliveryman[index][1].sort(byEstimatedPickupAt);
  }

  return (
    <Column childrenMargin="medium" className="order-rows">
      {ordersByDeliveryman.map(([deliverymanId, orders]) => (
        <Column childrenMargin="small" key={deliverymanId}>
          <Row justify="space-between" align="center" childrenMargin="tiny">
            <h3 style={{ whiteSpace: "nowrap" }}>
              {`${orders[0].orderDelivery.deliveryman.firstname} ${orders[0].orderDelivery.deliveryman.lastname}`}
            </h3>
            <Divider fluid dark />
            <span className="circular-count">{orders.length}</span>
          </Row>
          {orders.map((order) => (
            <OrderRow
              order={order}
              withEstimatedPickupTime
              displayRestaurantAcceptation
              key={order.id}
              // deliverymanColorMap={deliverymanColorMap}
            />
          ))}
        </Column>
      ))}
    </Column>
  );
};

export default PreparationTab;
