import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { OrderContext } from "../../../contexts/orders";
import { useRequest } from "../../../resources/hooks";
import * as OrderService from "../../../services/orders";
import { Column, Quantity, Row, WithLoader, Divider } from "../../common";

const CancelProductsModal = ({ orderId, closeModal }) => {
  const { dispatch } = useContext(OrderContext);

  const getOrder = useRequest({
    service: () => OrderService.fetchOrder(orderId),
  });

  const updateProductsStatus = useRequest({
    service: OrderService.updateProductsStatus,
    requestOnInit: false,
  });

  const [removedProductIds, setRemovedProductsIds] = useState([]);

  const order = getOrder.response;

  useEffect(() => {
    if (order) {
      setRemovedProductsIds(
        order?.orderProducts
          .filter((product) => product.status === "CANCELED")
          .map((product) => product.id)
      );
    }
  }, [order?.id]);

  const handleUpdate = (type, productId) => {
    if (type === "REMOVE") {
      setRemovedProductsIds([...removedProductIds, productId]);
    } else if (type === "RESTORE") {
      setRemovedProductsIds(
        removedProductIds.filter(
          (removedProductId) => removedProductId !== productId
        )
      );
    }
  };

  const handleProductsStatusUpdate = async () => {
    const result = await updateProductsStatus.request({
      params: {
        orderId,
        orderProducts: order.orderProducts.map((product) => ({
          id: product.id,
          status: removedProductIds.some(
            (removedProductId) => removedProductId === product.id
          )
            ? "CANCELED"
            : "DELIVERED",
        })),
      },
    });
    if (result.response) {
      dispatch({ type: "UPDATE_ORDER", order: result.response });
    }
    closeModal();
  };

  return (
    <Modal
      dimmer="blurring"
      open
      onClose={closeModal}
      size="small"
      id="cancel-product-modal"
    >
      <Modal.Header>Retirer des produits</Modal.Header>
      <WithLoader loading={getOrder.loading} modalMode>
        <Modal.Content>
          <Column childrenMargin="small">
            {order?.orderProducts.map((product) => {
              const isProductRemoved = removedProductIds.some(
                (removedProductId) => removedProductId === product.id
              );
              return (
                <Row
                  className="product-row"
                  align="center"
                  childrenMargin="tiny"
                  fluid
                >
                  <Quantity>{product.quantity}</Quantity>
                  <span className={isProductRemoved ? "removed" : ""}>
                    {product.label}
                  </span>
                  <Divider fluid dark />
                  {isProductRemoved ? (
                    <Button
                      positive
                      basic
                      onClick={() => handleUpdate("RESTORE", product.id)}
                    >
                      Restaurer
                    </Button>
                  ) : (
                    <Button
                      negative
                      basic
                      onClick={() => handleUpdate("REMOVE", product.id)}
                    >
                      Retirer
                    </Button>
                  )}
                </Row>
              );
            })}
          </Column>
        </Modal.Content>
      </WithLoader>
      <Modal.Actions>
        <Button onClick={closeModal}>Annuler</Button>
        <Button
          negative
          disabled={updateProductsStatus.loading}
          loading={updateProductsStatus.loading}
          onClick={handleProductsStatusUpdate}
        >
          Mettre à jour les produits
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CancelProductsModal;
