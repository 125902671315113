import React, { useContext, useEffect } from "react";
import { Label } from "semantic-ui-react";
import { OrderContext } from "../../../contexts/orders";
import { useRequest } from "../../../resources/hooks";
import { fetchPlannedOrders } from "../../../services/orders";
import { WithLoader } from "../../common";
import { OrderRow } from ".";

const PlannedTab = () => {
  const {
    state: { plannedOrders },
    dispatch,
  } = useContext(OrderContext);

  const getPlanned = useRequest({ service: fetchPlannedOrders });

  useEffect(() => {
    if (getPlanned.response)
      dispatch({
        type: "SET_PLANNED_ORDERS",
        plannedOrders: getPlanned.response,
      });
  }, [getPlanned.response]);

  return (
    <WithLoader loading={getPlanned.loading}>
      <div className="order-rows">
        {plannedOrders.map((order) => (
          <OrderRow
            order={order}
            lastElement={
              <Label
                color={order.status === "CANCELED" ? "red" : "green"}
                className="status"
                basic
              >
                {order.status === "CANCELED" ? "Annulée" : "Prévue"}
              </Label>
            }
          />
        ))}
      </div>
    </WithLoader>
  );
};

export default PlannedTab;
