import { useEffect, useState } from "react";


const OFFSET_TOP = 170; // px
const ORDER_ROW_HEIGHT = 60; // px
const MAX_ROWS_BY_PAGE = Math.floor(
  (window.innerHeight - OFFSET_TOP) / ORDER_ROW_HEIGHT
);

const usePagination = ({ request }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    request.request({
      params: {
        skip: (newPage - 1) * MAX_ROWS_BY_PAGE,
        limit: MAX_ROWS_BY_PAGE,
      },
    });
  };

  const resetTable = () => {
    setCurrentPage(1);
    request.request({
      params: {
        skip: (1 - 1) * MAX_ROWS_BY_PAGE,
        limit: MAX_ROWS_BY_PAGE,
      },
    });
  };

  useEffect(() => {
    handlePageChange(1);
  }, []);

  return {
    currentPage,
    handlePageChange,
    resetTable,
    MAX_ROWS_BY_PAGE,
  };
};

export default usePagination;
