import React, { useContext } from "react";
import "./App.css";
import { AuthContext, AuthProvider } from "./contexts/auth";
import { OrderProvider } from "./contexts/orders";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginPage from "./components/LoginPage";
import AdminInterface from "./components/AdminInterface";
import "semantic-ui-css/semantic.min.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "normalize.css";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <WithAutoConnect />
      </AuthProvider>

      <ToastContainer />
    </Router>
  );
}

const WithAutoConnect = () => {
  const {
    state: { isAutoConnecting },
  } = useContext(AuthContext);

  if (isAutoConnecting) {
    return "Chargement ...";
  }

  return (
    <Switch>
      <Route exact path="/login">
        <LoginPage />
      </Route>

      <PrivateRoute path="*">
        <OrderProvider>
          <AdminInterface />
        </OrderProvider>
      </PrivateRoute>
    </Switch>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  const {
    state: { currentUser },
  } = useContext(AuthContext);

  return !currentUser ? (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  ) : (
    <Route {...rest} render={() => children} />
  );
};

export default App;
