import { useEffect } from "react";

const useDebounce = (value, { minimumChars, onTimeout }) => {
  // Wait for 500ms keyboard pause to execute (disable API GET smashing)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (value && value.length >= (minimumChars || 3)) onTimeout();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);
};

export default useDebounce;
