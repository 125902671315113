import { useState } from "react";
import { Button, Form, Icon, Message, Search } from "semantic-ui-react";
import * as UserService from "services/users";
import * as CartService from "services/carts";
import { useDebounce, useInput, useRequest } from "resources/hooks";
import { AddressSearch, Row, Column } from "components/common";

const UserInformations = ({ onUserSubmit }) => {
  // const [searchUserLabel, setSearchUserLabel] = useState("");
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [searchUserPhone, setSearchUserPhone] = useState("");

  const firstname = useInput("");
  const lastname = useInput("");
  const phone = useInput("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState(null);

  // const searchUsers = useRequest({
  //   service: UserService.searchUsers,
  //   requestOnInit: false,
  // });
  const searchUsersByPhone = useRequest({
    service: UserService.searchUsersByPhone,
    requestOnInit: false,
  });
  const createOrderUser = useRequest({
    service: UserService.createUser,
    requestOnInit: false,
  });
  const createCart = useRequest({
    service: CartService.createCart,
    requestOnInit: false,
  });
  const getExistingCart = useRequest({
    service: CartService.getExistingCart,
    requestOnInit: false,
    hideToastOnError: true,
  });

  // useDebounce(searchUserLabel, {
  //   onTimeout: (type) => {
  //     searchUsers.request({ params: { text: searchUserLabel } });
  //   },
  // });

  useDebounce(searchUserPhone, {
    onTimeout: (type) => {
      searchUsersByPhone.request({
        params: { phone: searchUserPhone?.replace(/\s/g, "") },
      });
    },
  });

  const onUserSelect = async (customer) => {
    const { response: existingCart } = await getExistingCart.request({
      params: { userId: customer.id },
    });

    if (!existingCart) {
      const { response: newCart } = await createCart.request({
        params: { userId: customer.id },
      });
      onUserSubmit(newCart, customer);

      return;
    }

    onUserSubmit(existingCart, customer);
  };

  const inputs = [firstname, lastname, phone];

  const onUserSave = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (!address) {
      hasErrors = true;
      setError("Adresse de livraison manquante");
    }

    if (hasErrors) return;

    const { response: createdUser, error } = await createOrderUser.request({
      params: {
        firstname: firstname.value,
        lastname: lastname.value,
        phone: phone.value,
        address,
        role: "CUSTOMER",
        active: true,
      },
    });

    if (createdUser) onUserSelect(createdUser);
    else setError(error.data.error?.message);
  };

  return (
    <>
      {!isCreatingCustomer ? (
        <>
          {/* <Search
            className="search-input"
            loading={searchUsers.loading}
            onResultSelect={(e, { result }) => onUserSelect(result)}
            onSearchChange={(e, { value }) => setSearchUserLabel(value)}
            resultRenderer={formatUsers}
            results={searchUsers.response}
            value={searchUserLabel}
            minCharacters={3}
            placeholder="Rechercher un client"
            noResultsMessage="Aucun client trouvé"
            fluid
          /> */}

          <Search
            className="search-input"
            loading={searchUsersByPhone.loading}
            onResultSelect={(e, { result }) => {
              if (result.action === "CREATE_CUSTOMER") {
                setIsCreatingCustomer(true);
                phone.setValue(searchUserPhone);
              } else onUserSelect(result);
            }}
            onSearchChange={(e, { value }) => {
              setIsCreatingCustomer(false);
              setSearchUserPhone(value);
            }}
            resultRenderer={formatUsers}
            results={
              searchUsersByPhone.response
                ? [
                    ...searchUsersByPhone.response,
                    {
                      key: "CREATE_CUSTOMER",
                      title: "Créer un client",
                      action: "CREATE_CUSTOMER",
                      phone: searchUserPhone,
                    },
                  ]
                : [
                    {
                      key: "CREATE_CUSTOMER",
                      title: "Créer un client",
                      action: "CREATE_CUSTOMER",
                      phone: searchUserPhone,
                    },
                  ]
            }
            value={searchUserPhone}
            minCharacters={3}
            placeholder="Numéro de téléphone"
            noResultsMessage="Aucun client trouvé"
            fluid
          />
        </>
      ) : (
        <Form>
          <Form.Group className="form-row">
            <Form.Field width={16}>
              <Form.Input label="Prénom" {...firstname.props} />
            </Form.Field>
            <Form.Field width={16}>
              <Form.Input label="Nom de famille" {...lastname.props} />
            </Form.Field>
          </Form.Group>

          <Form.Group className="form-row">
            <Form.Field width={5}>
              <Form.Input label="Téléphone" {...phone.props} />
            </Form.Field>
            <Form.Field width={11}>
              <label>Adresse de livraison</label>
              <AddressSearch
                userAddress={address}
                onSelect={(result) => setAddress(result)}
              />
            </Form.Field>
          </Form.Group>

          {error && <Message negative>{error}</Message>}

          <Button type="submit" onClick={() => setIsCreatingCustomer(false)}>
            Retour à la recherche
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={onUserSave}
            loading={createOrderUser.loading}
          >
            <Icon name="plus" />
            Créer
          </Button>
        </Form>
      )}
    </>
  );
};

const formatUsers = ({ firstname, lastname, phone, email, action }) => {
  if (action === "CREATE_CUSTOMER")
    return (
      <>
        <Icon name="plus" />
        Créer un client avec <b>{phone}</b>
      </>
    );
  return (
    <Row align="center" justify="space-between">
      {firstname} {lastname}
      <Column align="flex-end">
        <span style={{ opacity: 0.4 }}>{email}</span>
        <span style={{ opacity: 0.6, fontWeight: 600 }}>{phone}</span>
      </Column>
    </Row>
  );
};

export default UserInformations;
