import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";

const TimeDisplay = ({
  time,
  minutesWarningThreshold = 5,
  prefix = "",
  style,
}) => {
  const calculateRemainingTime = () => {
    const remainingMilliseconds = new Date(time) - new Date();
    return Math.floor(remainingMilliseconds / 1000 / 60);
  };

  const [remaining, setRemaining] = useState(calculateRemainingTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(calculateRemainingTime());
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`time-display${
        remaining < minutesWarningThreshold && remaining > 0
          ? " nearly-late"
          : remaining <= 0
          ? " late"
          : ""
      }`}
      style={style}
    >
      {prefix} {dayjs(time).format("H[h]mm")}
    </div>
  );
};

export default TimeDisplay;
