export const getproductBreakdown = (orderProduct) => {
  const unitaryOptionPrice = orderProduct.orderProductOptions.reduce(
    (acc, choice) => acc + choice.price,
    0
  );
  return {
    unitaryProductPrice: orderProduct.price,
    unitaryOptionPrice,
    unitaryTotal: orderProduct.price + unitaryOptionPrice,
    total: (orderProduct.price + unitaryOptionPrice) * orderProduct.quantity,
  };
};

export const centimesToAmount = (amountInCentimes) => {
  return amountInCentimes / 100;
};

export const amountToCentimes = (amount) => {
  return amount * 100;
};

export const renderCentimesAsAmount = (
  amountInCentimes,
  { minimumFractionDigits } = { minimumFractionDigits: 2 }
) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits,
  }).format(centimesToAmount(amountInCentimes));
};

export const renderAmount = (
  amount,
  { minimumFractionDigits } = { minimumFractionDigits: 2 }
) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits,
  }).format(amount);
};
