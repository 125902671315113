// import "../../styles/Analysis.scss";
import { useCallback, useState, useEffect } from "react";
import { DateInput3 } from "@blueprintjs/datetime2";
import { formatToUppercase } from "../../resources/helpers";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Column, Divider, Row, WithLoader } from "../common";
import { Button, Icon } from "semantic-ui-react";
import { useRequest } from "../../resources/hooks";
import * as AnalysisService from "../../services/analysis";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

const getLastDayOfMonth = (date) =>
  dayjs(date).utc().endOf("month").get("date");
const getEndOfMidMonth = (date) =>
  dayjs(date).utc().set("date", 15).endOf("day");
const getStartOfMidMonth = (date) =>
  dayjs(date).utc().set("date", 16).startOf("day");

const InvoiceExports = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs().toDate());
  const [selectedDateRange, setSelectedDateRange] = useState("START");

  const getInvoices = useRequest({
    service: AnalysisService.fetchRestaurantInvoices,
    defaultParams: {
      startDate: dayjs(selectedDate).utc().startOf("month").toDate(),
      endDate: getEndOfMidMonth(selectedDate).toDate(),
    },
  });

  const onDateChange = (newDate) => {
    if (!newDate) return;
    const formatted = dayjs(newDate).utc();
    setSelectedDateRange(formatted.get("date") <= 15 ? "START" : "END");
    setSelectedDate(formatted.toDate());
  };

  const refreshInvoices = () => {
    let [startDate, endDate] = [null, null];

    const selectedDateFormatted = dayjs(selectedDate).utc();

    if (selectedDateRange === "START") {
      startDate = selectedDateFormatted.startOf("month");
      endDate = getEndOfMidMonth(selectedDateFormatted);
    } else if (selectedDateRange === "END") {
      startDate = getStartOfMidMonth(selectedDateFormatted);
      endDate = selectedDateFormatted.endOf("month");
    }
    getInvoices.request({
      params: {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      },
    });
  };
  useEffect(() => {
    refreshInvoices();
  }, [selectedDate, selectedDateRange]);

  const generateInvoiceUrl = useCallback(
    (restaurantId) => {
      let [startDate, endDate] = [null, null];
      const selectedDateFormatted = dayjs(selectedDate).utc();

      if (selectedDateRange === "START") {
        startDate = selectedDateFormatted.startOf("month");
        endDate = getEndOfMidMonth(selectedDateFormatted);
      } else if (selectedDateRange === "END") {
        startDate = getStartOfMidMonth(selectedDateFormatted);
        endDate = selectedDateFormatted.endOf("month");
      }
      return `${API_URL}/api/analysis/invoices/restaurants/${restaurantId}?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`;
    },
    [selectedDate, selectedDateRange]
  );

  return (
    <Column id="invoice-exports" childrenMargin="tiny">
      <Row childrenMargin="tiny">
        <Button.Group>
          <Button
            active={selectedDateRange === "START"}
            primary={selectedDateRange === "START"}
            onClick={() => setSelectedDateRange("START")}
          >
            1-15
          </Button>
          <Button
            active={selectedDateRange === "END"}
            primary={selectedDateRange === "END"}
            onClick={() => setSelectedDateRange("END")}
          >
            16-{getLastDayOfMonth(selectedDate)}
          </Button>
        </Button.Group>
        <DateInput3
          formatDate={(date) =>
            formatToUppercase(
              dayjs(date).utc().locale("fr").format("MMMM YYYY")
            )
          }
          parseDate={(str) => new Date(str)}
          todayButtonText="Aujourd'hui"
          highlightCurrentDay
          placeholder="Aucune"
          showActionsBar
          defaultValue={selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null}
          value={selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null}
          onChange={(selectedStringDate) => {
            onDateChange(selectedStringDate);
          }}
          inputProps={{ className: "date-picker-input" }}
        />
      </Row>
      <WithLoader loading={getInvoices.loading} centered>
        <Column childrenMargin="small">
          {getInvoices.response?.map((restaurant) => (
            <Row key={restaurant.id} align="center" childrenMargin="tiny">
              <h3 style={{ whiteSpace: "nowrap" }}>{restaurant.label}</h3>

              <Divider fluid dark />

              <a
                href={generateInvoiceUrl(restaurant.id)}
                target="_blank"
                rel="noreferrer"
                download
                onClick={() => {
                  if (!restaurant.invoice) {
                    setTimeout(refreshInvoices, 2000);
                  }
                }}
              >
                {restaurant.invoice ? (
                  <Button
                    size="mini"
                    color="green"
                    icon
                    labelPosition="right"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Icon name="download" />
                    Télécharger facture n°{restaurant.invoice.number}
                  </Button>
                ) : (
                  <Button
                    size="mini"
                    color="violet"
                    icon
                    labelPosition="right"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Icon name="plus" />
                    Générer facture
                  </Button>
                )}
              </a>
            </Row>
          ))}
        </Column>
      </WithLoader>
    </Column>
  );
};

export default InvoiceExports;
