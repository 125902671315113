import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchActiveOrders() {
  // TODO/ remove try catch
  try {
    const { data } = await axios.get("/api/orders/active");

    return data;
  } catch (err) {
    return null;
  }
}
export async function fetchPlannedOrders() {
  const { data } = await axios.get("/api/orders/planned");

  return data;
}

export async function fetchFinishedOrders({ deliveryDate }) {
  const { data } = await axios.get(`/api/orders/finished`, {
    params: { deliveryDate },
  });

  return data;
}

export async function fetchDeliverymen() {
  try {
    const { data } = await axios.get(`/api/users/deliverymen`);

    return data;
  } catch (err) {
    return null;
  }
}

export async function fetchOrder(orderId) {
  const { data } = await axios.get(`/api/orders/${orderId}`);

  return data;
}

export async function cancel({ orderId }) {
  const { data } = await axios.delete(`/api/orders/${orderId}`);

  return data;
}

export async function cancelRestaurantOrder({ orderId, restaurantId }) {
  const { data } = await axios.delete(
    `/api/orders/${orderId}/restaurants/${restaurantId}`
  );

  return data;
}

export async function fetchDeliveredOrders({
  deliveryDate,
  startDate,
  endDate,
}) {
  const { data } = await axios.get("/api/analysis/orders/delivered", {
    params: { deliveryDate, startDate, endDate },
  });

  return data;
}

export async function fetchUserOrders({ userId, limit, status }) {
  const { data } = await axios.get(`/api/orders/users/${userId}`, {
    params: { limit, status },
  });

  return data;
}

export async function updateProductsStatus({ orderId, orderProducts }) {
  const { data } = await axios.put(
    `/api/orders/${orderId}/products/status`,
    orderProducts
  );

  return data;
}
