import { useContext, useEffect, useState } from "react";
import { DateInput3 } from "@blueprintjs/datetime2";
import dayjs from "dayjs";
import { OrderContext } from "contexts/orders";
import { useRequest } from "resources/hooks";
import { fetchDeliveredOrders } from "services/orders";
import { OrderRow, SelectedOrder } from "components/orders/components";
import { Price, Row, Column, Divider, WithLoader } from "components/common";
import { formatToUppercase } from "resources/helpers";
import { getPaymentMethod } from "resources/constants/paymentMethods";
import {
  getDeliveryFee,
  getDiscount,
  getTip,
  getTotal,
} from "resources/helpers/orderTotal";
import "dayjs/locale/fr";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";

const DeliveriesAnalysis = () => {
  const {
    state: { selectedOrder },
    dispatch,
  } = useContext(OrderContext);

  const getDelivered = useRequest({
    service: fetchDeliveredOrders,
    defaultParams: {
      deliveryDate: dayjs().startOf("day").add(12, "hour").toDate(),
    },
  });

  const [ordersByDeliveryman, setOrdersByDeliveryman] = useState([]);

  useEffect(() => {
    if (getDelivered.response) {
      const formatted = getDelivered.response.reduce((acc, order) => {
        if (!order.orderDelivery.deliveryman?.id) {
          return acc;
        }
        const deliverymanIndex = acc.findIndex(
          ({ deliveryman }) =>
            deliveryman.id === order.orderDelivery.deliveryman.id
        );
        if (deliverymanIndex < 0) {
          acc.push({
            deliveryman: order.orderDelivery.deliveryman,
            orders: [order],
            totalManualProfit:
              getPaymentMethod(order.paymentMethod).type === "manual"
                ? getTotal(order.orderProducts) +
                getDeliveryFee(order) -
                getDiscount(order) +
                getTip(order)
                : 0,
            totalOnlineProfit:
              getPaymentMethod(order.paymentMethod).type === "online"
                ? getTotal(order.orderProducts) +
                getDeliveryFee(order) -
                getDiscount(order) +
                getTip(order)
                : 0,
          });
        } else {
          acc[deliverymanIndex].orders.push(order);
          if (getPaymentMethod(order.paymentMethod).type === "manual")
            acc[deliverymanIndex].totalManualProfit +=
              getTotal(order.orderProducts) +
              getDeliveryFee(order) -
              getDiscount(order) +
              getTip(order);
          else if (getPaymentMethod(order.paymentMethod).type === "online")
            acc[deliverymanIndex].totalOnlineProfit +=
              getTotal(order.orderProducts) +
              getDeliveryFee(order) -
              getDiscount(order) +
              getTip(order);
        }
        return acc;
      }, []);

      setOrdersByDeliveryman(formatted);
    }
  }, [getDelivered.response]);

  const handleDateChange = (deliveryDate) => {
    getDelivered.request({ params: { deliveryDate } });
  };

  return (
    <div id="deliveryman-analysis">
      <Row>
        <div className="board">
          <Row
            align="center"
            style={{ marginBottom: "2rem" }}
            childrenMargin="small"
          >
            <h3 style={{ whiteSpace: "nowrap" }}>Date des livraisons</h3>
            <DateInput3
              formatDate={(date) =>
                formatToUppercase(
                  dayjs(date).locale("fr").format("dddd DD MMMM")
                )
              }
              fill
              todayButtonText="Aujourd'hui"
              highlightCurrentDay
              placeholder="Aucune"
              showActionsBar
              defaultValue={new Date()}
              onChange={(e) => {
                handleDateChange(
                  dayjs(e).startOf("day").add(12, "hour").toDate()
                );
              }}
            />
          </Row>

          <WithLoader loading={getDelivered.loading}>
            <Column childrenMargin="medium">
              {ordersByDeliveryman.map(
                (
                  { deliveryman, totalManualProfit, totalOnlineProfit, orders },
                  i
                ) => (
                  <Column
                    key={deliveryman?.id || `delivery-group-${i}`}
                    childrenMargin="small"
                  >
                    <Row
                      justify="space-between"
                      align="center"
                      childrenMargin="tiny"
                    >
                      <h3 style={{ whiteSpace: "nowrap" }}>
                        {deliveryman
                          ? `${deliveryman.firstname} ${deliveryman.lastname}`
                          : "Non attribué"}
                      </h3>
                      <span className="circular-count">{orders.length}</span>

                      <Divider fluid dark />

                      <span className="deliveryman-summary-prices">
                        {`💻 `}
                        <Price fontSize={1.2}>
                          {renderCentimesAsAmount(totalOnlineProfit)}
                        </Price>
                        {` / 💳 💶 `}
                        <Price fontSize={1.2}>
                          {renderCentimesAsAmount(totalManualProfit)}
                        </Price>
                      </span>
                    </Row>

                    {orders.map((order) => (
                      <OrderRow order={order} />
                    ))}
                  </Column>
                )
              )}
            </Column>
          </WithLoader>
        </div>

        <div className="selected-order-container">
          {selectedOrder && (
            <SelectedOrder
              order={selectedOrder}
              dismiss={() => dispatch({ type: "DISMISS_SELECTED_ORDER" })}
              hideActions
            />
          )}
        </div>
      </Row>
    </div>
  );
};

export default DeliveriesAnalysis;
