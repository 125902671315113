const constants = {
  DEFAULT_LOCATION: [48.835275, 2.708757],
  COLORS: [
    {
      key: "#000000",
      text: "Noir",
      value: "#000000",
    },
    {
      key: "#0074D9",
      text: "Bleu",
      value: "#0074D9",
    },
    {
      key: "#FF4136",
      text: "Rouge",
      value: "#FF4136",
    },
    {
      key: "#B10DC9",
      text: "Violet",
      value: "#B10DC9",
    },
    {
      key: "#FF851B",
      text: "Orange",
      value: "#FF851B",
    },
    {
      key: "#2ECC40",
      text: "Vert",
      value: "#2ECC40",
    },
  ],
};

export default constants;
