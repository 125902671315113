import { useRef } from "react";
import { Header, Icon } from "semantic-ui-react";

const ImageUpload = ({ image, handleImageUpload, error }) => {
  const fileInput = useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <div className="image-uploader">
      {error && <div className="error-overlay" />}
      <div className="upload-overlay" onClick={handleClick}>
        <Icon name="edit" inverted size="massive" />
      </div>
      {image ? (
        <img
          className="preview-image"
          src={typeof image === "string" ? image : URL.createObjectURL(image)}
          alt="product-preview"
        />
      ) : (
        <div className="image-placeholder">
          <Header icon textAlign="center">
            <Icon name="picture" />
            Ajouter une image
          </Header>
        </div>
      )}
      <input
        type="file"
        name="product"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          handleImageUpload(e.target.files[0]);
        }}
        hidden
        ref={fileInput}
      />
    </div>
  );
};

export default ImageUpload;
