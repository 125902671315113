import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import { useRequest } from "resources/hooks";
import { Column, Row, WithLoader } from "components/common";
import { fetchDeliveryAreas, updateDeliveryArea } from "services/deliveryAreas";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import DeliveryAreaModal from "./DeliveryAreaModal";
import constants from "resources/constants/deliveryAreas";
import AreaCard from "./AreaCard";
import "styles/DeliveryAreas.scss";

const DeliveryAreasList = () => {
  const getAll = useRequest({ service: fetchDeliveryAreas });
  const update = useRequest({ service: updateDeliveryArea });

  const [isCreating, setIsCreating] = useState(false);
  const [areaId, setAreaId] = useState(null);

  const deliveryAreas = getAll.response ?? [];

  const handleAreaActivation = async (areaId, active) => {
    await update.request({
      params: {
        areaId,
        active,
      },
    });

    getAll.request();
  };

  return (
    <div id="delivery-areas">
      <Row className="header" align="center">
        <Header as="h2">Zones de livraison</Header>

        <Button
          icon="plus"
          color="blue"
          content="Ajouter un nouvelle zone"
          style={{ marginLeft: "1rem" }}
          onClick={() => setIsCreating(true)}
        />
      </Row>

      <div className="data-container">
        <WithLoader
          loading={getAll.loading || update.loading}
          text="Récupération des zones"
        >
          <Row childrenMargin="small" fluid>
            <Column className="list">
              {deliveryAreas.map((da) => (
                <AreaCard
                  key={`card-${da.id}`}
                  area={da}
                  onClick={() => setAreaId(da.id)}
                  handleToggleChange={(active) =>
                    handleAreaActivation(da.id, active)
                  }
                />
              ))}
            </Column>

            <MapContainer
              center={constants.DEFAULT_LOCATION}
              zoom={14}
              scrollWheelZoom={false}
              className="map-container"
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {deliveryAreas.map((da) => (
                <Circle
                  key={`circle-${da.id}`}
                  center={[da.latitude, da.longitude]}
                  pathOptions={{
                    fillColor: da.color,
                    color: da.color,
                    opacity: da.active ? 0.8 : 0.2,
                  }}
                  radius={da.distance}
                />
              ))}

              <Marker position={constants.DEFAULT_LOCATION}>
                <Popup>Restos bussy QG</Popup>
              </Marker>
            </MapContainer>
          </Row>
        </WithLoader>
      </div>

      {(isCreating || areaId) && (
        <DeliveryAreaModal
          areaId={areaId}
          closeModal={({ withReload }) => {
            setIsCreating(null);
            setAreaId(null);
            if (withReload) {
              getAll.request();
            }
          }}
        />
      )}
    </div>
  );
};

export default withRouter(DeliveryAreasList);
