import { useEffect, useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import { useRequest, useInput } from "resources/hooks";
import {
  createDeliveryArea,
  fetchDeliveryArea,
  removeDeliveryArea,
  updateDeliveryArea,
} from "services/deliveryAreas";
import { Column, Row, WithLoader } from "../common";
import constants from "resources/constants/deliveryAreas";
import { centimesToAmount } from "resources/helpers/PriceHelper";

const DeliveryAreaModal = ({ closeModal, areaId }) => {
  const create = useRequest({
    service: createDeliveryArea,
    requestOnInit: false,
  });
  const update = useRequest({
    service: updateDeliveryArea,
    requestOnInit: false,
  });
  const get = useRequest({
    service: () => fetchDeliveryArea({ areaId }),
    requestOnInit: false,
  });
  const remove = useRequest({
    service: () => removeDeliveryArea({ areaId }),
    requestOnInit: false,
  });
  // TODO: log error

  const label = useInput("");
  const orderMinimumAmount = useInput(0, null, (value) => Number(value));
  const deliveryFee = useInput(0, null, (value) => Number(value));
  const deliveryTime = useInput(45, null, (value) => Number(value)); // minutes
  const [color, setColor] = useState("black");
  const [distance, setDistance] = useState(1000);

  useEffect(() => {
    if (areaId) {
      get.request();
    }
  }, []);

  useEffect(() => {
    if (get.response) {
      label.setValue(get.response.label);
      orderMinimumAmount.setValue(
        centimesToAmount(get.response.orderMinimumAmount)
      );
      deliveryFee.setValue(centimesToAmount(get.response.deliveryFee));
      deliveryTime.setValue(get.response.deliveryTime);
      setColor(get.response.color);
      setDistance(get.response.distance);
    }
  }, [get.response]);

  const inputs = [label, distance];

  const handleSubmit = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (hasErrors) return;

    const result = areaId
      ? await update.request({
          params: {
            areaId,
            label: label.value,
            location: constants.DEFAULT_LOCATION,
            orderMinimumAmount: orderMinimumAmount.value,
            deliveryFee: deliveryFee.value,
            deliveryTime: deliveryTime.value,
            distance,
            color,
          },
        })
      : await create.request({
          params: {
            label: label.value,
            location: constants.DEFAULT_LOCATION,
            orderMinimumAmount: orderMinimumAmount.value,
            deliveryFee: deliveryFee.value,
            deliveryTime: deliveryTime.value,
            distance,
            color,
          },
        });

    if (result.response) handleClose();
    // TODO log error
  };

  const handleDelete = async () => {
    await remove.request();

    closeModal({
      withReload: true,
    });
  };

  const handleClose = () => {
    closeModal({
      withReload: true,
    });
  };
  const loading = create.loading || update.loading || remove.loading;

  return (
    <Modal onClose={handleClose} open={true} className="delivery-area-modal">
      <Modal.Header>Créer une zone de livraison</Modal.Header>

      <Modal.Content>
        <WithLoader modalMode loading={areaId && !label.value}>
          <Row childrenMargin="medium">
            <Column style={{ flexBasis: "40%" }}>
              <Form>
                <Form.Group className="form-row">
                  <Form.Field width={16}>
                    <Form.Input label="Nom de la zone" {...label.props} />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={16}>
                    <Form.Input
                      label="Commande minimum"
                      {...orderMinimumAmount.props}
                      type="number"
                      min={0}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={16}>
                    <Form.Input
                      label="Frais de service"
                      {...deliveryFee.props}
                      type="number"
                      min={0}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={16}>
                    <Form.Input
                      label="Temps de livraison estimé (minutes)"
                      {...deliveryTime.props}
                      type="number"
                      min={0}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={16}>
                    <Form.Dropdown
                      label="Couleur de la zone"
                      placeholder="Selectionner"
                      fluid
                      selection
                      options={constants.COLORS}
                      value={color}
                      onChange={(e, { value }) => setColor(value)}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={16}></Form.Field>
                </Form.Group>
              </Form>
            </Column>

            <Column style={{ flexBasis: "60%" }} childrenMargin="medium">
              <Row style={{ width: "100%" }}>
                <input
                  type="range"
                  min={0}
                  max={20000}
                  onChange={(e) => setDistance(Number(e.target.value))}
                  style={{ width: "100%" }}
                  value={distance}
                />

                <span>{`${(distance / 1000).toFixed(
                  (distance / 1000) % 1 === 0 ? 0 : 2
                )}km`}</span>
              </Row>

              <MapContainer
                center={constants.DEFAULT_LOCATION}
                zoom={14}
                scrollWheelZoom={false}
                className="map-container"
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <Circle
                  center={constants.DEFAULT_LOCATION}
                  pathOptions={{ fillColor: color, color: color }}
                  radius={distance}
                />

                <Marker position={constants.DEFAULT_LOCATION} />
              </MapContainer>
            </Column>
          </Row>
        </WithLoader>
      </Modal.Content>

      <Modal.Actions>
        {areaId && (
          <Button
            color="red"
            loading={loading}
            disabled={loading}
            onClick={handleDelete}
          >
            Supprimer
          </Button>
        )}
        <Button
          color="black"
          loading={loading}
          disabled={loading}
          onClick={handleClose}
        >
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          onClick={handleSubmit}
          positive
          loading={loading}
          disabled={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeliveryAreaModal;
