import { cloneElement } from "react";
import "../../styles/common.scss";

const Row = ({
  children,
  className,
  justify,
  align,
  margin,
  fluid,
  style,
  childrenStyle,
  childrenMargin,
  gap,
  lastChildOnEnd,
  flexBasis,
  ...props
}) => {
  return (
    <div
      className={`row ${className ?? ""}${
        childrenMargin ? ` children-margin ${childrenMargin}` : ""
      }${lastChildOnEnd ? ` last-child-on-end` : ""}`}
      style={{
        justifyContent: justify,
        alignItems: align,
        gap,
        margin,
        width: fluid && "100%",
        flexBasis: flexBasis ? `${flexBasis}%` : undefined,
        ...style,
      }}
      {...props}
    >
      {Array.isArray(children) && childrenStyle
        ? children.map((child) => cloneElement(child, { style: childrenStyle }))
        : children}
    </div>
  );
};

export default Row;
