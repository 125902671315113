import { useEffect, useState } from "react";

const useModal = (TemplateModal, reloadData) => {
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState(null);

  useEffect(() => {
    if (!open) {
      setUpdateId(null);
    }
  }, [open]);

  useEffect(() => {
    setOpen(updateId ? true : false);
  }, [updateId]);

  return {
    Modal: () => (
      <TemplateModal
        open={open}
        onClose={() => setOpen(false)}
        closeModal={({ withReload }) => {
          setOpen(false);
          if (withReload) reloadData.request();
        }}
        updateId={updateId}
      />
    ),
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
    setUpdateId,
    updateId,
    isModalOpened: open,
  };
};

export default useModal;
