import React, { Fragment, useEffect, useState } from "react";
import { Button, Icon, Label, Header, Dropdown } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import {
  Clickable,
  Column,
  OptionsModal,
  OptionsSearchModal,
  Row,
  WithLoader,
} from "components/common";
import { getProductId, getProductMargin } from "resources/helpers";
import { fetchProduct } from "services/products";
import * as OptionService from "services/options";
import { useRequest } from "resources/hooks";
import ProductUpdate from "./ProductUpdate";
import { centimesToAmount } from "../../resources/helpers/PriceHelper";

const ProductShow = ({ history, location, match, routes, create }) => {
  const productId = getProductId(location);

  const [updateId, setUpdateId] = useState(null);

  const [isCreatingOption, setIsCreatingOption] = useState(null);
  const [optionUpdate, setOptionUpdate] = useState(null);
  const [optionUpdateIndex, setOptionUpdateIndex] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const get = useRequest({
    service: () => fetchProduct({ productId }),
  });
  const removeProductOption = useRequest({
    service: OptionService.removeProductOption,
  });

  const image = get.response?.publicUrl;
  const label = get.response?.label;
  const price = get.response?.price;
  const originalPrice = get.response?.originalPrice;
  const description = get.response?.description;
  const available = get.response?.available;
  const [options, setOptions] = useState([]);

  // const handleOptionUpdate = async (updatedOption) => {
  //   const result = await update.request({
  //     params: {
  //       productId,
  //       options: options.map((option, index) =>
  //         index === optionUpdateIndex ? updatedOption : option
  //       ),
  //     },
  //   });

  //   if (result.response) get.request();

  //   setOptionUpdate(null);
  //   setOptionUpdateIndex(null);
  // };

  const handleOptionDelete = async (optionId) => {
    // /products/:productId/options/:optionId
    const result = await removeProductOption.request({
      params: {
        productId,
        optionId,
      },
    });

    if (result.response) get.request();
  };

  useEffect(() => {
    if (get.response) setOptions(get.response?.productOptions);
  }, [get.response]);

  return (
    <WithLoader loading={get.loading} text="Récupération des produits">
      <div className="product-show">
        <div className="data-container">
          <Row childrenMargin="small">
            <img className="preview-image" src={image} alt="product-preview" />

            <Column className="">
              <Clickable onClick={() => setUpdateId(productId)}>
                <h3>
                  {label}
                  <Icon name="edit" color="blue" style={{ marginLeft: 5 }} />
                </h3>
              </Clickable>

              <Row className="prices">
                <Column style={{ marginRight: 20 }}>
                  <span className="category-label">prix de vente</span>
                  <span>{centimesToAmount(price)}€</span>
                </Column>
                <Column>
                  <span className="category-label">prix original</span>
                  <Row align="center">
                    <span style={{ marginRight: 5 }}>
                      {centimesToAmount(originalPrice)}€
                    </span>
                    <Label size="tiny" color="green">
                      {getProductMargin(price, originalPrice)}%
                    </Label>
                  </Row>
                </Column>
              </Row>

              <Column className="description" style={{ marginRight: 20 }}>
                <span className="category-label">description</span>
                <span className="content">{description}</span>
              </Column>

              <span className="availability category-label">disponibilité</span>
              <div>
                {available ? (
                  <Label color="green">Disponible</Label>
                ) : (
                  <Label color="red">Indisponible</Label>
                )}
              </div>
            </Column>
          </Row>
        </div>

        <div className="data-container no-padding min-height medium">
          <Row
            align="center"
            justify="space-between"
            className="options-header"
          >
            <Header as="h3">Options de produit</Header>
            <Dropdown
              text="Ajout option"
              as={(props) => (
                <Button {...props} basic color="blue" icon="plus" />
              )}
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Nouvelle option"
                  onClick={() => setIsCreatingOption(true)}
                />
                <Dropdown.Item
                  text="Depuis existante"
                  onClick={() => setIsSearching(true)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>

          <Column>
            {options.length > 0
              ? options.map((option, index) => (
                  <Fragment key={option.id}>
                    <Clickable
                      highlightOnHover
                      onClick={() => {
                        setOptionUpdateIndex(index);
                        setOptionUpdate(option);
                      }}
                    >
                      <Row className="option-row" justify="space-between">
                        <Column>
                          <strong>{option.label}</strong>
                          <Row childrenStyle={{ margin: "0 0.2rem" }}>
                            <span className="label">Selection: </span>
                            <span>
                              {option.productOptionChoices.map((choice, i) => (
                                <Fragment key={`${choice.label}-${i}`}>
                                  {i !== 0 ? ", " : ""}{" "}
                                  <span>{choice.label}</span>
                                </Fragment>
                              ))}
                            </span>
                          </Row>
                        </Column>

                        <Row>
                          <Button
                            icon="trash"
                            color="red"
                            basic
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOptionDelete(option.id);
                            }}
                          />
                        </Row>
                      </Row>
                    </Clickable>
                  </Fragment>
                ))
              : "Pas d'option encore définie"}
          </Column>
        </div>
      </div>

      {(isCreatingOption || optionUpdate) && (
        <OptionsModal
          productId={productId}
          optionUpdate={optionUpdate}
          closeModal={({ withReload }) => {
            setOptionUpdate(null);
            setOptionUpdateIndex(null);
            setIsCreatingOption(null);
            if (withReload) {
              get.request();
            }
          }}
        />
      )}

      {isSearching && (
        <OptionsSearchModal
          productId={productId}
          closeModal={({ withReload }) => {
            setIsSearching(false);
            if (withReload) {
              get.request();
            }
          }}
        />
      )}

      {updateId && (
        <ProductUpdate
          productId={updateId}
          closeModal={({ withReload }) => {
            setUpdateId(null);
            if (withReload) {
              get.request();
            }
          }}
        />
      )}
    </WithLoader>
  );
};

export default withRouter(ProductShow);
