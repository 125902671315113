import { getPaymentMethod } from "../../../resources/constants/paymentMethods";

const PaymentBadge = ({ paymentMethod, style, large, inverted }) => {
  return (
    <div
      className={`payment-status${large ? " large" : ""}`}
      style={{
        ...getPaymentMethod(paymentMethod)?.[
          inverted ? "invertedStyle" : "style"
        ],
        ...style,
      }}
    >
      <span>{getPaymentMethod(paymentMethod).label}</span>
    </div>
  );
};

export default PaymentBadge;
