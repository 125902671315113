import { Switch } from "react-router-dom";
import { RouteWithSubRoutes } from "../../resources/helpers";

const UsersView = ({ routes }) => {
  return (
    <div id="users">
      <Switch>
        {routes.map((route, index) => (
          <RouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
};

export default UsersView;
