import React, { useEffect, useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Row, WithLoader, SortableItem } from "../common";
import {
  fetchRestaurants,
  reorderRestaurants,
} from "../../services/restaurants";
import "../../styles/Restaurants.scss";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const RestaurantsTable = ({ routes }) => {
  let history = useHistory();

  const redirectToUpdate = (restaurantId) => {
    history.push(`/restaurants/${restaurantId}`);
  };

  const redirectToCategories = (restaurantId) => {
    history.push(`/restaurants/${restaurantId}/categories`, {
      restaurantId,
    });
  };

  const redirectToAccountManagement = (restaurantId) => {
    history.push(`/restaurants/${restaurantId}/profile`, {
      restaurantId,
    });
  };

  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState(null);

  const initiateRestaurants = async () => {
    setLoading(true);
    const restaurants = await fetchRestaurants();
    setRestaurants(restaurants);
    setLoading(false);
  };

  useEffect(() => initiateRestaurants(), []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  async function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = restaurants.findIndex(
        (restaurant) => restaurant.id === active.id
      );
      const newIndex = restaurants.findIndex(
        (restaurant) => restaurant.id === over.id
      );

      const newOrder = arrayMove(restaurants, oldIndex, newIndex);
      setLoading(true);
      setRestaurants(newOrder);
      await reorderRestaurants({
        restaurantIds: newOrder.map((restaurant) => restaurant.id),
      });
      await initiateRestaurants();
      setLoading(false);
    }
  }

  return (
    <WithLoader loading={loading} text="Récupération des restaurants">
      <div className="data-container">
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Actif</Table.HeaderCell>
              <Table.HeaderCell>Téléphone</Table.HeaderCell>
              <Table.HeaderCell>Ville</Table.HeaderCell>
              <Table.HeaderCell>Adresse</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={restaurants}
                strategy={verticalListSortingStrategy}
              >
                {restaurants?.length > 0 &&
                  restaurants.map((restaurant) => (
                    <Table.Row key={restaurant.id}>
                      <Table.Cell>
                        <SortableItem
                          key={restaurant.id}
                          id={restaurant.id}
                          style={{
                            cursor: "grab",
                          }}
                        >
                          <Icon name="bars" size="large" />
                          {restaurant.label}
                        </SortableItem>
                      </Table.Cell>
                      <Table.Cell>
                        <img
                          src={restaurant.publicUrl}
                          className="restaurant-image"
                          alt="restaurant-illustration"
                        />
                      </Table.Cell>
                      <Table.Cell>{restaurant.active ? "✅" : "❌"}</Table.Cell>
                      <Table.Cell>{restaurant.phone}</Table.Cell>
                      <Table.Cell>{restaurant.address}</Table.Cell>
                      <Table.Cell>{restaurant.city}</Table.Cell>
                      <Table.Cell>
                        <Row>
                          <Button
                            icon="edit"
                            color="blue"
                            onClick={() => redirectToUpdate(restaurant.id)}
                          />
                          <Button
                            icon
                            color="teal"
                            labelPosition="left"
                            onClick={() =>
                              redirectToAccountManagement(restaurant.id)
                            }
                          >
                            <Icon name="user" />
                            Compte
                          </Button>
                          <Button
                            icon
                            color="violet"
                            labelPosition="left"
                            onClick={() => redirectToCategories(restaurant.id)}
                          >
                            <Icon name="list" />
                            Catégories
                          </Button>
                        </Row>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </SortableContext>
            </DndContext>
          </Table.Body>
        </Table>
      </div>
    </WithLoader>
  );
};

export default RestaurantsTable;
