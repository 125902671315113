import { useContext } from "react";
import { Button, Message, Modal } from "semantic-ui-react";
import { OrderContext } from "../../../contexts/orders";
import { useRequest } from "../../../resources/hooks";
import * as OrderService from "../../../services/orders";

const CancelModal = ({
  orderId,
  orderNumber,
  hasPaidOnline,
  cancel,
  closeModal,
}) => {
  const { dispatch } = useContext(OrderContext);

  const cancelOrder = useRequest({
    service: () => OrderService.cancel({ orderId }),
    requestOnInit: false,
  });
  const getPlannedOrders = useRequest({
    service: OrderService.fetchPlannedOrders,
  });

  const handleCancel = async (orderId) => {
    const result = await cancelOrder.request();
    if (result.response) {
      dispatch({ type: "REMOVE_ORDER", order: result.response });

      const { response: plannedOrders } = await getPlannedOrders.request();
      dispatch({ type: "SET_PLANNED_ORDERS", plannedOrders });
    }
    closeModal();
  };

  return (
    <Modal dimmer="blurring" open onClose={closeModal} size="small">
      <Modal.Header>Annuler une commande</Modal.Header>
      <Modal.Content>
        <p>Êtes-vous sûr de vouloir annuler la commande {orderNumber} ?</p>
        {hasPaidOnline && (
          <Message warning>
            <Message.Header>Commande CB</Message.Header>
            <Message.Content>
              <p>
                Cette commande a été passée par CB, il faudra rembourser ce
                client sur Stripe
              </p>
            </Message.Content>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Retour</Button>
        <Button
          negative
          disabled={cancelOrder.loading}
          loading={cancelOrder.loading}
          onClick={handleCancel}
        >
          Je souhaite annuler cette commande
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CancelModal;
