import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchDeliveryAreas() {
  const { data } = await axios.get(`/api/deliveryAreas`);

  return data;
}

export async function fetchDeliveryArea({ areaId }) {
  const { data } = await axios.get(`/api/deliveryAreas/${areaId}`);

  return data;
}

export async function createDeliveryArea({
  label,
  location,
  orderMinimumAmount,
  minimumRequired,
  deliveryFee,
  deliveryTime,
  distance,
  color,
}) {
  const { data } = await axios.post("/api/deliveryAreas", {
    label,
    location,
    orderMinimumAmount,
    minimumRequired,
    deliveryFee,
    deliveryTime,
    distance,
    color,
  });

  return data;
}

export async function updateDeliveryArea({
  areaId,
  label,
  location,
  orderMinimumAmount,
  deliveryFee,
  deliveryTime,
  distance,
  color,
  active,
}) {
  const { data } = await axios.put(`/api/deliveryAreas/${areaId}`, {
    label,
    location,
    orderMinimumAmount,
    deliveryFee,
    deliveryTime,
    distance,
    color,
    active,
  });

  return data;
}

export async function removeDeliveryArea({ areaId }) {
  const { data } = await axios.delete(`/api/deliveryAreas/${areaId}`);

  return data;
}

export async function searchAddress({ address }) {
  const { data } = await axios.get("/api/locations/address", {
    params: { address },
  });

  return data;
}
