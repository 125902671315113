import { Loader } from "semantic-ui-react";

const CircularButton = ({
  children,
  className,
  onClick,
  inverted,
  loading,
  disabled,
  size,
  style,
}) => (
  <button
    className={`circular-button ${className ? ` ${className}` : ""}${
      inverted ? " inverted" : ""
    }${disabled ? " disabled" : ""}`}
    onClick={onClick}
    style={{ height: size, width: size, ...style }}
  >
    {loading ? (
      <Loader active inverted={!inverted} size="tiny" inline="centered" />
    ) : (
      children
    )}
  </button>
);

export default CircularButton;
