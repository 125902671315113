import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchRestaurants() {
  const { data } = await axios.get(`/api/restaurants`);

  return data;
}

export async function fetchRestaurant({ restaurantId, withAccount }) {
  const { data } = await axios.get(
    `/api/restaurants/${restaurantId}${withAccount ? "/account" : ""}`
  );

  return data;
}

export async function createRestaurant({
  label,
  phone,
  address,
  city,
  image,
  categories,
}) {
  try {
    const formData = new FormData();

    formData.append("label", label);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("restaurant-picture", image);
    formData.append("categories", categories);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.post("/api/restaurants", formData, config);

    return data;
  } catch (err) {
    return null;
  }
}

export async function updateRestaurant({
  restaurantId,
  label,
  phone,
  address,
  city,
  corporateName,
  siretNumber,
  benefitPercentage,
  invoiceNumberOffset,
  defaultReportDisplay,
  active,
  archived,
  openingHours,
  categories,
  image,
  removeImage,
}) {
  const formData = new FormData();

  if (!!label) formData.append("label", label);
  if (!!phone) formData.append("phone", phone);
  if (!!address) formData.append("address", address);
  if (!!city) formData.append("city", city);
  if (!!corporateName) formData.append("corporateName", corporateName);
  if (!!siretNumber) formData.append("siretNumber", siretNumber);
  if (benefitPercentage > -1)
    formData.append("benefitPercentage", benefitPercentage);
  if (invoiceNumberOffset > -1)
    formData.append("invoiceNumberOffset", invoiceNumberOffset);
  if (!!categories) formData.append("categories", categories);
  if (!!openingHours)
    formData.append("openingHours", JSON.stringify(openingHours));
  if (active !== undefined) formData.append("active", active);
  if (archived !== undefined) formData.append("archived", archived);
  if (defaultReportDisplay !== undefined)
    formData.append("defaultReportDisplay", defaultReportDisplay);
  if (!!image) formData.append("restaurant-picture", image);
  if (!!removeImage) formData.append("removeImage", removeImage);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const { data } = await axios.put(
    `/api/restaurants/${restaurantId}`,
    formData,
    config
  );

  return data;
}
export async function updateOpeningHours({ restaurantId, openingHours }) {
  const { data } = await axios.post(
    `/api/restaurants/${restaurantId}/openingHours`,
    { openingHours }
  );

  return data;
}

export async function registerAccount({ username, restaurantId }) {
  const { data } = await axios.post(
    `/api/auth/restaurants/${restaurantId}/account`,
    { username }
  );

  return data;
}

export async function fetchRestaurantAccount({ restaurantId }) {
  const { data } = await axios.get(
    `/api/auth/restaurants/${restaurantId}/account`
  );

  return data;
}

export async function getAvailableRestaurantCategories() {
  const { data } = await axios.get("/api/restaurantsCategories");

  return data;
}

export async function reorderRestaurants({ restaurantIds }) {
  const { data } = await axios.put("/api/restaurants/reorder", restaurantIds);

  return data;
}
