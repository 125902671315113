const Ping = ({ style, color, speed }) => (
  <div
    className={`ping ${speed ? ` ${speed}` : ""}`}
    style={{ ...style, backgroundColor: color }}
  >
    <div className="animated" style={{ backgroundColor: color }} />
  </div>
);

export default Ping;
