import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function searchUsers({ text }) {
  const { data } = await axios.get(`/api/users/search`, {
    params: {
      text,
    },
  });

  return data;
}

export async function searchUsersByPhone({ phone }) {
  const { data } = await axios.get(`/api/users/search/phone`, {
    params: {
      phone,
    },
  });

  return data;
}

export async function fetchUsers({ skip, limit, searchText }) {
  const { data } = await axios.get(`/api/users`, {
    params: {
      skip,
      limit,
      searchText,
    },
  });

  return data;
}

export async function createUser({
  email,
  firstname,
  lastname,
  password,
  phone,
  address,
  role,
  active,
}) {
  const { data } = await axios.post("/api/users", {
    email,
    firstname,
    lastname,
    password,
    phone,
    address,
    role,
    active,
  });

  return data;
}

export async function updateUser({
  userId,
  email,
  firstname,
  lastname,
  password,
  phone,
  address,
  role,
  active,
}) {
  const { data } = await axios.put(`/api/users/${userId}`, {
    email,
    firstname,
    lastname,
    password,
    phone,
    address,
    role,
    active,
  });

  return data;
}

export async function fetchUser({ userId }) {
  const { data } = await axios.get(`/api/users/${userId}`);

  return data;
}
