import { useMemo, useState } from "react";
import { Icon, Label } from "semantic-ui-react";
import getOrderStatus from "resources/constants/orderStatus";
import { Column, Divider, Price, Row } from "components/common";
import * as PriceHelper from "resources/helpers/PriceHelper";
import OrderStatus from "./OrderStatus";

const OrderProducts = ({
  orderProducts,
  restaurantOrders,
  children,
  inverted,
  deliveryFee = 0,
  discount = 0,
  tip = 0,
  discountCode,
  onRestaurantOrderCancel,
}) => {
  let productsPrice = 0;

  const productsByRestaurant = useMemo(
    () =>
      orderProducts?.reduce((acc, product) => {
        if (acc[product.originalRestaurant.label]?.length > 0) {
          acc[product.originalRestaurant.label].push({ ...product });
        } else {
          acc[product.originalRestaurant.label] = [{ ...product }];
        }
        return acc;
      }, {}) ?? {},
    [orderProducts]
  );

  Object.keys(productsByRestaurant).forEach((restaurant) => {
    productsByRestaurant[restaurant].forEach((product) => {
      const { total } = PriceHelper.getproductBreakdown(product);
      productsPrice += total;
    });
  });

  return (
    <div className={`order-products${inverted ? " inverted" : ""}`}>
      <div className="restaurant-products">
        {Object.keys(productsByRestaurant).map((restaurantLabel) => (
          <RestaurantProducts
            key={restaurantLabel}
            productsByRestaurant={productsByRestaurant}
            restaurant={
              productsByRestaurant[restaurantLabel][0].originalRestaurant
            }
            restaurantName={restaurantLabel}
            restaurantStatus={
              restaurantOrders.find(
                (orderRestaurant) => orderRestaurant.label === restaurantLabel
              )?.status
            }
            onRestaurantOrderCancel={onRestaurantOrderCancel}
          />
        ))}
      </div>

      <Divider noMargin />

      <div className="cart-prices">
        <Row
          className="price-row sub-total-price"
          align="center"
          justify="space-between"
        >
          <div className="title">Sous-total</div>
          <div className="price">
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(productsPrice)}
            </Price>
          </div>
        </Row>
        {tip > 0 && (
          <Row
            className="price-row delivery-price"
            align="center"
            justify="space-between"
          >
            <span className="title">Pourboire</span>
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(tip)}
            </Price>
          </Row>
        )}
        <Row
          className="price-row delivery-price"
          align="center"
          justify="space-between"
        >
          <span className="title">Frais de service</span>
          {deliveryFee === 0 ? (
            <span className="price">Gratuit</span>
          ) : (
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(deliveryFee)}
            </Price>
          )}
        </Row>
        {discount > 0 && (
          <Row
            className="price-row delivery-price"
            align="center"
            justify="space-between"
          >
            <Row className="title" gap="0.3rem">
              <span>Réduction</span>
              {discountCode && (
                <Label size="tiny">
                  <Icon name="tag" color="blue" />
                  {discountCode}
                </Label>
              )}
            </Row>
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(-discount)}
            </Price>
          </Row>
        )}
        <Row
          className="price-row total-price"
          align="center"
          justify="space-between"
          style={{ fontWeight: 600 }}
        >
          <span className="title">Total</span>
          <Price fontSize="1.3">
            {PriceHelper.renderCentimesAsAmount(
              deliveryFee + productsPrice - discount + tip
            )}
          </Price>
        </Row>
      </div>

      {children}
    </div>
  );
};

const RestaurantProducts = ({
  productsByRestaurant,
  restaurant,
  restaurantName,
  restaurantStatus,
  onRestaurantOrderCancel,
}) => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  return (
    <Column className="restaurant">
      <Row
        className="title"
        align="center"
        style={{
          gap: "10px",
        }}
      >
        <Column>
          <span>préparé par</span>
          <div className="name">{restaurantName}</div>
        </Column>

        <Row align="center" style={{ marginLeft: "auto" }}>
          <OrderStatus status={restaurantStatus} />
          {(getOrderStatus(restaurantStatus).code === "CONFIRMED" ||
            getOrderStatus(restaurantStatus).code === "PREPARATION" ||
            getOrderStatus(restaurantStatus).code === "ASSIGNED") &&
            (confirmCancel ? (
              <button
                className="order-restaurant-cancel-button"
                onClick={() => {
                  onRestaurantOrderCancel(restaurant.id);
                }}
              >
                <Icon name="question" size="small" />
              </button>
            ) : (
              <button
                className="order-restaurant-cancel-button"
                onClick={() => {
                  setConfirmCancel(true);
                  setTimeout(() => setConfirmCancel(false), 3000);
                }}
              >
                <Icon name="close" size="small" />
              </button>
            ))}
        </Row>
      </Row>

      {/* TODO: link to product */}
      {productsByRestaurant[restaurantName].map((product) => (
        <ProductRow key={product.id} product={product} />
      ))}
    </Column>
  );
};

const ProductRow = ({ product }) => {
  const { unitaryTotal } = useMemo(
    () => PriceHelper.getproductBreakdown(product),
    [product]
  );

  return (
    <Column
      className={`product${product.status === "CANCELED" ? " canceled" : ""}`}
    >
      <Row align="center" childrenMargin="tiny">
        <div className="count">
          <span>{product.quantity}</span>
          <span style={{ fontSize: "0.7rem", marginLeft: "2px" }}>x</span>
        </div>

        <div className="name">{product.label}</div>

        <Row className="price-wrapper" align="center">
          <div className={`item-price`}>
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(unitaryTotal)}
            </Price>
          </div>
        </Row>
      </Row>

      <ProductSpecifics
        options={product.orderProductOptions}
        comments={product.comments}
      />
    </Column>
  );
};

const ProductSpecifics = ({ options, comments }) => {
  if (options?.length === 0 && !comments) return null;

  return (
    <Column className="item-additionnal-information">
      {options && (
        <Column className="item-options">
          {options.map((option) => (
            <span key={option.id}>
              {option.option}:{" "}
              <span className="option-choices">{option.choice}</span>
            </span>
          ))}
        </Column>
      )}

      {comments && <span className="comments">{`"${comments}"`}</span>}
    </Column>
  );
};

export default OrderProducts;
