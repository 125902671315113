import io from "socket.io-client";
import cookies from "js-cookie";
export let socket;

const ENDPOINT =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export const initiateSocket = (userId, cb) => {
  socket = io(ENDPOINT, {
    auth: {
      token: cookies.get("token"),
    },
  });
  if (socket) {
    socket.emit("/admin/identify", { userId }, (response) => {
      return cb(null, response);
    });
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.disconnect();
  }
};

export const subscribeToOrders = (cb) => {
  if (!socket) return true;
  socket.on("/orders/update", (msg) => {
    return cb(null, msg);
  });
};

export const subscribeToOrderCreation = (cb) => {
  if (!socket) return true;
  socket.on("/orders/create", (msg) => {
    return cb(null, msg);
  });
};

export const assignOrder = ({ orderId, deliverymanId, pickupTime }, cb) => {
  if (!socket) return true;
  socket.emit(
    "/admin/order/assign",
    { orderId, deliverymanId, pickupTime },
    (msg) => {
      return cb(null, msg);
    }
  );
};

export const handleDisconnection = (callback) => {
  if (!socket) return;
  socket.on("disconnect", (reason) => {
    return callback(reason);
  });
};

export const handleReconnection = ({ userId }, callback) => {
  if (!socket) return;

  
  socket.io.on("reconnect", (reconnectionAttemptNumber) => {    
    socket.emit("/admin/identify", { userId }, (response) => {});

    callback(reconnectionAttemptNumber);
  });
};
