import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Header, Label } from "semantic-ui-react";
import { useRequest } from "../../resources/hooks";
import { fetchUserOrders } from "../../services/orders";
import { Column, NoDataMessage, Row, WithLoader } from "../common";
import { OrderRow, SelectedOrder } from "../orders/components";

const UserOrders = ({ match }) => {
  const history = useHistory();
  const { userId } = match.params;
  const [selectedOrder, setSelectedOrder] = useState(null);

  const userOrders = useRequest({
    service: fetchUserOrders,
    defaultParams: {
      limit: 100,
      userId,
      status: ["delivered", "active"],
    },
  });

  const orders = userOrders.response || [];

  return (
    <Column className="user-orders" childrenMargin="large">
      <Row className="header" align="center">
        <Header as="h2">Commandes passées</Header>
        <Button
          icon="arrow left"
          color="blue"
          content="Retour aux utilisateurs"
          style={{ marginLeft: "1rem" }}
          onClick={() => history.push("/users")}
        />
      </Row>

      <Row>
        <Column style={{ flexBasis: "60%" }} className="order-rows" fluid>
          <WithLoader loading={userOrders.loading}>
            {orders?.length !== 0 ? (
              orders.map((order) => (
                <OrderRow
                  order={order}
                  onClick={(order) => setSelectedOrder(order)}
                >
                  <Column justify="center" style={{ marginLeft: "auto" }}>
                    <Label
                      color={
                        order.orderDelivery.status === "DELIVERED"
                          ? "green"
                          : "red"
                      }
                      className="status"
                      basic
                    >
                      {order.orderDelivery.status === "DELIVERED"
                        ? "Livrée"
                        : "Annulée"}
                    </Label>
                  </Column>
                </OrderRow>
              ))
            ) : (
              <NoDataMessage style={{ marginTop: "1rem" }}>
                Aucune commande pour ce client
              </NoDataMessage>
            )}
          </WithLoader>
        </Column>

        <Column className="selected-order-parent" fluid>
          <div className="selected-order-container">
            {selectedOrder && (
              <SelectedOrder
                order={selectedOrder}
                dismiss={() => setSelectedOrder(null)}
                hideActions
              />
            )}
          </div>
        </Column>
      </Row>
    </Column>
  );
};

export default withRouter(UserOrders);
