import { Checkbox, Form, Search, TextArea } from "semantic-ui-react";
import { useEffect, useState } from "react";
import * as DeliveryAreaService from "services/deliveryAreas";
import {
  useDebounce,
  useInput,
  useRequest,
  useTextArea,
} from "resources/hooks";

const MIN_CHARACTER_INPUT = 5;

const DeliveryInformations = ({
  user,
  onUpdate,
  onMultipleUpdates,
  initialDeliveryFee,
}) => {
  const searchAddress = useRequest({
    service: DeliveryAreaService.searchAddress,
  });
  const phone = useInput(user.phone);
  const fixedDiscount = useInput(0, { type: "number", min: 0 });
  const percentageDiscount = useInput(0, { type: "number", min: 0, max: 100 });
  const deliveryFee = useInput(initialDeliveryFee || 0, {
    type: "number",
    min: 0,
  });
  const deliveryComments = useTextArea("", { maxLength: 150 });
  const [address, setAddress] = useState(user.address);
  const [addressLabel, setAddressLabel] = useState(user.address);

  useDebounce(addressLabel, {
    minimumChars: MIN_CHARACTER_INPUT,
    onTimeout: () => {
      searchAddress.request({ params: { address: addressLabel } });
    },
  });

  useEffect(() => {
    const [numberFixedDiscount, numberPercentageDiscount, numberDeliveryFee] = [
      Number(fixedDiscount.value) ?? 0,
      Number(percentageDiscount.value) ?? 0,
      Number(deliveryFee.value) ?? 0,
    ];

    onMultipleUpdates({
      comments: deliveryComments.value,
      phone: phone.value,
      deliveryAddress: address,
      percentageDiscount: numberPercentageDiscount,
      fixedDiscount: numberFixedDiscount,
      deliveryFee: numberDeliveryFee,
    });

    if (numberFixedDiscount > 0 && numberPercentageDiscount > 0) {
      fixedDiscount.setError("Un seul type de réduction est possible");
      percentageDiscount.setError("Un seul type de réduction est possible");
    } else if (numberPercentageDiscount < 0 || numberPercentageDiscount > 100) {
      percentageDiscount.setError("Doit être compris entre 0 et 100%");
    } else if (numberFixedDiscount < 0) {
      fixedDiscount.setError("Doit être supérieur à 0");
    } else {
      fixedDiscount.resetError();
      percentageDiscount.resetError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    percentageDiscount.value,
    fixedDiscount.value,
    deliveryFee.value,
    address,
    phone.value,
    deliveryComments.value,
  ]);

  return (
    <Form>
      <Form.Field>
        <label>Adresse de livraison</label>
        <Search
          className="search-input"
          loading={searchAddress.loading}
          onResultSelect={(e, { result }) => setAddress(result.title)}
          onSearchChange={(e, { value }) => setAddressLabel(value)}
          results={searchAddress.response?.map((address) => ({
            title: address.label,
          }))}
          minCharacters={MIN_CHARACTER_INPUT}
          placeholder="Rechercher une adresse"
          noResultsMessage="Aucune adresse correspondante"
          fluid
          defaultValue={user.address ?? undefined}
        />
      </Form.Field>

      <Form.Field>
        <Form.Input label="Téléphone" {...phone.props} />
      </Form.Field>

      <Form.Field className="" error={deliveryComments.error}>
        <label>Instructions de livraison</label>
        <TextArea
          {...deliveryComments.props}
          placeholder="Des instructions particulières ?"
        />
      </Form.Field>

      <Form.Group>
        <Form.Field className="" error={fixedDiscount.error} width={8}>
          <Form.Input label="Réduction fixe (€)" {...fixedDiscount.props} />
        </Form.Field>

        <Form.Field className="" error={percentageDiscount.error} width={8}>
          <Form.Input
            label="Réduction en pourcentage (%)"
            {...percentageDiscount.props}
          />
        </Form.Field>
      </Form.Group>

      <Form.Field className="" error={deliveryFee.error} width={16}>
        <Form.Input label="Frais de service (€)" {...deliveryFee.props} />
      </Form.Field>

      <Form.Field className="" error={deliveryComments.error}>
        <label>Commande payée ?</label>
        <Checkbox onChange={(e, { checked }) => onUpdate("isPaid", checked)} />
      </Form.Field>
    </Form>
  );
};

export default DeliveryInformations;
