import { useMemo, useState } from "react";
import { Button, Icon, Message, Modal, Step } from "semantic-ui-react";
import { useRequest } from "resources/hooks";
import * as CartService from "services/carts";
import { Column, Row } from "components/common";
import ProductModal from "components/products/ProductModal";
import UserInformations from "./UserInformations";
import ProductsSelection from "./ProductsSelection";
import DeliveryInformations from "./DeliveryInformations";
import { CartSummary } from "components/products";
import * as PriceHelper from "resources/helpers/PriceHelper";

const CreateOrderModal = ({ closeModal }) => {
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const addProductToCart = useRequest({
    service: CartService.addProductToCart,
    requestOnInit: false,
  });

  const createOrder = useRequest({
    service: CartService.createOrder,
    requestOnInit: false,
  });

  const updateOrderProduct = useRequest({
    service: CartService.updateOrderProduct,
    requestOnInit: false,
  });

  const updateCart = useRequest({
    service: CartService.updateCart,
    requestOnInit: false,
  });

  const updateCartExtraCosts = useRequest({
    service: CartService.updateCartExtraCosts,
    requestOnInit: false,
  });

  const deleteCart = useRequest({
    service: CartService.deleteCart,
    requestOnInit: false,
  });

  const [step, setStep] = useState("CUSTOMER_INFORMATIONS");

  const setUserCart = (cart, user) => {
    setCart(cart);
    setUser(user);
    setStep("PRODUCTS_SELECTION");
  };

  const onProductAdd = async (product) => {
    const { response: updatedCart } = await addProductToCart.request({
      params: { cartId: cart.id, ...product },
    });
    setCart({ ...cart, orderProducts: updatedCart.orderProducts });
  };

  const handleQuantityUpdate = async (delta, orderProduct) => {
    const { response: updatedCart } = await updateOrderProduct.request({
      params: {
        cartId: cart.id,
        orderProductId: orderProduct.id,
        quantity: orderProduct.quantity + delta,
      },
    });
    if (updatedCart) {
      setCart({ ...cart, orderProducts: updatedCart.orderProducts });
    }
  };

  const onDeliveryInformationUpdate = (type, data) => {
    setCart({ ...cart, [type]: data });
  };

  const onDeliveryInformationMultipleUpdates = async (updates) => {
    if (
      cart.deliveryFee !== updates.deliveryFee ||
      cart.percentageDiscount !== updates.percentageDiscount ||
      cart.fixedDiscount !== updates.fixedDiscount
    ) {
      const { response: updatedCart } = await updateCartExtraCosts.request({
        params: {
          cartId: cart.id,
          deliveryFee:
            typeof updates.deliveryFee === "number"
              ? updates.deliveryFee * 100
              : 0,
          percentageDiscount:
            typeof updates.percentageDiscount === "number"
              ? updates.percentageDiscount
              : 0,
          fixedDiscount:
            typeof updates.fixedDiscount === "number"
              ? updates.fixedDiscount * 100
              : 0,
        },
      });

      if (updatedCart) {
        setCart({
          ...cart,
          ...updatedCart,
        });
      }
    }
    if (
      cart.deliveryAddress !== updates.deliveryAddress ||
      updates.phone !== cart.phone ||
      updates.comments !== cart.comments
    ) {
      const { response: updatedCart } = await updateCart.request({
        params: {
          cartId: cart.id,
          deliveryAddress: updates.deliveryAddress,
          phone: updates.phone,
          comments: updates.comments,
        },
      });

      if (updatedCart) {
        setCart({ ...cart, ...updatedCart });
      }
    }
  };

  const handleOrderCreation = async () => {
    const result = await createOrder.request({
      params: {
        ...cart,
        cartId: cart.id,
        isPaid: cart.isPaid ? true : false,
      },
    });

    if (result.response) {
      closeModal();
    }
  };

  const onModalClose = () => {
    if (cart?.id) deleteCart.request({ params: { cartId: cart.id } });

    closeModal();
  };

  const isCartValid =
    cart &&
    cart.orderProducts.length > 0 &&
    !!cart.deliveryAddress &&
    !!cart.phone &&
    cart.fixedDiscount >= 0 &&
    cart.percentageDiscount >= 0 &&
    cart.percentageDiscount <= 100 &&
    !(cart.fixedDiscount > 0 && cart.percentageDiscount > 0);

  const estimatedTotal = useMemo(() => {
    if (!cart?.orderProducts.length) return;
    return cart.orderProducts.reduce((acc, product) => {
      const { total } = PriceHelper.getproductBreakdown(product);
      return (acc += total);
    }, 0);
  }, [cart?.orderProducts]);

  const effectiveDiscount = useMemo(() => {
    if (!cart) {
      return 0;
    }
    const { fixedDiscount, percentageDiscount } = cart;
    let effectiveDiscount = 0;

    if (
      fixedDiscount > 0 &&
      percentageDiscount > 0 &&
      percentageDiscount <= 100
    ) {
      effectiveDiscount = 0;
    } else if (fixedDiscount > 0) {
      effectiveDiscount =
        estimatedTotal - fixedDiscount >= 0 ? fixedDiscount : estimatedTotal;
    } else if (percentageDiscount > 0 && percentageDiscount <= 100) {
      effectiveDiscount = (estimatedTotal * percentageDiscount) / 100;
    }
    return effectiveDiscount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.fixedDiscount, cart?.percentageDiscount, estimatedTotal]);

  return (
    <>
      <Modal dimmer="blurring" open onClose={closeModal} size="large">
        <Modal.Header>
          <Row justify="space-between">
            <span>Créer une nouvelle commande</span>
            {user && (
              <span style={{ opacity: 0.5 }}>
                {user.firstname} {user.lastname}
              </span>
            )}
          </Row>
        </Modal.Header>
        <Modal.Content style={{ height: "75vh", overflow: "auto" }}>
          <>
            <Step.Group>
              <Step active={step === "CUSTOMERS_INFORMATIONS"}>
                <Icon name="id card" />
                <Step.Content>
                  <Step.Title>Client</Step.Title>
                </Step.Content>
              </Step>

              <Step active={step === "PRODUCTS_SELECTION"}>
                <Icon name="cart arrow down" />
                <Step.Content>
                  <Step.Title>Produits</Step.Title>
                </Step.Content>
              </Step>

              <Step active={step === "DELIVERY_INFORMATIONS"}>
                <Icon name="info" />
                <Step.Content>
                  <Step.Title>Livraison</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>

            {step === "CUSTOMER_INFORMATIONS" && (
              <UserInformations onUserSubmit={setUserCart} />
            )}

            {step === "PRODUCTS_SELECTION" && (
              <>
                {selectedProduct && (
                  <ProductModal
                    product={selectedProduct}
                    onProductAdd={onProductAdd}
                    open={!!selectedProduct}
                    closeModal={() => setSelectedProduct(null)}
                  />
                )}
                <ProductsSelection
                  cart={cart}
                  onSelectedProduct={setSelectedProduct}
                  handleQuantityUpdate={handleQuantityUpdate}
                />
              </>
            )}

            {step === "DELIVERY_INFORMATIONS" && (
              <>
                <Row>
                  <Column flexBasis={59}>
                    <DeliveryInformations
                      user={user}
                      initialDeliveryFee={cart.deliveryFee}
                      onUpdate={onDeliveryInformationUpdate}
                      onMultipleUpdates={onDeliveryInformationMultipleUpdates}
                    />
                    {!cart.orderProducts.length === 0 && (
                      <Message warning>
                        <Message.Header>
                          Impossible de passer commande
                        </Message.Header>
                        <Message.Content>La panier est vide</Message.Content>
                      </Message>
                    )}
                  </Column>
                  <Column flexBasis={2} />
                  <Column flexBasis={39}>
                    <CartSummary
                      orderProducts={cart.orderProducts}
                      deliveryFee={cart.deliveryFee}
                      discount={effectiveDiscount}
                      interactive
                      editable
                      handleQuantityUpdate={handleQuantityUpdate}
                    />
                  </Column>
                </Row>
              </>
            )}
          </>
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={onModalClose}>
            Annuler la commande
          </Button>

          {step === "PRODUCTS_SELECTION" && (
            <Button
              primary
              disabled={addProductToCart.loading}
              loading={addProductToCart.loading}
              onClick={() => setStep("DELIVERY_INFORMATIONS")}
            >
              Suivant
            </Button>
          )}

          {step === "DELIVERY_INFORMATIONS" && (
            <>
              <Button
                disabled={createOrder.loading}
                loading={createOrder.loading}
                onClick={() => setStep("PRODUCTS_SELECTION")}
              >
                Retour au panier
              </Button>
              <Button
                color="green"
                disabled={!isCartValid || createOrder.loading}
                loading={createOrder.loading}
                onClick={handleOrderCreation}
              >
                Passer commande
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CreateOrderModal;
