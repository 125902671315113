import React, { useMemo } from "react";
import { Switch, Link, withRouter } from "react-router-dom";
import { Button, Breadcrumb } from "semantic-ui-react";
import { Column, Row } from "../common";
import {
  RouteWithSubRoutes,
  getRestaurantId,
  getCategoryId,
} from "../../resources/helpers";
import "../../styles/Restaurants.scss";

const translations = [
  { path: "restaurants", name: "Restaurants" },
  { path: "create", name: "Création" },
  { path: "products", name: "Produits" },
];

const pathTranslate = (path) =>
  translations.find((entry) => entry.path === path)?.name ?? path;

const RestaurantsView = ({ routes, location, match, history }) => {
  const redirectToCreate = () => {
    history.push("/restaurants/create");
  };

  const redirectToProductCreate = () => {
    const restaurantId = getRestaurantId(location);
    const categoryId = getCategoryId(location);

    history.push(
      `/restaurants/${restaurantId}/categories/${categoryId}/products/create`,
      {
        restaurantId,
        categoryId,
      }
    );
  };

  const redirectToCategoryCreate = () => {
    const restaurantId = getRestaurantId(location);

    history.push(`/restaurants/${restaurantId}/categories/create`, {
      restaurantId,
    });
  };

  const isProductTable = useMemo(
    () =>
      /\/restaurants\/[0-9]+\/categories\/[0-9]+\/products$/gm.test(
        location.pathname
      ),
    [location.pathname]
  );

  const isCategoryTable = useMemo(
    () => /\/restaurants\/[0-9]+\/categories$/gm.test(location.pathname),
    [location.pathname]
  );

  const paths = location.pathname
    .split("/")
    .filter((v) => v)
    .map((p, i, arr) => {
      if (i === arr.length - 1)
        return {
          key: i,
          content: pathTranslate(p),
          active: i === arr.length - 1,
        };
      return {
        key: i,
        content: (
          <Link to={`/${arr.slice(0, i + 1).join("/")}`}>
            {pathTranslate(p)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1,
      };
    });

  return (
    <div id="restaurants">
      <Column className="header">
        <Row align="center">
          <Breadcrumb icon="right angle" sections={paths} size="large" />
          {location.pathname === "/restaurants" && (
            <Button
              icon="plus"
              color="green"
              content="Ajouter un restaurant"
              style={{ marginLeft: "1rem" }}
              onClick={() => redirectToCreate()}
            />
          )}
          {isProductTable && (
            <Button
              icon="plus"
              color="green"
              content="Ajouter un produit"
              style={{ marginLeft: "1rem" }}
              onClick={() => redirectToProductCreate()}
            />
          )}
          {isCategoryTable && (
            <Button
              icon="plus"
              color="green"
              content="Ajouter une catégorie"
              style={{ marginLeft: "1rem" }}
              onClick={() => redirectToCategoryCreate()}
            />
          )}
        </Row>
      </Column>

      <Switch>
        {routes.map((route, index) => (
          <RouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
};

export default withRouter(RestaurantsView);
