import { Button } from "semantic-ui-react";
import { OrderRow } from ".";

const IncomingTab = ({ orders, onAssignClick }) => {
  return (
    <div className="order-rows">
      {orders.map((order) => (
        <OrderRow
          key={order.id}
          order={order}
          withCountdown
          lastElement={
            <Button
              className="attribution-button"
              color="blue"
              onClick={(e) => {
                e.stopPropagation();
                onAssignClick(order.id);
              }}
            >
              Attribuer
            </Button>
          }
        />
      ))}
    </div>
  );
};

export default IncomingTab;
