import { useContext } from "react";
import { Countdown, TimeDisplay, PaymentBadge } from ".";
import { OrderContext } from "contexts/orders";
import { Clickable, Column, Ping, Price, Row } from "components/common";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";
import { getOrderTotal } from "resources/helpers/orderTotal";

const OrderRow = ({
  order,
  withCountdown,
  withEstimatedPickupTime,
  displayRestaurantAcceptation = false,
  lastElement,
  deliverymanColorMap,
  children,
  onClick,
}) => {
  const {
    state: { selectedOrder, unwatchedOrderIds },
    dispatch,
  } = useContext(OrderContext);

  const deliveryman = order.orderDelivery.deliveryman;
  const hasRestaurantOrdersNotAcknowledged = order.restaurantOrders.some(
    (orderRestaurant) => orderRestaurant.status === "ASSIGNED"
  );

  const orderTotal = getOrderTotal(order);

  return (
    <Clickable
      className={`order-card${
        selectedOrder?.id === order.id ? " selected" : ""
      }`}
      highlightOnHover
      onClick={() => {
        onClick
          ? onClick(order)
          : dispatch({ type: "SET_SELECTED_ORDER", order });
      }}
    >
      <Row childrenMargin="small" lastChildOnEnd>
        {deliverymanColorMap && (
          <div
            className="border-color"
            style={{
              backgroundColor: deliverymanColorMap.get(deliveryman.id)?.value,
            }}
          >{`${deliveryman.firstname} ${deliveryman.lastname}`}</div>
        )}
        {unwatchedOrderIds.some(({ orderId }) => orderId === order.id) && (
          <Ping style={{ alignSelf: "center", marginRight: 0 }} />
        )}
        <span className="number">{order.number}</span>

        <Column style={{ flexBasis: "50%" }}>
          <span className="customer">{`${order.customer.firstname} ${order.customer.lastname}`}</span>
          <span className="address">{order.deliveryAddress}</span>
          {/* <span className="city">{order.city}</span> */}
        </Column>

        <Column align="flex-end">
          <Price fontSize={1.3}>{renderCentimesAsAmount(orderTotal)}</Price>
          <span className="payment-method">
            <PaymentBadge paymentMethod={order.paymentMethod} />
          </span>
        </Column>

        {withCountdown && order.orderDelivery.estimatedDeliveryAt && (
          <Countdown endAt={order.orderDelivery.estimatedDeliveryAt} />
        )}

        {displayRestaurantAcceptation && hasRestaurantOrdersNotAcknowledged && (
          <div className={"unacknowledged-restaurant-order-tag"}>
            Non accepté
          </div>
        )}

        {withEstimatedPickupTime &&
          order.restaurantOrders[0].estimatedPickupAt && (
            <TimeDisplay time={order.restaurantOrders[0].estimatedPickupAt} />
          )}

        {children}

        {lastElement}
      </Row>
    </Clickable>
  );
};

export default OrderRow;
