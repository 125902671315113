import React from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { useRequest, useInput, useToggle } from "../../resources/hooks";
import { createProductCategory } from "../../services/productCategories";
import { getRestaurantId } from "../../resources/helpers";

const ProductCategoriesUpdate = ({
  categoryId,
  closeModal,
  match,
  location,
}) => {
  const restaurantId = getRestaurantId(location);

  const create = useRequest({
    service: createProductCategory,
    requestOnInit: false,
  });
  // TODO: log error
  const label = useInput("");

  const inputs = [label];

  const handleCreate = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (hasErrors) return;

    const result = await create.request({
      params: {
        restaurantId,
        label: label.value,
      },
    });

    if (result.response) handleClose();
  };

  const handleClose = () => {
    closeModal({ withRedirect: `/restaurants/${restaurantId}/categories` });
  };

  return (
    <Modal onClose={handleClose} open={true} size="small">
      <Modal.Header>Créer une catégorie</Modal.Header>

      <Modal.Content>
        <Form>
          <Form.Group widths={6}>
            <Form.Field width={6}>
              <Form.Input label="Nom de catégorie" {...label.props} />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button color="black" loading={create.loading} onClick={handleClose}>
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          onClick={handleCreate}
          positive
          loading={create.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default withRouter(ProductCategoriesUpdate);
