const Clickable = ({ onClick, highlightOnHover, children, className }) => {
  return (
    <div
      onClick={onClick}
      className={`clickable${highlightOnHover ? " hoverable" : ""}${
        className ? ` ${className}` : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Clickable;
