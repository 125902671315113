import { useContext } from "react";
import { Button, Header, Message, Icon } from "semantic-ui-react";
import {
  Badge,
  Column,
  Divider,
  OrderProducts,
  OrderStatus,
  Price,
  Row,
} from "components/common";
import PaymentBadge from "./PaymentBadge";
import * as OrderService from "services/orders";
import { OrderContext } from "contexts/orders";
import { formatToUppercase } from "resources/helpers";
import dayjs from "dayjs";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";
import { getOrderTotal } from "resources/helpers/orderTotal";
import { useRequest } from "resources/hooks";

const SelectedOrder = ({
  order,
  dismiss,
  onAssignClick,
  onCancelClick,
  onProductCancelClick,
  hideActions,
}) => {
  const { dispatch } = useContext(OrderContext);

  const cancelRestaurantOrder = useRequest({
    service: OrderService.cancelRestaurantOrder,
    requestOnInit: false,
  });

  const actions = (orderStatus) => {
    switch (orderStatus) {
      case "CONFIRMED":
        return (
          <>
            <Button color="blue" onClick={() => onAssignClick(order.id)}>
              Attribuer
            </Button>
            <Button
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                onCancelClick(order.id);
              }}
            >
              Annuler la commande
            </Button>
          </>
        );
      case "ASSIGNED":
      case "DELIVERING":
        return (
          <>
            <Button color="blue" onClick={() => onAssignClick(order.id)}>
              Changer livreur
            </Button>
            <Button
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                onCancelClick(order.id);
              }}
            >
              Annuler la commande
            </Button>
          </>
        );
      case "DELIVERED":
        return (
          <>
            <Button
              negative
              basic
              onClick={() => onProductCancelClick(order.id)}
            >
              Retirer produits
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const handleRestaurantOrderCancel = async (orderId, restaurantId) => {
    const updatedOrderResult = await cancelRestaurantOrder.request({
      params: { orderId, restaurantId },
    });

    if (updatedOrderResult.response) {
      dispatch({ type: "UPDATE_ORDER", order: updatedOrderResult.response });
    }
  };

  return (
    <div className="selectedOrder">
      <div className="header">
        <h2>{order.number}</h2>
        <OrderStatus
          status={order.orderDelivery.status}
          large
          inverted
          style={{ whiteSpace: "nowrap" }}
        />
        <Button
          circular
          icon="close"
          onClick={dismiss}
          className="close-button"
        />
      </div>

      <div className="content">
        <OrderProducts
          orderProducts={order.orderProducts}
          restaurantOrders={order.restaurantOrders}
          deliveryFee={order.checkout?.deliveryFee}
          discount={order.checkout?.discountAmount}
          tip={order.checkout?.tipAmount}
          discountCode={order.checkout?.discountCode}
          onRestaurantOrderCancel={(restaurantId) =>
            handleRestaurantOrderCancel(order.id, restaurantId)
          }
        />

        <Divider />

        <Column className="customer category" childrenMargin="small">
          <Header
            as="h3"
            className="name"
          >{`${order.customer.firstname} ${order.customer.lastname}`}</Header>

          <Row childrenMargin="small">
            <Column>
              <span style={{ fontWeight: "bold" }}>Adresse</span>
              <span>{order.deliveryAddress}</span>
              <span>{order.city}</span>
            </Column>
          </Row>

          <Row childrenMargin="small" justify="space-between">
            <Column>
              <span style={{ fontWeight: "bold" }}>Téléphone</span>
              <span>{order.phone}</span>
            </Column>

            <Column>
              <span style={{ fontWeight: "bold" }}>Montant</span>
              <span>
                {<Price>{renderCentimesAsAmount(getOrderTotal(order))}</Price>}
              </span>
            </Column>

            <Column>
              <span style={{ fontWeight: "bold" }}>Moyen de paiement</span>
              <PaymentBadge paymentMethod={order.paymentMethod} />
            </Column>
          </Row>

          {order.comments && (
            <Column>
              <span style={{ fontWeight: "bold" }}>Commentaires</span>
              <Message warning icon>
                <Icon name="warning sign" />
                <Message.Content>{order.comments}</Message.Content>
              </Message>
            </Column>
          )}
        </Column>

        {order.orderDelivery.deliveryman && (
          <>
            <Divider />

            <div className="delivery category">
              <div className="informations-container">
                <div className="col">
                  <div className="row">
                    <span className="title">Livreur</span>
                    <span>{`${order.orderDelivery.deliveryman.firstname} ${order.orderDelivery.deliveryman.lastname}`}</span>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <span className="title">Téléphone</span>
                    <span>{order.orderDelivery.deliveryman.phone}</span>
                  </div>
                </div>

                <div className="col">
                  <div className="row">
                    <span className="title">Status</span>
                    <Badge
                      valid={order.orderDelivery.deliverymanConfirm}
                      error={!order.orderDelivery.deliverymanConfirm}
                    >
                      {order.orderDelivery.deliverymanConfirm
                        ? "Confirmé"
                        : "En attente de validation"}
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Divider />

        <div className="order-informations category">
          <Row justify="space-between">
            <Column className="col">
              <span style={{ fontWeight: "bold" }}>Commande</span>
              <span>
                {formatToUppercase(
                  dayjs(order.createdAt)
                    .locale("fr")
                    .format("dddd DD MMMM à HH:mm")
                )}
              </span>
            </Column>
            <Column>
              <span style={{ fontWeight: "bold" }}>Livraison estimée</span>
              <span>
                {formatToUppercase(
                  dayjs(order.orderDelivery.estimatedDeliveryAt)
                    .locale("fr")
                    .format("dddd DD MMMM à HH:mm")
                )}
              </span>
            </Column>
            {order.orderDelivery.deliveryAt && (
              <Column>
                <span style={{ fontWeight: "bold" }}>Livraison effectuée</span>
                <span>
                  {formatToUppercase(
                    dayjs(order.orderDelivery.deliveryAt)
                      .locale("fr")
                      .format("dddd DD MMMM à HH:mm")
                  )}
                </span>
              </Column>
            )}
          </Row>
        </div>
      </div>

      <div className="footer">
        {!hideActions && (
          <>
            <Divider />
            <div className="actions">{actions(order.orderDelivery.status)}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectedOrder;
