import { useState } from "react";

const useTextArea = (initialValue, { forwardProps, maxLength } = {}) => {
	const [value, setValue] = useState(initialValue);
	const [error, setError] = useState(null);

	const handleCommentsUpdate = (text) => {
		if (maxLength && text.length <= maxLength) {
			setValue(text);
		}
		setError(
			maxLength > 0
				? text.length > value.length && value.length >= maxLength
				: false
		);
	};

	return {
		value,
		props: {
			value,
			onChange: (event) => {
				handleCommentsUpdate(event.target.value);
			},
			error,
			onBlur: () => {
				setError(false);
			},
			...forwardProps,
		},
		error: error,
	};
};

export default useTextArea;
