const Price = ({ children, fontSize = 1 }) => {
  if (typeof children !== "string") {
    return <span>?</span>;
  }
  const [euros, rest] = children.split(",");

  return (
    <span>
      <span style={{ fontSize: `${fontSize}rem` }}>{euros}</span>
      <span style={{ fontSize: `${Math.max(fontSize * 0.6, 0.8)}rem` }}>
        {"," + rest}
      </span>
    </span>
  );
};

export default Price;
