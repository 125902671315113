import { useContext, useEffect, useState } from "react";
import { DateRangeInput3 } from "@blueprintjs/datetime2";
import { Button, Icon, Label, Popup, Statistic } from "semantic-ui-react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { OrderContext } from "contexts/orders";
import { formatToUppercase } from "resources/helpers";
import { Column, Price, Row, WithLoader, Divider } from "components/common";
import { OrderRow, SelectedOrder } from "components/orders/components";
import { fetchDeliveredOrders } from "services/orders";
import * as RestaurantService from "services/restaurants";
import { useRequest } from "resources/hooks";
import {
  getDeliveryFee,
  getDiscount,
  getOrderTotal,
  getProductTotal,
  getTip,
  getTotal,
} from "resources/helpers/orderTotal";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";

const API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

const byOrderNumber = (a, b) => (a.number < b.number ? -1 : 1);
const byDeliveryDate = (a, b) =>
  new Date(a.orderDelivery.deliveryAt) > new Date(b.orderDelivery.deliveryAt)
    ? 1
    : -1;

const RestaurantsProfitAnalysis = () => {
  const {
    state: { selectedOrder },
    dispatch,
  } = useContext(OrderContext);

  const [startDate, setStartDate] = useState(
    dayjs().startOf("day").startOf("month").add(12, "hour").toDate()
  );
  const [endDate, setEndDate] = useState(
    dayjs().startOf("day").add(12, "hour").toDate()
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [hideByDefault, setHideByDefault] = useState(true);

  const [periodDetails, setPeriodDetails] = useState(null);
  const [productsByRestaurant, setProductsByRestaurant] = useState(new Map());
  const [ordersByDay, setOrdersByDay] = useState(null);

  const getDelivered = useRequest({
    service: fetchDeliveredOrders,
    defaultParams: {
      startDate,
      endDate,
    },
  });

  const getRestaurants = useRequest({
    service: RestaurantService.fetchRestaurants,
  });

  const restaurants = getRestaurants.response || [];

  const displayedRestaurantIds = restaurants
    ?.filter((restaurant) => restaurant.defaultReportDisplay || !hideByDefault)
    .map((restaurant) => restaurant.id);

  useEffect(() => {
    if (getDelivered.response) processOrders(getDelivered.response);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDelivered.response, hideByDefault]);

  const processOrders = (orders) => {
    const pd = orders.reduce(
      (acc, order) => {
        const filteredOrdersProducts = order.orderProducts.filter((product) =>
          displayedRestaurantIds.includes(product.originalRestaurant.id)
        );
        return {
          total:
            acc.total +
            getTotal(filteredOrdersProducts) +
            getDeliveryFee(order) -
            getDiscount(order) +
            getTip(order),
          productsTotal: acc.productsTotal + getTotal(filteredOrdersProducts),
          deliveryFees: acc.deliveryFees + order.deliveryFee,
          discountsTotal: acc.discountsTotal + (order.discount || 0),
          restaurantsTotal:
            acc.restaurantsTotal +
            getTotal(filteredOrdersProducts, { restaurantView: true }),
        };
      },
      {
        total: 0,
        productsTotal: 0,
        restaurantsTotal: 0,
        deliveryFees: 0,
        discountsTotal: 0,
      }
    );
    setPeriodDetails(pd);

    const allProducts = orders.map(({ orderProducts }) => orderProducts).flat();

    const restaurantsMap = new Map();
    allProducts.forEach((product) => {
      if (restaurantsMap.has(product.originalRestaurant.id)) {
        const currentEntry = restaurantsMap.get(product.originalRestaurant.id);

        restaurantsMap.set(product.originalRestaurant.id, {
          ...currentEntry,
          products: [...currentEntry.products, product],
          total: currentEntry.total + getProductTotal(product),
          restaurantTotal:
            currentEntry.restaurantTotal +
            getProductTotal(product, { restaurantView: true }),
        });
      } else {
        restaurantsMap.set(product.originalRestaurant.id, {
          label: product.originalRestaurant.label,
          products: [product],
          total: getProductTotal(product),
          restaurantTotal: getProductTotal(product, { restaurantView: true }),
        });
      }
    });
    setProductsByRestaurant(restaurantsMap);

    const o = [];
    const ordersWithDate = orders
      .map((order) => ({
        ...order,
        dateLabel: dayjs(order.orderDelivery.deliveryAt)
          .locale("fr")
          .format("dddd DD MMMM"),
      }))
      .sort(byDeliveryDate);

    ordersWithDate.forEach((order) => {
      const dayOrdersIndex = o.findIndex((dayOrders) =>
        dayjs(order.orderDelivery.deliveryAt).isSame(
          dayjs(dayOrders.orders[0].orderDelivery.deliveryAt),
          "day"
        )
      );
      if (dayOrdersIndex >= 0) {
        o[dayOrdersIndex].orders = [...o[dayOrdersIndex].orders, order];
        o[dayOrdersIndex].total += getOrderTotal(order);
      } else {
        o.push({
          orders: [order],
          total: getOrderTotal(order),
          dateLabel: formatToUppercase(order.dateLabel),
        });
      }
    });

    for (const dateGroupedOrders of o) {
      dateGroupedOrders.orders.sort(byOrderNumber);
    }

    setOrdersByDay(o);
  };

  const handleDateChange = async ([start, end]) => {
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const { response: orders } = await getDelivered.request({
        params: {
          startDate: start,
          endDate: end,
        },
      });

      if (orders) processOrders(orders);
    }
  };

  return (
    <div id="restaurants-profit-analysis">
      <Row>
        <div className="board">
          <Row
            align="center"
            style={{ marginBottom: "2rem" }}
            childrenMargin="small"
          >
            <h3 style={{ whiteSpace: "nowrap" }}>Date des livraisons</h3>

            <DateRangeInput3
              onChange={handleDateChange}
              parseDate={(str) => new Date(str)}
              formatDate={(date) =>
                formatToUppercase(
                  dayjs(date).locale("fr").format("dddd DD MMMM")
                )
              }
              value={[startDate, endDate]}
              todayButtonText="Aujourd'hui"
              shortcuts={false}
              highlightCurrentDay
              allowSingleDayRange
            />
          </Row>

          <WithLoader loading={getDelivered.loading}>
            <Column childrenMargin="small">
              {periodDetails && (
                <Statistic.Group widths="three" size="small">
                  <Statistic color="blue">
                    <Statistic.Value>
                      {renderCentimesAsAmount(periodDetails.total)}
                    </Statistic.Value>
                    <Statistic.Label>Total</Statistic.Label>
                  </Statistic>
                  <Statistic color="red">
                    <Statistic.Value>
                      - {renderCentimesAsAmount(periodDetails.restaurantsTotal)}
                    </Statistic.Value>
                    <Statistic.Label>Total restaurants</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      ={" "}
                      {renderCentimesAsAmount(
                        periodDetails.total - periodDetails.restaurantsTotal
                      )}
                    </Statistic.Value>
                    <Statistic.Label>Marge</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              )}

              {[...productsByRestaurant.keys()].length > 0 &&
                [...productsByRestaurant.entries()]
                  .filter(([restaurantId]) =>
                    restaurants?.find(
                      (restaurant) => restaurant.id === restaurantId
                    )?.defaultReportDisplay
                      ? true
                      : !hideByDefault
                  )
                  .map(([restaurantId, { label, restaurantTotal, total }]) => (
                    <Row
                      justify="space-between"
                      align="center"
                      childrenMargin="tiny"
                      key={restaurantId}
                    >
                      <Row align="center" childrenMargin="tiny">
                        <h3 style={{ whiteSpace: "nowrap" }}>{label}</h3>

                        <a
                          href={`${API_URL}/api/analysis/invoices/restaurants/${restaurantId}/products?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <Button
                            size="mini"
                            color="violet"
                            icon
                            labelPosition="right"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Icon name="download" />
                            Export produits
                          </Button>
                        </a>
                      </Row>
                      <Divider fluid dark />
                      <span className="deliveryman-summary-prices">
                        <Popup
                          trigger={
                            <span>
                              {`👨‍🍳 `}
                              <Price fontSize={1.2}>
                                {renderCentimesAsAmount(restaurantTotal)}
                              </Price>
                            </span>
                          }
                          content="Dû au restaurant"
                          inverted
                        />
                        {" / "}
                        <Popup
                          trigger={
                            <span>
                              {`💰 `}
                              <Price fontSize={1.2}>
                                {renderCentimesAsAmount(total)}
                              </Price>
                            </span>
                          }
                          content="Montant total vendu (sans frais de service et pourboires)"
                          inverted
                        />
                      </span>
                    </Row>
                  ))}
            </Column>

            <Column childrenMargin="medium" style={{ marginTop: "3rem" }}>
              <h1 onClick={() => setHideByDefault(false)}>
                Commandes par jour
              </h1>

              {ordersByDay?.length > 0 &&
                ordersByDay.map((dayOrders) => (
                  <Column key={dayOrders.dateLabel} childrenMargin="small">
                    <Row align="center" childrenMargin="tiny">
                      <h3 style={{ whiteSpace: "nowrap" }}>
                        {dayOrders.dateLabel}
                      </h3>

                      {selectedDate === dayOrders.dateLabel ? (
                        <Button
                          size="mini"
                          color="teal"
                          icon
                          labelPosition="left"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => setSelectedDate(null)}
                        >
                          <Icon name="hide" />
                          Masquer
                        </Button>
                      ) : (
                        <Button
                          size="mini"
                          color="blue"
                          icon
                          labelPosition="left"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => setSelectedDate(dayOrders.dateLabel)}
                        >
                          <Icon name="unhide" />
                          Voir les commandes
                        </Button>
                      )}
                      <Divider fluid dark />
                      <span className="circular-count">
                        {dayOrders.orders.length}
                      </span>

                      <Label color="green">
                        {renderCentimesAsAmount(dayOrders.total)}
                      </Label>
                    </Row>
                    {selectedDate === dayOrders.dateLabel &&
                      dayOrders.orders.map((order) => (
                        <OrderRow
                          key={`${dayOrders.dateLabel}-${order.id}`}
                          order={order}
                        />
                      ))}
                  </Column>
                ))}
            </Column>
          </WithLoader>
        </div>

        <div className="selected-order-container">
          {selectedOrder && (
            <SelectedOrder
              order={selectedOrder}
              dismiss={() => dispatch({ type: "DISMISS_SELECTED_ORDER" })}
              hideActions
            />
          )}
        </div>
      </Row>
    </div>
  );
};

export default RestaurantsProfitAnalysis;
