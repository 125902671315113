import React, { useState, useContext } from "react";
import { Tab, Button, Segment, Header, Icon } from "semantic-ui-react";
import { OrderContext } from "../../contexts/orders";
import { assignOrder } from "../../services/socket";
import {
  IncomingTab,
  PreparationTab,
  DeliveryTab,
  FinishedTab,
  PlannedTab,
  SelectedOrder,
  CancelModal,
  AssignModal,
  CreateOrderModal,
  CancelProductsModal,
} from "./components";
import { Column, Divider, Row } from "../common";
import "../../styles/Orders.scss";

const Orders = ({ routes }) => {
  const {
    state: { orders, selectedOrder, deliverymen },
    dispatch,
  } = useContext(OrderContext);
  const [assignOrderId, setAssignOrderId] = useState(null);
  const [canceledOrderId, setCanceledOrderId] = useState(null);
  const [productCanceledMode, setProductCanceledMode] = useState(null);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const assign = (orderId, deliverymanId, pickupTime) => {
    setAssignOrderId(null);
    assignOrder({ orderId, deliverymanId, pickupTime }, (err, { order }) => {
      if (err) return;
      dispatch({ type: "UPDATE_ORDER", order });
    });
  };
  const confimedOrders = (orders || []).filter(
    (order) =>
      order.orderDelivery === null || order.orderDelivery.status === "CONFIRMED"
  );
  const preparationOrders = (orders || []).filter(
    (order) => order.orderDelivery?.status === "ASSIGNED"
  );
  const deliveringOrders = (orders || []).filter(
    (order) => order.orderDelivery?.status === "DELIVERING"
  );
  const panes = [
    {
      menuItem: {
        key: "confirmed",
        content: (
          <Row align="center" childrenMargin="tiny">
            Entrantes
            <span className="circular-count">{confimedOrders.length}</span>
          </Row>
        ),
      },
      render: () => (
        <IncomingTab
          orders={confimedOrders}
          onAssignClick={(orderId) => setAssignOrderId(orderId)}
        />
      ),
    },
    {
      menuItem: {
        key: "preparation",
        content: (
          <Row align="center" childrenMargin="tiny">
            En préparation
            <span className="circular-count">{preparationOrders.length}</span>
          </Row>
        ),
      },
      render: () => (
        <PreparationTab
          orders={(orders || []).filter(
            (order) => order.orderDelivery.status === "ASSIGNED"
          )}
        />
      ),
    },
    {
      menuItem: {
        key: "delivering",
        content: (
          <Row align="center" childrenMargin="tiny">
            En livraison
            <span className="circular-count">{deliveringOrders.length}</span>
          </Row>
        ),
      },
      render: () => <DeliveryTab orders={deliveringOrders} />,
    },
    {
      menuItem: "Terminées",
      render: () => <FinishedTab />,
    },
    {
      menuItem: "Prévues",
      render: () => <PlannedTab />,
    },
  ];

  return (
    <div id="orders">
      <div className="content">
        <div className="board">
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            className="order-cards"
          />
        </div>

        <Segment placeholder className="selected-order-container">
          {selectedOrder ? (
            <SelectedOrder
              order={selectedOrder}
              dismiss={() => dispatch({ type: "DISMISS_SELECTED_ORDER" })}
              onAssignClick={(orderId) => setAssignOrderId(orderId)}
              onCancelClick={(orderId) => setCanceledOrderId(orderId)}
              onProductCancelClick={(orderId) =>
                setProductCanceledMode(orderId)
              }
            />
          ) : (
            <Column justify="space-evenly" fluid>
              <Header icon>
                <Icon name="search" />
                Selectionnez une commande
              </Header>

              <Divider>
                <span style={{ fontSize: "1.5rem" }}>Ou</span>
              </Divider>

              <Column align="center">
                <Header icon>
                  <Icon name="plus" />
                  Créer une nouvelle commande
                </Header>
                <Button
                  color="green"
                  content="Créer"
                  onClick={() => setIsCreatingOrder(true)}
                />
              </Column>
            </Column>
          )}
        </Segment>
      </div>

      {assignOrderId && (
        <AssignModal
          deliverymen={deliverymen}
          assign={assign}
          orderId={assignOrderId}
          restaurantNames={orders
            .find((order) => order.id === assignOrderId)
            ?.restaurantOrders.map((restaurant) => restaurant.label)}
          orderNumber={
            orders.find((order) => order.id === assignOrderId)?.number
          }
          closeModal={() => setAssignOrderId(null)}
        />
      )}

      {canceledOrderId && (
        <CancelModal
          orderId={canceledOrderId}
          orderNumber={
            orders.find((order) => order.id === canceledOrderId)?.number
          }
          hasPaidOnline={
            orders.find((order) => order.id === canceledOrderId)
              ?.paymentMethod === "card_online"
          }
          closeModal={() => setCanceledOrderId(null)}
        />
      )}

      {productCanceledMode && (
        <CancelProductsModal
          orderId={productCanceledMode}
          closeModal={() => setProductCanceledMode(null)}
        />
      )}

      {isCreatingOrder && (
        <CreateOrderModal closeModal={() => setIsCreatingOrder(false)} />
      )}

      <div className="orders-navigation">{/* TODO somethign ? */}</div>
    </div>
  );
};

export default Orders;
