import { useState } from "react";

const useToggle = (initialValue, props) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    props: {
      onChange: (event, data) => {
        setValue(data.checked);
      },
      ...props,
      checked: value,
    },
    setValue: (newValue) => {
      setValue(newValue);
    },
  };
};

export default useToggle;
