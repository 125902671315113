const getOrderStatus = (status) => {
  switch (status) {
    case "CONFIRMED":
      return orderStatus.confirmed;
    case "TO_ASSIGN":
      return orderStatus.toAssign;
    case "ASSIGNED":
      return orderStatus.assigned;
    case "PREPARATION":
      return orderStatus.preparation;
    case "READY":
      return orderStatus.ready;
    case "DELIVERING":
      return orderStatus.delivering;
    case "DELIVERED":
      return orderStatus.delivered;
    case "CANCELED":
      return orderStatus.canceled;
    default:
      return orderStatus.unknown;
  }
};

const orderStatus = {
  confirmed: {
    label: "A dispatcher",
    state: "warning",
    code: "CONFIRMED",
    emote: "⏳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  toAssign: {
    label: "À assigner",
    state: "warning",
    code: "TO_ASSIGN",
    emote: "⏳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
  assigned: {
    label: "En attente",
    state: "warning",
    code: "ASSIGNED",
    emote: "⏳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
  preparation: {
    label: "En préparation",
    state: "warning",
    code: "PREPARATION",
    emote: "🍳",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  ready: {
    label: "Prête",
    state: "valid",
    code: "READY",
    emote: "✅",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#67bc75",
    },
    invertedStyle: {
      color: "#67bc75",
      borderColor: "#67bc75",
    },
  },
  delivering: {
    label: "En livraison",
    state: "passive",
    code: "DELIVERING",
    emote: "🚙",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
  delivered: {
    label: "Livré",
    state: "valid",
    code: "DELIVERED",
    emote: "✅",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#67bc75",
    },
    invertedStyle: {
      color: "#67bc75",
      borderColor: "#67bc75",
    },
  },
  canceled: {
    label: "Annulée",
    state: "error",
    code: "CANCELED",
    emote: "❌",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bc6767",
    },
    invertedStyle: {
      color: "#bc6767",
      borderColor: "#bc6767",
    },
  },
  unknown: {
    label: "?",
    state: "passive",
    code: "unknown",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
};

export default getOrderStatus;
