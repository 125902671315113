import { useState } from "react";
import { Dropdown, Header, Label, Search } from "semantic-ui-react";
import * as ProductService from "services/products";
import * as RestaurantService from "services/restaurants";
import { useDebounce, useRequest } from "resources/hooks";
import { Column, Divider, Price, Row } from "components/common";
import { CartSummary } from "components/products";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";

const ProductsSelection = ({
  cart,
  onSelectedProduct,
  handleQuantityUpdate,
}) => {
  const searchProducts = useRequest({
    service: ProductService.searchProducts,
    requestOnInit: false,
  });
  const getProduct = useRequest({
    service: ProductService.fetchProduct,
    requestOnInit: false,
  });
  const allRestaurants = useRequest({
    service: RestaurantService.fetchRestaurants,
    requestOnInit: true,
  });
  const restaurantProducts = useRequest({
    service: ProductService.fetchProductsByRestaurant,
    requestOnInit: false,
  });

  const [searchProductLabel, setSearchProductLabel] = useState("");

  const onTimeout = (type) => {
    searchProducts.request({ params: { label: searchProductLabel } });
  };

  const onProductSelect = async ({ id: productId, restaurantId }) => {
    const { response } = await getProduct.request({
      params: { productId },
    });

    onSelectedProduct({ ...response, restaurantId });
  };

  const onRestaurantSelect = async (restaurantId) => {
    await restaurantProducts.request({ params: { restaurantId } });
  };

  useDebounce(searchProductLabel, { onTimeout: () => onTimeout("products") });

  return (
    <>
      <Row fluid childrenMargin="small">
        <Column
          flexBasis={60}
          className="product-selection"
          childrenMargin="small"
        >
          <Row
            fluid
            align="center"
            justify="space-between"
            childrenMargin="tiny"
          >
            <Dropdown
              placeholder="Choix restaurant"
              search
              selection
              options={allRestaurants.response?.map((restaurant) => ({
                key: restaurant.id,
                value: restaurant.id,
                text: restaurant.label,
              }))}
              onChange={(e, { value }) => onRestaurantSelect(value)}
            />
            <Divider horizontal fluid style={{ minWidth: "400px" }}>
              OU
            </Divider>
            <Search
              className="search-input products"
              loading={searchProducts.loading}
              onResultSelect={(e, { result }) => onProductSelect(result)}
              onSearchChange={(e, { value }) => setSearchProductLabel(value)}
              resultRenderer={formatProducts}
              results={searchProducts.response}
              value={searchProductLabel}
              minCharacters={3}
              placeholder="Rechercher un produit"
              noResultsMessage="Aucun produit trouvé"
              fluid
            />
          </Row>

          {restaurantProducts.response?.productCategories.map(
            (productCategory) => (
              <div key={productCategory.id}>
                <Header as="h3">{productCategory.label}</Header>
                <div className="category-products">
                  {productCategory.products.map((product) => (
                    <Row
                      key={product.id}
                      className="product-card"
                      justify="space-between"
                      onClick={() =>
                        onProductSelect({
                          id: product.id,
                          restaurantId: restaurantProducts.response.id,
                        })
                      }
                    >
                      <b>{product.label}</b>{" "}
                      <Price>{renderCentimesAsAmount(product.price)}</Price>
                    </Row>
                  ))}
                </div>
              </div>
            )
          )}
        </Column>

        <Column flexBasis={40}>
          <CartSummary
            orderProducts={cart.orderProducts}
            deliveryFee={cart.deliveryFee}
            interactive
            editable
            handleQuantityUpdate={handleQuantityUpdate}
          />
        </Column>
      </Row>
    </>
  );
};

const formatProducts = ({ label, restaurant, productCategory }) => {
  return (
    <Column>
      <Header as="h4">
        {restaurant}
        <Header.Subheader>{productCategory}</Header.Subheader>
      </Header>
      <Label color="violet" horizontal>
        {label}
      </Label>
    </Column>
  );
};

export default ProductsSelection;
