import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function createCart({ userId }) {
  const { data } = await axios.post(`/api/carts/users/${userId}`);

  return data;
}

export async function getExistingCart({ userId }) {
  const { data } = await axios.get(`/api/carts/users/${userId}`);

  return data;
}

export async function updateCartExtraCosts({
  cartId,
  deliveryFee,
  tip,
  fixedDiscount,
  percentageDiscount,
}) {
  const { data } = await axios.put(`/api/carts/${cartId}/extraCosts`, {
    deliveryFee,
    tip,
    fixedDiscount,
    percentageDiscount,
  });

  return data;
}

export async function addProductToCart({
  cartId,
  productId,
  quantity,
  optionChoiceIds,
  comments,
}) {
  const { data } = await axios.post(
    `/api/carts/${cartId}/products/${productId}`,
    {
      quantity,
      optionChoiceIds,
      comments,
    }
  );

  return data;
}

export async function updateCart({
  cartId,
  deliveryAddress,
  comments,
  phone
}) {
  const { data } = await axios.put(`/api/admin/carts/${cartId}`, {
    deliveryAddress,
    comments,
    phone,
  });

  return data;
}

export async function updateOrderProduct({
  cartId,
  orderProductId,
  quantity,
  options,
  comments,
}) {
  const { data } = await axios.put(
    `/api/carts/${cartId}/orderProducts/${orderProductId}`,
    {
      quantity,
      options,
      comments,
    }
  );

  return data;
}

export async function deleteCart({ cartId }) {
  const { data } = await axios.delete(`/api/carts/${cartId}`);

  return data;
}

export async function createOrder({ cartId, ...params }) {
  const { data } = await axios.post(`/api/admin/carts/${cartId}/orders`, {
    ...params,
    paymentMethod: "MANUAL",
  });

  return data;
}
