const hourify = (timeQuantity, initialUnit) => {
  const minutes =
    initialUnit === "milliseconds"
      ? Math.floor(timeQuantity / 1000 / 60)
      : timeQuantity;

  const days = Math.floor(minutes / (24 * 60));
  const hours = Math.floor((minutes % (24 * 60)) / 60);
  const restMinutes = minutes % 60;

  return `${days ? `${days}j` : ""}${hours ? ` ${hours}h` : ""}${restMinutes === 0 && hours === 0 ? "" : ` ${restMinutes}mn`
    }`.trim();
};


export default hourify;
