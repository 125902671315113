import React, { useReducer, useEffect } from "react";
import { fetchActiveOrders } from "../services/orders";
import { toast } from "react-toastify";

let reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return {
        ...state,
        orders: action.orders,
      };
    case "CREATE_ORDER":
      return {
        ...state,
        orders: [...state.orders, action.order],
        unwatchedOrderIds: [
          ...state.unwatchedOrderIds,
          { orderId: action.order.id, toastId: action.toastId },
        ],
      };
    case "UPDATE_ORDER":
      let prunedOrders = state.orders.filter((o) => o.id !== action.order.id);
      prunedOrders = [...prunedOrders, action.order];

      const selectedOrder =
        state.selectedOrder && state.selectedOrder.id === action.order.id
          ? action.order
          : state.selectedOrder;

      return { ...state, orders: prunedOrders, selectedOrder };
    case "REMOVE_ORDER":
      return {
        ...state,
        orders: state.orders.filter((order) => order.id != action.order.id),
        selectedOrder:
          action.order.id == state.selectedOrder?.id
            ? null
            : state.selectedOrder,
      };
    case "ORDERS_LOADING":
      return {
        ...state,
        ordersLoading: true,
      };
    case "ORDERS_LOADING_END":
      return {
        ...state,
        ordersLoading: false,
      };

    case "SET_SELECTED_ORDER":
      removeToast(state.unwatchedOrderIds, action.order.id);

      return {
        ...state,
        selectedOrder:
          state.orders.find((order) => order.id === action.order.id) ||
          action.order,
        unwatchedOrderIds: state.unwatchedOrderIds.filter(
          ({ orderId }) => orderId != action.order.id
        ),
      };
    case "DISMISS_SELECTED_ORDER":
      return {
        ...state,
        selectedOrder: null,
      };

    case "SET_FINISHED_ORDERS":
      return {
        ...state,
        finishedOrders: action.finishedOrders,
      };

    case "SET_PLANNED_ORDERS":
      return {
        ...state,
        plannedOrders: action.plannedOrders,
      };

    case "SET_DELIVERYMEN":
      return {
        ...state,
        deliverymen: action.deliverymen,
      };
    case "DELIVERYMEN_LOADING":
      return {
        ...state,
        deliverymenLoading: true,
      };
    case "DELIVERYMEN_LOADING_END":
      return {
        ...state,
        deliverymenLoading: false,
      };
    default:
      return state;
  }
};

const removeToast = (unwatchedOrderIds, selectedOrderId) => {
  const toastId = unwatchedOrderIds.find(
    ({ orderId }) => orderId === selectedOrderId
  )?.toastId;

  if (toastId) toast.dismiss(toastId);
};

const initialState = {
  restaurantId: null,
  selectedOrder: null,
  orders: null,
  unwatchedOrderIds: [],
  finishedOrders: [],
  plannedOrders: [],
  deliverymen: null,
  ordersLoading: true,
};

const OrderContext = React.createContext(initialState);

function OrderProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function initiateOrders() {
    try {
      dispatch({ type: "ORDERS_LOADING" });
      const result = await fetchActiveOrders();
      dispatch({ type: "SET_ORDERS", orders: result });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({ type: "ORDERS_LOADING_END" });
    }
  }

  useEffect(() => {
    // if (state.restaurantId) initiateOrders();
    initiateOrders();
    // TODO: fetch all orders
  }, []);

  return (
    <OrderContext.Provider value={{ state, dispatch, initiateOrders }}>
      {props.children}
    </OrderContext.Provider>
  );
}
export { OrderContext, OrderProvider };
