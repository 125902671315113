import { useEffect, useState } from "react";
import hourify from "../../../resources/helpers/hourify";

const Countdown = ({ endAt }) => {
  const calculateRemainingTime = () => {
    const remainingMilliseconds = new Date(endAt) - new Date();
    return Math.floor(remainingMilliseconds / 1000 / 60);
  };

  const [remaining, setRemaining] = useState(calculateRemainingTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(calculateRemainingTime());
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`countdown${
        remaining < 10 && remaining > 0
          ? " nearly-late"
          : remaining <= 0
          ? " late"
          : ""
      }`}
    >
      {remaining > 0
        ? `prévue dans ${hourify(remaining)}`
        : `retard de ${hourify(-remaining)}`}
    </div>
  );
};

export default Countdown;
