const constants = {
  ROLES: {
    options: [
      {
        key: "CUSTOMER",
        text: "Client",
        value: "CUSTOMER",
      },
      {
        key: "DELIVERY",
        text: "Livreur",
        value: "DELIVERY",
      },
      {
        key: "ADMIN",
        text: "Administrateur",
        value: "ADMIN",
      },
    ],
    hashMap: {
      CUSTOMER: {
        label: "Client",
        color: "olive",
      },
      DELIVERY: {
        label: "Livreur 🥡",
        color: "violet",
      },
      ADMIN: {
        label: "Administrateur 👨‍💻",
        color: "orange",
      },
    },
  },
};

export default constants;
