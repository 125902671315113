import { getOrderStatus } from "../../resources/constants";

const OrderStatus = ({ status, inverted, large, style }) => (
  <div
    className={`order-status${large ? " large" : ""}`}
    style={{
      ...getOrderStatus(status)[inverted ? "invertedStyle" : "style"],
      ...style,
    }}
  >
    <span>{getOrderStatus(status).label}</span>
  </div>
);
export default OrderStatus;
