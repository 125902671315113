import { useEffect, useState } from "react";
import { Button, Modal, Form, Checkbox, Message } from "semantic-ui-react";
import { useRequest, useInput } from "../../resources/hooks";
import { WithLoader, AddressSearch } from "../common";
import { createUser, updateUser, fetchUser } from "../../services/users";
import constants from "../../resources/constants/roles";

const UserModal = ({ userId, closeModal }) => {
  const create = useRequest({
    service: createUser,
    requestOnInit: false,
  });
  const update = useRequest({
    service: updateUser,
    requestOnInit: false,
  });
  const get = useRequest({
    service: () => fetchUser({ userId }),
    requestOnInit: false,
  });

  const email = useInput("");
  const password = useInput("");
  const firstname = useInput("");
  const lastname = useInput("");
  const phone = useInput("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState("customer");
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (userId) {
      get.request();
    }
  }, []);

  useEffect(() => {
    if (get.response) {
      email.setValue(get.response.email);
      firstname.setValue(get.response.firstname);
      lastname.setValue(get.response.lastname);
      phone.setValue(get.response.phone);
      setAddress(get.response.address);
      setRole(get.response.role);
      setActive(get.response.active);
    }
  }, [get.response]);

  const inputs = [email, firstname, lastname, phone];
  if (!userId) inputs.push(password);

  const handleSubmit = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (hasErrors) return;

    const result = userId
      ? await update.request({
          params: {
            userId,
            email: email.value,
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            address,
            role,
            active,
            password: password.value,
          },
        })
      : await create.request({
          params: {
            email: email.value,
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            address,
            role,
            active,
            password: password.value,
          },
        });

    if (result.response) handleClose({ withReload: true });
  };

  const handleClose = (params) => {
    closeModal(params);
  };

  const loading = create.loading || update.loading;

  return (
    <Modal onClose={handleClose} open className="user-area-modal" size="small">
      <Modal.Header>
        {userId ? "Mise à jour profil utilisateur" : "Nouvel utilisateur"}
      </Modal.Header>

      <Modal.Content>
        <WithLoader modalMode loading={userId && get.loading}>
          <Form autocomplete={false}>
            <Form.Group className="form-row">
              <Form.Field width={16}>
                <Form.Input
                  label="Email"
                  {...email.props}
                  autocomplete={false}
                />
              </Form.Field>
              <Form.Field width={16}>
                <Form.Input
                  label="Mot de passe"
                  {...password.props}
                  type="password"
                  placeholder={
                    userId
                      ? "Pour modifier, entrer un nouveau mot de passe"
                      : ""
                  }
                  autocomplete={false}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group className="form-row">
              <Form.Field width={16}>
                <Form.Input label="Prénom" {...firstname.props} />
              </Form.Field>
              <Form.Field width={16}>
                <Form.Input label="Nom de famille" {...lastname.props} />
              </Form.Field>
            </Form.Group>

            <Form.Group className="form-row">
              <Form.Field width={5}>
                <Form.Input label="Téléphone" {...phone.props} />
              </Form.Field>
              <Form.Field width={11}>
                <label>Adresse de livraison</label>
                <AddressSearch
                  userAddress={address}
                  onSelect={(result) => setAddress(result)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group className="form-row">
              <Form.Field width={6}>
                <Form.Dropdown
                  label="Role"
                  placeholder="Selectionner"
                  fluid
                  selection
                  options={constants.ROLES.options}
                  value={role}
                  onChange={(e, { value }) => setRole(value)}
                />
              </Form.Field>
              <Form.Field width={2}>
                <label>Actif ?</label>
                <Checkbox
                  checked={active}
                  onChange={(e, { checked }) => setActive(checked)}
                  toggle
                />
              </Form.Field>
            </Form.Group>
          </Form>

          {(create.error || update.error) && (
            <Message negative>
              <Message.Header>Erreur</Message.Header>
              <Message.Content>{create.error || update.error}</Message.Content>
            </Message>
          )}
        </WithLoader>
      </Modal.Content>

      <Modal.Actions>
        <Button
          color="black"
          loading={loading}
          disabled={loading}
          onClick={handleClose}
        >
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          onClick={handleSubmit}
          positive
          loading={loading}
          disabled={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UserModal;
