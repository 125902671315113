import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchOption({ optionId }) {
  const { data } = await axios.get(`api/options/${optionId}`);

  return data;
}

export async function createOption({
  label,
  required,
  type,
  minimum,
  maximum,
  choices,
}) {
  const { data } = await axios.post(`/api/options`, {
    label,
    required,
    type,
    minimum,
    maximum,
    choices,
  });

  return data;
}

export async function assignOption({
  productId,
  optionId = "",
  label,
  required,
  type,
  minimum,
  maximum,
  choices,
}) {
  const { data } = await axios.post(
    `api/products/${productId}/options/${optionId}`,
    {
      label,
      required,
      type,
      minimum,
      maximum,
      choices,
    }
  );

  return data;
}

export async function searchOptions({ label }) {
  const { data } = await axios.get(`api/options/search`, {
    params: {
      label,
    },
  });

  return data;
}

export async function updateOption({
  optionId,
  label,
  required,
  type,
  minimum,
  maximum,
  choices,
}) {
  const { data } = await axios.put(`/api/options/${optionId}`, {
    label,
    required,
    type,
    minimum,
    maximum,
    choices,
  });

  return data;
}

export async function productOptionUpdate({
  productId,
  optionId,
  label,
  required,
  type,
  minimum,
  maximum,
  choices,
}) {
  const { data } = await axios.put(
    `/api/products/${productId}/options/${optionId}`,
    {
      label,
      required,
      type,
      minimum,
      maximum,
      choices,
    }
  );

  return data;
}

export async function removeOption({ optionId }) {
  const { data } = await axios.delete(`api/options/${optionId}`);

  return data;
}

export async function removeProductOption({ productId, optionId }) {
  const { data } = await axios.delete(
    `/api/products/${productId}/options/${optionId}`
  );

  return data;
}
