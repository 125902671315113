import { DateInput3 } from "@blueprintjs/datetime2";
import dayjs from "dayjs";
import React, { useContext, useEffect } from "react";
import { Label } from "semantic-ui-react";
import { OrderRow } from ".";
import { OrderContext } from "../../../contexts/orders";
import { formatToUppercase } from "../../../resources/helpers";
import { useRequest } from "../../../resources/hooks";
import { fetchFinishedOrders } from "../../../services/orders";
import { Row, WithLoader } from "../../common";

const FinishedTab = () => {
  const {
    state: { finishedOrders },
    dispatch,
  } = useContext(OrderContext);

  const getFinished = useRequest({
    service: fetchFinishedOrders,
    defaultParams: {
      deliveryDate: dayjs().startOf("day").add(12, "hour").toDate(),
    },
  });

  useEffect(() => {
    if (getFinished.response)
      dispatch({
        type: "SET_FINISHED_ORDERS",
        finishedOrders: getFinished.response,
      });
  }, [getFinished.response]);

  const handleDateChange = (deliveryDate) => {
    getFinished.request({ params: { deliveryDate } });
  };

  return (
    <>
      <Row
        align="center"
        style={{ marginBottom: "2rem", marginLeft: "0.5rem" }}
        childrenMargin="tiny"
      >
        <span className="circular-count">{finishedOrders.length}</span>
        <h3 style={{ whiteSpace: "nowrap" }}>{`commande${
          finishedOrders.length > 1 ? "s" : ""
        } le `}</h3>
        <DateInput3
          formatDate={(date) =>
            formatToUppercase(dayjs(date).locale("fr").format("dddd DD MMMM"))
          }
          parseDate={(str) => new Date(str)}
          fill
          todayButtonText="Aujourd'hui"
          highlightCurrentDay
          placeholder="Aucune"
          showActionsBar
          defaultValue={new Date()}
          onChange={(selectedStringDate) => {
            if (selectedStringDate) {
              handleDateChange(
                dayjs(selectedStringDate)
                  .startOf("day")
                  .add(12, "hour")
                  .toDate()
              );
            } else {
              handleDateChange(null);
            }
          }}
        />
      </Row>

      <WithLoader loading={getFinished.loading}>
        <div className="order-rows">
          {finishedOrders.map((order) => (
            <OrderRow
              key={order.id}
              order={order}
              lastElement={
                <Label
                  color={
                    order.orderDelivery.status === "DELIVERED" ? "green" : "red"
                  }
                  className="status"
                  basic
                >
                  {order.orderDelivery.status === "DELIVERED"
                    ? "Livrée"
                    : "Annulée"}
                </Label>
              }
            />
          ))}
        </div>
      </WithLoader>
    </>
  );
};

export default FinishedTab;
