import React, { useEffect, useState } from "react";
import { Button, Form, Header, Message, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { WithLoader, Column } from "../common";
import {
  fetchRestaurantAccount,
  registerAccount,
} from "../../services/restaurants";
import { useInput, useRequest } from "../../resources/hooks";
import "../../styles/Restaurants.scss";

const RestaurantUpdate = ({ routes, location, history, match }) => {
  const { restaurantId } = match.params;

  const username = useInput("");
  const [hasAccount, setHasAccount] = useState(false);
  const [restaurantLabel, setRestaurantLabel] = useState("");
  const [responseModalOpen, setResponseModalOpen] = useState(false);

  const getRestaurant = useRequest({
    service: () => fetchRestaurantAccount({ restaurantId }),
  });
  const createRestaurantAccount = useRequest({
    service: () => registerAccount({ restaurantId, username: username.value }),
    requestOnInit: false,
  });

  const handleCreation = async () => {
    let hasErrors = false;

    if (!username.value) {
      hasErrors = true;
      username.setError("Obligatoire");
    } else if (username.value.length < 5) {
      hasErrors = true;
      username.setError("Trop court (minimum: 5)");
    } else if (username.value.length > 20) {
      hasErrors = true;
      username.setError("Trop long (maximum: 10)");
    } else if (!/^[a-z\-0-9]{5,20}$/gm.test(username.value)) {
      hasErrors = true;
      username.setError(
        "Caractères interdits (minuscules, chiffres et '-' uniquement"
      );
    } else if (username.value.includes(" ")) {
      hasErrors = true;
      username.setError("Caractères interdits");
    }

    if (hasErrors) return;

    createRestaurantAccount.request();
  };

  useEffect(() => {
    if (getRestaurant.response) {
      setRestaurantLabel(getRestaurant.response.username);
      if (getRestaurant.response.username) {
        username.setValue(getRestaurant.response.username);
        setHasAccount(true);
      }
    }
  }, [getRestaurant.response]);

  useEffect(() => {
    if (createRestaurantAccount.response?.password) {
      setResponseModalOpen(true);
      setHasAccount(true);
    }
  }, [createRestaurantAccount.response]);

  return (
    <WithLoader loading={getRestaurant.loading} text="Chargement du restaurant">
      <div
        className="restaurant-account data-container"
        style={{ width: "40%" }}
      >
        <Column>
          <h2>{`Compte restaurant "${restaurantLabel}"`}</h2>
          <Form>
            <Form.Group className="form-row">
              <Form.Field>
                <Form.Input label="Utilisateur" {...username.props} />
              </Form.Field>
            </Form.Group>

            <Button
              onClick={handleCreation}
              loading={getRestaurant.loading || createRestaurantAccount.loading}
              disabled={
                getRestaurant.loading || createRestaurantAccount.loading
              }
              color="green"
              negative={hasAccount}
            >
              {hasAccount ? "Regénérer un mot de passe" : "Créer un compte"}
            </Button>
          </Form>

          <Message warning>
            <Message.Header>⚠️ Attention</Message.Header>
            {hasAccount ? (
              <p>
                Regénérer un mot de passe écrasera l'ancien ! <br />
                Le restaurant ne sera plus en mesure de se connecter avec
              </p>
            ) : (
              <p>
                Le mot de passe que vous allez générer ne s'affichera qu'une
                seule fois
                <br />
                Veuillez bien le noter, il ne pourra pas être ré-affiché
              </p>
            )}
          </Message>
        </Column>
      </div>

      <Modal basic open={responseModalOpen} size="small">
        <Header>Voici le mot de passe :</Header>
        <Modal.Content>
          <p></p>
          <p className="password-reveal">
            {createRestaurantAccount.response?.password}
          </p>
          <p>
            ⚠️ Attention à bien le noter, il ne sera affiché{" "}
            <b>qu'une seule fois</b>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setResponseModalOpen(false)}
          >
            J'ai compris et je l'ai bien noté
          </Button>
        </Modal.Actions>
      </Modal>
    </WithLoader>
  );
};

export default withRouter(RestaurantUpdate);
