import React, { useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Compressor from "compressorjs";
import { useRequest, useInput } from "../../resources/hooks";
import { createProduct } from "../../services/products";
import { getCategoryId, getRestaurantId } from "../../resources/helpers";
import { Column, ImageUpload, Row } from "../common";

const ProductCreate = ({ closeModal, match, location }) => {
  const restaurantId = getRestaurantId(location);
  const categoryId = getCategoryId(location);

  const create = useRequest({
    service: createProduct,
    requestOnInit: false,
  });
  // TODO: log error

  const label = useInput("");
  const description = useInput("");
  const price = useInput("");
  const originalPrice = useInput("");
  const [image, setImage] = useState(null);

  const inputs = [label, price, originalPrice];
  const [imageError, setImageError] = useState(null);

  const handleCreate = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    // if (!image) {
    //   setImageError({
    //     content: "Obligatoire",
    //   });
    //   hasErrors = true;
    // }

    if (hasErrors) return;

    const result = await create.request({
      params: {
        categoryId,
        label: label.value,
        description: description.value,
        price: price.value * 100,
        originalPrice: originalPrice.value * 100,
        image,
      },
    });

    if (result.response) handleClose();
    // TODO log error
  };

  const handleImageUpload = (image) => {
    setImageError(null);
    new Compressor(image, {
      quality: 0.8,
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: Infinity, // disable .png to .jpg conversion to keep image transparency
      success(result) {
        setImage(result);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const handleClose = () => {
    closeModal({
      withRedirect: `/restaurants/${restaurantId}/categories/${categoryId}/products`,
    });
  };

  return (
    <Modal onClose={handleClose} open={true} size="small">
      <Modal.Header>Créer une produit</Modal.Header>

      <Modal.Content>
        <Row childrenMargin="small">
          <Column style={{ flexBasis: "45%" }}>
            <ImageUpload
              image={image}
              handleImageUpload={handleImageUpload}
              error={!!imageError}
            />
          </Column>

          <Column style={{ flexBasis: "55%" }}>
            <Form>
              <Form.Group className="form-row">
                <Form.Field width={13}>
                  <Form.Input label="Nom du produit" {...label.props} />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row">
                <Form.Field width={16}>
                  <Form.TextArea label="Description" {...description.props} />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row" widths={6}>
                <Form.Field width={6}>
                  <Form.Input
                    label="Prix de vente"
                    {...price.props}
                    type="number"
                    min={0}
                  />
                </Form.Field>

                <Form.Field width={6}>
                  <Form.Input
                    label="Prix original"
                    {...originalPrice.props}
                    type="number"
                    min={0}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Column>
        </Row>
      </Modal.Content>

      <Modal.Actions>
        <Button color="black" loading={create.loading} onClick={handleClose}>
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          onClick={handleCreate}
          positive
          loading={create.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default withRouter(ProductCreate);
