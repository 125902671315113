import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchProducts({ categoryId }) {
  const { data } = await axios.get(
    `/api/productCategories/${categoryId}/products`
  );

  return data;
}

export async function fetchProductsByRestaurant({ restaurantId }) {
  const { data } = await axios.get(`/api/restaurants/${restaurantId}/products`);

  return data;
}

export async function fetchProduct({ productId }) {
  const { data } = await axios.get(`/api/products/${productId}`);

  return data;
}

export async function searchProducts({ label }) {
  const { data } = await axios.get("/api/products/search", {
    params: { label },
  });

  return data;
}

export async function createProduct({
  categoryId,
  label,
  description,
  price,
  originalPrice,
  image,
  options,
}) {
  const formData = new FormData();

  formData.append("label", label);
  formData.append("description", description);
  formData.append("price", price);
  formData.append("originalPrice", originalPrice);
  formData.append("product-picture", image);
  if (!!options) formData.append("options", JSON.stringify(options));

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const { data } = await axios.post(
    `/api/productCategories/${categoryId}/products`,
    formData,
    config
  );

  return data;
}

export async function updateProduct({
  productId,
  label,
  description,
  price,
  originalPrice,
  image,
  available,
  options,
  removeImage,
}) {
  const formData = new FormData();

  if (!!label) formData.append("label", label);
  if (!!description) formData.append("description", description);
  if (!!price) formData.append("price", price);
  if (!!originalPrice) formData.append("originalPrice", originalPrice);
  if (!!image) formData.append("product-picture", image);
  if (!!removeImage) formData.append("removeImage", removeImage);
  if (available !== undefined) formData.append("available", available);
  if (!!options) formData.append("options", JSON.stringify(options));

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const { data } = await axios.put(
    `/api/products/${productId}`,
    formData,
    config
  );

  return data;
}

export async function deleteProduct({ productId }) {
  const { data } = await axios.delete(`/api/products/${productId}`);

  return data;
}
