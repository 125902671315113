import { useState } from "react";
import Row from "./Row";
import Column from "./Column";
import HourRange from "./HourRange";
import { Icon, Button } from "semantic-ui-react";
import "../../styles/common.scss";

const weekdays = [
  { code: "MONDAY", label: "Lundi" },
  { code: "TUESDAY", label: "Mardi" },
  { code: "WEDNESDAY", label: "Mercredi" },
  { code: "THURSDAY", label: "Jeudi" },
  { code: "FRIDAY", label: "Vendredi" },
  { code: "SATURDAY", label: "Samedi" },
  { code: "SUNDAY", label: "Dimanche" },
];

const WeekOpeningHours = ({ openingHours, handleUpdate }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [creatingPeriod, setCreatingPeriod] = useState(null);

  const updateOpeningPeriod = (start, end) => {
    if (creatingPeriod) {
      const copy = [
        ...openingHours,
        { weekday: creatingPeriod, startHour: start, endHour: end },
      ];
      handleUpdate(copy);

      setCreatingPeriod(null);
    } else {
      let cursor = 0;
      const updatedPeriods = openingHours.map((period) => {
        if (period.weekday === selectedPeriod.weekday) {
          if (cursor === selectedPeriod.index) {
            return { ...period, startHour: start, endHour: end };
          } else {
            cursor++;
          }
        }
        return period;
      });
      handleUpdate(updatedPeriods);
      setSelectedPeriod(null);
    }
  };

  const remove = (weekday, index) => {
    let cursor = 0;
    const updatedPeriods = openingHours.reduce((acc, period) => {
      if (period.weekday === weekday) {
        if (cursor === index) {
          return acc;
        } else {
          cursor++;
        }
      }
      return [...acc, period];
    }, []);
    handleUpdate(updatedPeriods);
  };

  let formattedHours = {};
  for (const weekday of weekdays) {
    formattedHours[weekday.code] = openingHours
      .filter((openingHour) => openingHour.weekday === weekday.code)
      .map((openingHour) => ({
        startHour: openingHour.startHour,
        endHour: openingHour.endHour,
      }));
  }

  return (
    <Column className="week-opening-hours">
      {weekdays.map((weekday) => {
        const periods = openingHours.filter(
          (openingHour) => openingHour.weekday === weekday.code
        );
        return (
          <Row className="day-row" key={`${weekday.code}`} align="center">
            <span className="day-title">{weekday.label}</span>

            {periods.length > 0 &&
              periods.map((period, index) =>
                selectedPeriod?.weekday === weekday.code &&
                selectedPeriod?.index === index ? (
                  <HourRange
                    initialStart={period.startHour}
                    initialEnd={period.endHour}
                    handleSubmit={updateOpeningPeriod}
                  />
                ) : (
                  <div
                    key={`${period.code}-${index}`}
                    className="hour-range"
                    onClick={() => {
                      setCreatingPeriod(null);
                      setSelectedPeriod({ weekday: weekday.code, index });
                    }}
                  >
                    <span className="entry start">{period.startHour}</span>
                    <span className="entry end">{period.endHour}</span>
                    <Icon
                      name="close"
                      className="remove-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        remove(weekday.code, index);
                      }}
                    />
                  </div>
                )
              )}

            {creatingPeriod === weekday.code ? (
              <HourRange handleSubmit={updateOpeningPeriod} />
            ) : (
              <Button
                className="add-hour-range"
                icon="plus"
                color="green"
                compact
                circular
                size="small"
                onClick={() => {
                  setSelectedPeriod(null);
                  setCreatingPeriod(weekday.code);
                }}
              />
            )}
          </Row>
        );
      })}
    </Column>
  );
};

export default WeekOpeningHours;
