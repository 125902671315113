import { Loader } from "semantic-ui-react";

const WithLoader = ({
  loading,
  text,
  margin,
  modalMode,
  inverted,
  centered = false,
  children,
}) => {
  return loading ? (
    <Loader
      active={loading}
      inverted={inverted}
      size="large"
      inline="centered"
      style={{
        marginTop: margin ?? "2rem",
        width: centered ? "100%" : undefined,
      }}
      className={modalMode ? "workaround-modal-loader" : ""}
    >
      {text}
    </Loader>
  ) : (
    children
  );
};

export default WithLoader;
