import React, { useState, useEffect, useContext } from "react";
import { Switch, useLocation, useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import dayjs from "dayjs";
import { AuthContext } from "../contexts/auth";
import { OrderContext } from "../contexts/orders";
import { logout } from "../services/auth";
import "../styles/AdminInterface.scss";
import brandIcon from "../resources/png/logo_funky_blanc.png";
import { ReactComponent as BellIcon } from "../resources/svg/bell.svg";
import { ReactComponent as PinpointIcon } from "../resources/svg/pinpoint.svg";
import { ReactComponent as ShopIcon } from "../resources/svg/shop.svg";
import { ReactComponent as DiscountIcon } from "../resources/svg/discount.svg";
import { ReactComponent as LensIcon } from "../resources/svg/lens.svg";
import { ReactComponent as UserIcon } from "../resources/svg/user.svg";
import { OrdersView } from "./orders";
import {
  AnalysisView,
  DeliveriesAnalysis,
  RestaurantsProfitAnalysis,
} from "./deliveries";
import { InvoiceExports } from "./analysis";
import {
  RestaurantsView,
  RestaurantsTable,
  RestaurantsCreation,
  RestaurantsUpdate,
  RestaurantsAccount,
} from "./restaurants";
import { ProductsTable, ProductShow } from "./products";
import { ProductCategoriesTable } from "./productCategories";
import { DeliveryAreasList } from "./deliveryAreas";
import { DiscountCodesList } from "./discountCodes";
import { RouteWithSubRoutes } from "../resources/helpers";
import {
  socket,
  subscribeToOrders,
  initiateSocket,
  disconnectSocket,
  subscribeToOrderCreation,
  handleReconnection,
  handleDisconnection,
} from "../services/socket";
import NotificationSound from "../resources/sounds/notification-sound.mp3";
import { toast } from "react-toastify";
import { Ping } from "./common";
import { UserOrders, UsersTable, UsersView } from "./users";

const orderCreationSound = new Audio(NotificationSound);

const routes = [
  {
    path: "/orders",
    component: OrdersView,
    icon: <BellIcon />,
    name: "Commandes",
  },
  {
    path: "/restaurants",
    component: RestaurantsView,
    icon: <ShopIcon />,
    name: "Restaurants",
    routes: [
      {
        path: "/restaurants",
        strict: true,
        exact: true,
        component: RestaurantsTable,
        name: "Restaurants",
      },
      {
        path: "/restaurants/create",
        component: RestaurantsCreation,
        name: "Création",
        exact: true,
      },
      {
        path: "/restaurants/:restaurantId",
        component: RestaurantsUpdate,
        name: "Mise à jour",
        exact: true,
      },

      {
        path: "/restaurants/:restaurantId/categories",
        component: ProductCategoriesTable,
        name: "Catégories",
        exact: true,
      },
      {
        path: "/restaurants/:restaurantId/profile",
        component: RestaurantsAccount,
        name: "Profil restaurant",
        exact: true,
      },
      {
        path: "/restaurants/:restaurantId/categories/create",
        component: () => <ProductCategoriesTable create />,
        name: "Création",
        exact: true,
      },
      {
        path: "/restaurants/:restaurantId/categories/:categoryId/products",
        component: ProductsTable,
        name: "Produits",
        exact: true,
      },
      {
        path: "/restaurants/:restaurantId/categories/:categoryId/products/create",
        component: () => <ProductsTable create />,
        name: "Création",
        exact: true,
      },
      {
        path: "/restaurants/:restaurantId/categories/:categoryId/products/:productId/show",
        component: ProductShow,
        name: "Produits",
        exact: true,
      },
    ],
  },
  {
    path: "/deliveryAreas",
    component: DeliveryAreasList,
    icon: <PinpointIcon />,
    name: "Zones livraison",
  },
  {
    path: "/discountCodes",
    component: DiscountCodesList,
    icon: <DiscountIcon />,
    name: "Codes de réduction",
  },
  {
    path: "/analysis",
    component: AnalysisView,
    icon: <LensIcon />,
    name: "Analyses",
    routes: [
      {
        path: "/analysis/deliverymen",
        strict: true,
        exact: true,
        component: DeliveriesAnalysis,
        name: "Recettes livreur",
      },
      {
        path: "/analysis/restaurants",
        strict: true,
        exact: true,
        component: RestaurantsProfitAnalysis,
        name: "Profits restaurants",
      },
      {
        path: "/analysis/restaurants/invoices",
        strict: true,
        exact: true,
        component: InvoiceExports,
        name: "Exports restaurants",
      },
    ],
  },
  {
    path: "/users",
    component: UsersView,
    icon: <UserIcon />,
    name: "Utilisateurs",
    routes: [
      {
        path: "/users",
        component: () => <UsersTable />,
        strict: true,
        exact: true,
        name: "Dernières commandes",
      },
      {
        path: "/users/:userId/orders",
        component: () => <UserOrders />,
        strict: true,
        exact: true,
        name: "Dernières commandes",
      },
    ],
  },
];

const AdminInterface = () => {
  const {
    state: { currentUser },
  } = useContext(AuthContext);
  const {
    state: { ordersLoading }, initiateOrders,
    dispatch: orderDispatch,
  } = useContext(OrderContext);

  useEffect(() => {
    initiateSocket(currentUser.id, (err, { admin }) => {
      if (!admin) console.warn("Error while joining room");
    });

    subscribeToOrders((err, { order, delivered }) => {
      if (err) return;
      if (delivered) orderDispatch({ type: "REMOVE_ORDER", order });
      else orderDispatch({ type: "UPDATE_ORDER", order });
    });

    handleDisconnection((reason) => {
      console.warn(`Disconnection from socket for reason [${reason}]`);
      toast.warn(
        <div>
          <h4>Perte de connexion au serveur</h4>
          <span>
            Reconnection en cours...
          </span>
        </div>,
        { autoClose: false, toastId: "connectionToastId" }
      )
    });

    handleReconnection(
      { userId: currentUser.id },
      (reconnectionAttemptCount) => {
        console.warn("reconnected after", reconnectionAttemptCount, "attempts");
        
        initiateOrders();

        toast.update("connectionToastId", {
          type: toast.TYPE.SUCCESS,
          render:
            <div>
              <h4>Reconnecté !</h4>
              <p>Les commandes sont à jour</p>
            </div>,
          autoClose: 5000
        })
      }
    );

    subscribeToOrderCreation((err, { order }) => {
      if (err) return;

      const toastId = toast(
        <span className="toast-order-creation">
          <Ping />
          <span className="order-number">{order.number}</span> Nouvelle commande
          !
        </span>,
        {
          autoClose: false,
          onClick: () => orderDispatch({ type: "SET_SELECTED_ORDER", order }),
          className: "order-received-toast",
          closeOnClick: true,
        }
      );

      orderDispatch({ type: "CREATE_ORDER", order, toastId });
      orderCreationSound.play().catch(console.log);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  return (
    <div id="admin-interface">
      <NavigationBar routes={routes} />

      <div className="content">
        {/* // TODO: loading orders */}

        {ordersLoading ? (
          "Chargement des commandes"
        ) : (
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        )}
      </div>
      {/* <div onClick={play} /> */}
    </div>
  );
};

const NavigationBar = ({ routes, user }) => {
  const {
    state: { currentUser },
    dispatch: authDispatch,
  } = useContext(AuthContext);
  const { pathname } = useLocation();
  const [time, setTime] = useState(dayjs().format("HH:mm"));
  const history = useHistory();

  const disconnect = async () => {
    try {
      await logout();
      authDispatch({ type: "SET_USER", user: null });
      disconnectSocket();
    } catch (err) { }
  };

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTime(dayjs().format("HH:mm"));
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  return (
    <nav>
      <img src={brandIcon} alt="restos-bussy-icon" />
      <div className="navigation">
        {routes.map(({ icon, name, path }) => (
          // .filter((page) => isAdmin(user) || !page.restricted)
          <div
            className={`nav-category${pathname.includes(path) ? " active" : ""
              }`}
            onClick={() => {
              history.push(path);
            }}
            key={path}
          >
            <div className="nav-icon-container">
              <div className="select-indicator" />
              {icon}
              {/* {page.route === "/admin/messages" && unreadCount > 0 && (
                    <Label
                      color="red"
                      className="unread-messages-badge"
                      floated="left"
                      floating
                      circular
                      size="tiny"
                    >
                      {unreadCount}
                    </Label>
                  )} */}
            </div>
            <span className="nav-label">{name}</span>
          </div>
        ))}
      </div>

      <div className="footer">
        <div className="nav-category">
          <div className="nav-icon-container">
            {!socket ? (
              <Ping color="#f5bd1f" speed="slow" />
            ) : socket.connected ? (
              <Ping color="#67bc75" speed="slow" />
            ) : (
              <Ping color="#bc6767" speed="slow" />
            )}
            <time className="current-time">{time}</time>
            <span className="user-name">{currentUser.firstname}</span>
            <span>Admin</span>
          </div>
          <div className="user-information" onClick={disconnect}>
            <Button color="red" size="small" style={{ whiteSpace: "nowrap" }}>
              Se déconnecter
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminInterface;
