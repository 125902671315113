import { Fragment, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { CircularButton, Column, Divider, Price, Row } from "components/common";
import * as PriceHelper from "resources/helpers/PriceHelper";

const CartSummary = ({
  orderProducts,
  minimumRequired = 0,
  deliveryFee = 0,
  discount = 0,
  editable,
  handleQuantityUpdate,
  children,
  inverted,
  interactive,
  CloseButton,
  Header,
  Footer,
}) => {
  let productsPrice = 0;

  const productsByRestaurant = useMemo(
    () =>
      orderProducts?.reduce((acc, product) => {
        if (acc[product.originalRestaurant.label]?.length > 0) {
          acc[product.originalRestaurant.label].push({ ...product });
        } else {
          acc[product.originalRestaurant.label] = [{ ...product }];
        }
        return acc;
      }, {}) ?? [],
    [orderProducts]
  );

  Object.keys(productsByRestaurant).forEach((restaurant) => {
    productsByRestaurant[restaurant].forEach((product) => {
      const { total } = PriceHelper.getproductBreakdown(product);
      productsPrice += total;
    });
  });

  return (
    <div className={`cart-summary${inverted ? " inverted" : ""}`}>
      {Header}
      <div className="restaurant-products">
        {Object.keys(productsByRestaurant).map((restaurant, index) => {
          return (
            <RestaurantProducts
              key={restaurant}
              productsByRestaurant={productsByRestaurant}
              restaurant={restaurant}
              editable={editable}
              interactive={interactive}
              handleQuantityUpdate={handleQuantityUpdate}
            />
          );
        })}
        {CloseButton}
      </div>

      <Divider noMargin />

      <div className="cart-prices">
        <Row
          className="price-row sub-total-price"
          align="center"
          justify="space-between"
        >
          <div className="title">
            Sous-total{" "}
            {minimumRequired > 0 && (
              <>
                <span style={{ fontSize: "0.8em", opacity: 0.5 }}>
                  (
                  <Price fontSize={0.9}>
                    {PriceHelper.renderCentimesAsAmount(minimumRequired)}
                  </Price>{" "}
                  minimum)
                </span>
              </>
            )}
          </div>
          <div className="price">
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(productsPrice)}
            </Price>
          </div>
        </Row>
        <Row
          className="price-row delivery-price"
          align="center"
          justify="space-between"
        >
          <span className="title">Frais de service</span>
          {deliveryFee === 0 ? (
            <span className="price">Sans frais</span>
          ) : (
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(deliveryFee)}
            </Price>
          )}
        </Row>
        {discount > 0 && (
          <Row
            className="price-row delivery-price"
            align="center"
            justify="space-between"
          >
            <span className="title">Réduction</span>
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(-discount)}
            </Price>
          </Row>
        )}
      </div>

      <Divider noMargin />

      <div className="cart-prices">
        <Row
          className="price-row total-price"
          align="center"
          justify="space-between"
        >
          <span className="title">Total</span>
          <Price fontSize="1.3">
            {PriceHelper.renderCentimesAsAmount(
              deliveryFee + productsPrice - discount
            )}
          </Price>
        </Row>
      </div>

      {Footer}

      {children}
    </div>
  );
};

const RestaurantProducts = ({
  productsByRestaurant,
  restaurant,
  editable,
  interactive,
  handleQuantityUpdate,
}) => {
  return (
    <Column className="restaurant">
      <Row className="title" align="baseline">
        <span>préparé par</span>
        <div className="name">{restaurant}</div>
      </Row>

      {/* TODO: link to product */}
      {productsByRestaurant[restaurant].map((product) => {
        return (
          <ProductRow
            key={product.id}
            product={product}
            editable={editable}
            interactive={interactive}
            handleQuantityUpdate={handleQuantityUpdate}
          />
        );
      })}
    </Column>
  );
};

const ProductRow = ({
  product,
  editable,
  interactive,
  handleQuantityUpdate,
}) => {
  const { unitaryTotal } = useMemo(
    () => PriceHelper.getproductBreakdown(product),
    [product]
  );

  return (
    <Column className={`product${interactive ? " interactive" : ""}`}>
      <Row align="center" childrenMargin="tiny">
        <div className="count">
          <span>{product.quantity}</span>
          <span style={{ fontSize: "0.7rem", marginLeft: "2px" }}>x</span>
        </div>

        <div className="name">{product.label}</div>

        <Row className="price-wrapper" align="center">
          <div className={`item-price${editable ? " hoverable" : ""}`}>
            <Price fontSize="1.3">
              {PriceHelper.renderCentimesAsAmount(unitaryTotal)}
            </Price>
          </div>
          {editable && (
            <Row className="item-price-above">
              <div className="plus-button">
                <CircularButton
                  onClick={() => handleQuantityUpdate(1, product)}
                >
                  <Icon name="plus" fitted />
                </CircularButton>
              </div>

              <div className="minus-button">
                <CircularButton
                  inverted
                  onClick={() => handleQuantityUpdate(-1, product)}
                >
                  <Icon name="minus" fitted />
                </CircularButton>
              </div>
            </Row>
          )}
        </Row>
      </Row>

      <ProductSpecifics
        options={product.orderProductOptions}
        comments={product.comments}
      />
    </Column>
  );
};

const ProductSpecifics = ({ options, comments }) => {
  if (options?.length === 0 && !comments) return null;

  return (
    <Column className="item-additionnal-information">
      {options && (
        <Column className="item-options">
          {options.map((option) => (
            <span key={option.id}>
              {option.option}:{" "}
              <span className="option-choices">{option.choice}</span>
            </span>
          ))}
        </Column>
      )}

      {comments && <span className="comments">{`"${comments}"`}</span>}
    </Column>
  );
};

export default CartSummary;
