import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchProductCategories(restaurantId) {
  const { data } = await axios.get(
    `/api/restaurants/${restaurantId}/productCategories`
  );

  return data;
}

export async function fetchProductCategory(categoryId) {
  const { data } = await axios.get(`/api/productCategories/${categoryId}`);

  return data;
}

export async function createProductCategory({ restaurantId, label }) {
  const { data } = await axios.post(
    `/api/restaurants/${restaurantId}/productCategories`,
    {
      label,
    }
  );

  return data;
}

export async function updateProductCategory({ categoryId, label }) {
  const { data } = await axios.put(`/api/productCategories/${categoryId}`, {
    label,
  });

  return data;
}

export async function reorderProductCategories({ productCategories }) {
  const { data } = await axios.put(`/api/productCategories/reorder`, {
    productCategories,
  });

  return data;
}

export async function deleteProductCategory(categoryId) {
  const { data } = await axios.delete(`/api/productCategories/${categoryId}`);

  return data;
}
