import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Compressor from "compressorjs";
import { useRequest, useInput, useToggle } from "../../resources/hooks";
import { fetchProduct, updateProduct } from "../../services/products";
import { getCategoryId, getRestaurantId } from "../../resources/helpers";
import { Column, ImageUpload, Row, WithLoader } from "../common";
import { centimesToAmount } from "../../resources/helpers/PriceHelper";

const ProductUpdate = ({ productId, closeModal, match, location }) => {
  const restaurantId = getRestaurantId(location);
  const categoryId = getCategoryId(location);

  const get = useRequest({
    service: () => fetchProduct({ productId }),
  });
  const update = useRequest({
    service: updateProduct,
    requestOnInit: false,
  });
  // TODO: log error

  const label = useInput("");
  const description = useInput("");
  const price = useInput("");
  const originalPrice = useInput("");
  const available = useToggle(false);
  const [image, setImage] = useState(null);

  const inputs = [label, price, originalPrice];
  const [imageError, setImageError] = useState(null);

  useEffect(() => {
    label.setValue(get.response?.label);
    description.setValue(get.response?.description);
    price.setValue(centimesToAmount(get.response?.price));
    originalPrice.setValue(centimesToAmount(get.response?.originalPrice));
    available.setValue(get.response?.available);
    setImage(get.response?.publicUrl);
  }, [get.response]);

  const handleUpdate = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    // if (!image) {
    //   setImageError({
    //     content: "Obligatoire",
    //   });
    //   hasErrors = true;
    // }

    if (hasErrors) return;

    const result = await update.request({
      params: {
        productId,
        label: label.value,
        description: description.value,
        price: price.value * 100,
        originalPrice: originalPrice.value * 100,
        available: available.value,
        image,
      },
    });

    if (result.response) handleClose();
    // TODO log error
  };

  const handleImageUpload = (image) => {
    setImageError(null);
    new Compressor(image, {
      quality: 0.8,
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: Infinity, // disable .png to .jpg conversion to keep image transparency
      success(result) {
        setImage(result);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const handleClose = () => {
    closeModal({
      withReload: true,
    });
  };

  return (
    <Modal onClose={handleClose} open={true}>
      <Modal.Header>Modifier un produit</Modal.Header>

      <Modal.Content className="product-update">
        <WithLoader loading={get.loading}>
          <Row childrenMargin="small">
            <Column style={{ flexBasis: "45%" }}>
              <ImageUpload
                image={image}
                handleImageUpload={handleImageUpload}
                error={!!imageError}
              />
            </Column>

            <Column style={{ flexBasis: "55%" }}>
              <Form>
                <Form.Group className="form-row">
                  <Form.Field width={13}>
                    <Form.Input label="Nom du produit" {...label.props} />
                  </Form.Field>

                  <Form.Field>
                    <label>Disponible ?</label>
                    <Form.Checkbox toggle {...available.props} />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row">
                  <Form.Field width={16}>
                    <Form.TextArea label="Description" {...description.props} />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={6}>
                    <Form.Input
                      label="Prix de vente"
                      {...price.props}
                      type="number"
                      min={0}
                    />
                  </Form.Field>

                  <Form.Field width={6}>
                    <Form.Input
                      label="Prix original"
                      {...originalPrice.props}
                      type="number"
                      min={0}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Column>
          </Row>
        </WithLoader>
      </Modal.Content>

      <Modal.Actions>
        <Button color="black" loading={update.loading} onClick={handleClose}>
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          onClick={handleUpdate}
          positive
          loading={update.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default withRouter(ProductUpdate);
