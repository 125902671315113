import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Radio,
  Header,
  Input,
  Checkbox,
  Message,
  Search,
  Popup,
} from "semantic-ui-react";
import { useRequest } from "../../resources/hooks";
import { Column, Row, VerticalDivider } from "../common";
import {
  assignOption,
  fetchOption,
  removeOption,
  searchOptions,
  updateOption,
} from "../../services/options";
import "../../styles/common.scss";
import WithLoader from "./WithLoader";
import {
  centimesToAmount,
  amountToCentimes,
} from "../../resources/helpers/PriceHelper";

const DEFAULT_CHOICE = {
  label: "",
  price: null,
  originalPrice: null,
  available: true,
  labelError: null,
};

const OptionsSearchModal = ({ productId, closeModal }) => {
  // Option search part
  const [searchLabel, setSearchLabel] = useState("");

  const search = useRequest({
    service: searchOptions,
    requestOnInit: false,
  });
  const remove = useRequest({
    service: removeOption,
    requestOnInit: false,
  });
  const get = useRequest({
    service: fetchOption,
    requestOnInit: false,
  });
  const assignToProduct = useRequest({
    service: assignOption,
    requestOnInit: false,
  });
  const update = useRequest({
    service: updateOption,
    requestOnInit: false,
  });

  // Wait for 500ms keyboard pause to execute (disable API GET smashing)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchLabel && searchLabel.length >= 3)
        search.request({ params: { label: searchLabel } });
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchLabel]);

  const handleOptionDelete = async (optionId) => {
    await remove.request({
      params: {
        optionId,
      },
    });

    search.request({ params: { label: searchLabel } });
  };

  const onOptionSelect = async (result) => {
    const { response } = await get.request({
      params: { optionId: result.id },
    });

    if (response) {
      setOption(response);
    }
  };

  const setOption = (option) => {
    setOptionId(option.id);
    setLabel(option.label);
    setType(option.type);
    setRequired(option.required);
    setMinimum(option.minimum);
    setMaximum(option.maximum);
    setChoices(
      option.choices.map((choice) => ({
        ...choice,
        price: centimesToAmount(choice.price),
        originalPrice: centimesToAmount(choice.originalPrice),
      }))
    );
  };

  const formatOptions = (rawOption) => {
    return (
      <Row align="center" justify="space-between">
        {rawOption.label}
        <Button
          onClick={(e) => {
            handleOptionDelete(rawOption.id);
            e.preventDefault();
            e.stopPropagation();
          }}
          icon="trash"
          color="red"
        />
      </Row>
    );
  };

  // Option update part
  const [optionId, setOptionId] = useState(null);
  const [label, setLabel] = useState("");
  const [type, setType] = useState("SINGLE");
  const [required, setRequired] = useState(false);
  const [minimum, setMinimum] = useState(null);
  const [maximum, setMaximum] = useState(null);
  const [choices, setChoices] = useState([]);

  const [labelError, setLabelError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const areChoicesValid = () => {
    let hasErrors = false;
    let message = "";

    if (choices.length === 0) {
      return { hasErrors: true, message: "Au minimum un choix est requis" };
    }

    setChoices(
      choices.map((choice) => {
        if (!choice.label) {
          hasErrors = true;
          message = "Certains choix sont vides";
          return { ...choice, labelError: { content: "Obligatoire" } };
        } else {
          return choice;
        }
      })
    );

    return { hasErrors, message };
  };

  const handleOptionUpdate = async () => {
    let hasErrors = false;
    if (!label) {
      setLabelError({
        content: "Obligatoire",
      });
      hasErrors = true;
    }

    const { hasErrors: choicesError, message } = areChoicesValid();
    setErrorMessage(message);

    if (maximum > choices.length) {
      setErrorMessage("Le maximum ne doit pas dépasser le nombre de choix");
      hasErrors = true;
    }

    if (choicesError) hasErrors = true;

    if (hasErrors) return;

    const cleanedChoices = choices.map((choice) => ({
      label: choice.label,
      price: choice.price ? amountToCentimes(Number(choice.price)) : 0,
      originalPrice: choice.originalPrice
        ? amountToCentimes(Number(choice.originalPrice))
        : 0,
      available: choice.available,
    }));

    await update.request({
      params: {
        optionId,
        label,
        type,
        required,
        minimum,
        maximum,
        choices: cleanedChoices,
      },
    });
    // TODO: toast "update success"
    search.request({ params: { label: searchLabel } });
  };

  const handleOptionAssign = async () => {
    let hasErrors = false;
    if (!label) {
      setLabelError({
        content: "Obligatoire",
      });
      hasErrors = true;
    }

    const { hasErrors: choicesError, message } = areChoicesValid();
    setErrorMessage(message);

    if (maximum > choices.length) {
      setErrorMessage("Le maximum ne doit pas dépasser le nombre de choix");
      hasErrors = true;
    }

    if (choicesError) hasErrors = true;

    if (hasErrors) return;

    const cleanedChoices = choices.map((choice) => ({
      label: choice.label,
      price: choice.price ? amountToCentimes(Number(choice.price)) : 0,
      originalPrice: choice.originalPrice
        ? amountToCentimes(Number(choice.originalPrice))
        : 0,
      available: choice.available,
    }));

    const result = await assignToProduct.request({
      params: {
        productId,
        label,
        type,
        required,
        minimum,
        maximum,
        choices: cleanedChoices,
      },
    });

    if (result.response) {
      closeModal({ withReload: true });
    }
  };

  const handleChoiceUpdate = (value, type, index) => {
    let toUpdate = [...choices];
    if (type === "label") toUpdate[index].labelError = null;
    toUpdate[index][type] = value;
    setChoices(toUpdate);
  };

  const handleTypeChange = (value) => {
    setMinimum(null);
    setMaximum(null);
    setType(value);
  };

  const handleRangeChange = (value, type) => {
    const newValue = parseInt(value);
    if (type === "minimum") {
      if (maximum === null || value <= maximum)
        setMinimum(value ? newValue : null);
    } else if (type === "maximum") {
      if (minimum === null || value >= minimum)
        setMaximum(value ? newValue : null);
    }
  };

  return (
    <Modal onClose={closeModal} open={true} className="options-search-modal">
      <Modal.Header>
        {optionId ? "Modification du modèle d'option" : "Options existantes"}
      </Modal.Header>

      <Modal.Content className="content">
        <Search
          className="search-input"
          loading={search.loading}
          onResultSelect={(e, { result }) => onOptionSelect(result)}
          onSearchChange={(e, { value }) => setSearchLabel(value)}
          resultRenderer={formatOptions}
          results={search.response}
          value={searchLabel}
          minCharacters={3}
          noResultsMessage="Aucune option trouvée"
        />

        {optionId && (
          <WithLoader
            loading={get.loading}
            modalMode
            text="Récupération de l'option"
          >
            <Row fluid className="options-modal">
              <Column style={{ flexBasis: "40%" }}>
                <Form>
                  <Form.Field>
                    <Header as="h3">Nom</Header>
                    <Form.Input
                      placeholder="Nouvelle option"
                      value={label}
                      onChange={(e, { value }) => {
                        setLabelError(null);
                        setLabel(value);
                      }}
                      error={labelError}
                    />
                  </Form.Field>
                </Form>

                <Form style={{ marginTop: "2rem" }}>
                  <Header as="h3">Type</Header>
                  <Form.Field>
                    <Radio
                      label="Selection à choix unique"
                      name="radioGroup"
                      value="SINGLE"
                      checked={type === "SINGLE"}
                      onChange={(e, { value }) => handleTypeChange(value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Selection à choix multiple"
                      name="radioGroup"
                      value="MULTIPLE"
                      checked={type === "MULTIPLE"}
                      onChange={(e, { value }) => handleTypeChange(value)}
                    />
                  </Form.Field>
                </Form>

                <Form style={{ marginTop: "2rem" }}>
                  <Header as="h3">Divers</Header>
                  <Form.Field>
                    <Checkbox
                      checked={required}
                      onChange={(e, { checked }) => setRequired(checked)}
                      toggle
                      label="Requis pour la commande"
                    />
                  </Form.Field>

                  {type === "MULTIPLE" && (
                    <Form.Group inline>
                      <Form.Field>
                        <Input
                          value={minimum}
                          onChange={(e, { value }) =>
                            handleRangeChange(value, "minimum")
                          }
                          placeholder="Min"
                          type="number"
                          min="0"
                          className="choice-range-input"
                        />
                      </Form.Field>
                      <Form.Field>
                        <Input
                          value={maximum}
                          onChange={(e, { value }) =>
                            handleRangeChange(value, "maximum")
                          }
                          placeholder="Max"
                          type="number"
                          min={minimum}
                          className="choice-range-input"
                        />
                      </Form.Field>
                    </Form.Group>
                  )}
                </Form>
              </Column>

              <VerticalDivider />

              <Column fluid>
                <Row align="center" fluid justify="space-between">
                  <Header as="h3">Choix selection</Header>
                  <Button
                    icon="plus"
                    color="blue"
                    content="Nouveau choix"
                    basic
                    style={{ marginLeft: "1rem" }}
                    onClick={() =>
                      setChoices([...choices, { ...DEFAULT_CHOICE }])
                    }
                  />
                </Row>

                {choices.map((choice, index) => (
                  <Row
                    className="choice-row"
                    childrenStyle={{ margin: "0 1rem " }}
                    align="center"
                    key={`choice-${index}`}
                  >
                    {type === "SINGLE" ? (
                      <Radio checked={false} disabled />
                    ) : (
                      <Checkbox checked={false} disabled />
                    )}

                    <Input
                      value={choice.label}
                      onChange={(e, { value }) =>
                        handleChoiceUpdate(value, "label", index)
                      }
                      autoFocus
                      placeholder="Nouveau choix"
                      error={choice.labelError}
                    />

                    <Popup
                      content="Prix public"
                      inverted
                      trigger={
                        <Input
                          value={choice.price}
                          onChange={(e, { value }) =>
                            handleChoiceUpdate(value, "price", index)
                          }
                          label="€"
                          fluid
                          placeholder="Prix"
                          type="number"
                          step="any"
                          min="0"
                          className="choice-price-input"
                        />
                      }
                    />

                    <Popup
                      content="Prix restaurant"
                      inverted
                      trigger={
                        <Input
                          value={choice.originalPrice}
                          onChange={(e, { value }) =>
                            handleChoiceUpdate(value, "originalPrice", index)
                          }
                          label="€"
                          fluid
                          placeholder="Prix"
                          type="number"
                          step="any"
                          min="0"
                          className="choice-price-input"
                        />
                      }
                    />

                    <Checkbox
                      checked={choice.available}
                      onChange={(e, { checked }) =>
                        handleChoiceUpdate(checked, "available", index)
                      }
                      toggle
                    />

                    <Button
                      icon="trash"
                      negative
                      basic
                      onClick={() =>
                        setChoices(choices.filter((c, j) => j !== index))
                      }
                    />
                  </Row>
                ))}
              </Column>
            </Row>

            {errorMessage && (
              <Message negative>
                <Message.Header>Erreur</Message.Header>
                <p>{errorMessage}</p>
              </Message>
            )}
          </WithLoader>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button color="black" onClick={closeModal} loading={search.loading}>
          Retour
        </Button>
        <Button
          content="Modifier le modèle"
          labelPosition="right"
          icon="edit"
          color="blue"
          onClick={handleOptionUpdate}
          disabled={!optionId}
          loading={search.loading}
        />
        <Button
          content="Assigner au produit"
          labelPosition="right"
          icon="checkmark"
          positive
          disabled={!optionId}
          onClick={handleOptionAssign}
          loading={search.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default OptionsSearchModal;
