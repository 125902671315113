import { TimePicker, TimePrecision } from "@blueprintjs/datetime";
import hourify from "../../../resources/helpers/hourify";
import { useMemo, useState } from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import { useRequest } from "../../../resources/hooks";
import { fetchDeliverymen } from "../../../services/orders";
import { Column, Divider, Row } from "../../common";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

const byFullname = (a, b) =>
  `${a.firstname} ${a.lastname}` < `${b.firstname} ${b.lastname}` ? -1 : 1;

const AssignModal = ({
  orderId,
  restaurantNames,
  orderNumber,
  assign,
  closeModal,
}) => {
  const getDeliverymen = useRequest({ service: fetchDeliverymen });
  const [deliverymanId, setDeliverymanId] = useState(null);
  const [pickupTime, setPickupTime] = useState(new Date());
  const pickupDelay = pickupTime - new Date();

  const deliverymen = useMemo(() => {
    const temp = [...(getDeliverymen.response || [])].sort(byFullname);
    return temp.map((d) => ({
      key: `${d.firstname} ${d.lastname}`,
      text: `${d.firstname} ${d.lastname}`,
      value: d.id,
    }));
  }, [getDeliverymen.response]);

  return (
    <Modal dimmer="blurring" open onClose={closeModal} size="small">
      <Modal.Header>
        <Row justify="space-between">
          <span>Assigner une commande</span>
          <span style={{ opacity: 0.5 }}>Commande {orderNumber}</span>
        </Row>
      </Modal.Header>
      <Modal.Content>
        <p>
          <Dropdown
            placeholder="Choix livreur"
            inline
            scrolling
            loading={getDeliverymen.loading}
            disabled={getDeliverymen.loading}
            onChange={(e, { value }) => {
              setDeliverymanId(value);
            }}
            options={deliverymen}
          />
          <span>&nbsp; ira chercher la commande {orderNumber} pour &nbsp;</span>
          <TimePicker
            className="time-picker-inline"
            autoFocus
            precision={TimePrecision.MINUTE}
            value={pickupTime}
            minTime={new Date()}
            selectAllOnFocus
            onChange={(newTime) => setPickupTime(newTime)}
          />

          <span style={{ opacity: 0.4 }}>
            &nbsp; (dans{" "}
            {pickupDelay > 0 ? hourify(pickupDelay, "milliseconds") : "0mn"}
            )&nbsp;
          </span>

          <Column>
            <strong style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
              Restaurants :
            </strong>
            <Column fluid childrenMargin="tiny">
              {restaurantNames?.length &&
                restaurantNames.map((restaurantName) => (
                  <>
                    <span>{restaurantName}</span>
                    <Divider />
                  </>
                ))}
            </Column>
          </Column>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <span style={{ opacity: 0.3 }}>Veuillez choisir un livreur</span>
        <Button
          positive
          disabled={!deliverymanId || !pickupTime}
          onClick={() => assign(orderId, deliverymanId, pickupTime)}
        >
          Attribuer la commande
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AssignModal;
