import React from "react";

const Badge = ({
  backgroundColor,
  color,
  state,
  fontSize = "12px",
  fontWeight,
  marginLeft,
  borderRadius = "20px",
  padding = "2px 7px",
  text,
  children,
  valid,
  error,
  warning,
  passive,
  inline,
}) => {
  return (
    <span
      style={{
        backgroundColor:
          state === "valid" || valid
            ? "#67bc75"
            : state === "error" || error
            ? "#bc6767"
            : state === "warning" || warning
            ? "#bca667"
            : state === "passive" || passive
            ? "#bfbfbf"
            : backgroundColor,
        color:
          state === "valid" || valid
            ? "white"
            : state === "error" || error
            ? "white"
            : state === "warning" || warning
            ? "white"
            : state === "passive" || passive
            ? "black"
            : color,
        padding,
        borderRadius,
        marginLeft: marginLeft ? marginLeft : inline ? "5px" : "0",
        fontSize,
        fontWeight,
        whiteSpace: "nowrap",
        width: "fit-content",
      }}
    >
      {children || text}
    </span>
  );
};

export default Badge;
