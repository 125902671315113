import { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Compressor from "compressorjs";
import { createRestaurant } from "services/restaurants";
import { useInput } from "resources/hooks";
import "styles/Restaurants.scss";
import { Column, ImageUpload, Row } from "../common";

const RestaurantsCreation = () => {
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);

  const label = useInput("");
  const phone = useInput("");
  const address = useInput("");
  const city = useInput("");
  const [image, setImage] = useState(null);

  const inputs = [label, phone, address, city];

  const [imageError, setImageError] = useState(null);

  const handleCreate = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (!image) {
      setImageError({
        content: "Obligatoire",
      });
      hasErrors = true;
    }

    if (hasErrors) return;

    try {
      setIsCreating(true);

      const newRestaurant = await createRestaurant({
        label: label.value,
        phone: phone.value,
        address: address.value,
        city: city.value,
        image,
      });

      history.push("/restaurants");
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  };

  const handleImageUpload = (image) => {
    setImageError(null);
    new Compressor(image, {
      quality: 0.8,
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: Infinity, // disable .png to .jpg conversion to keep image transparency
      success(result) {
        setImage(result);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  return (
    <div className="restaurant-creation data-container">
      <Row childrenMargin="small">
        <Column style={{ flexBasis: "45%" }}>
          <ImageUpload
            image={image}
            handleImageUpload={handleImageUpload}
            error={!!imageError}
          />
        </Column>

        <Column style={{ flexBasis: "55%" }}>
          <Form>
            <Form.Group className="form-row">
              <Form.Field width={8}>
                <Form.Input label="Nom de l'établissement" {...label.props} />
              </Form.Field>
            </Form.Group>

            <Form.Group className="form-row">
              <Form.Field width={8}>
                <Form.Input label="Adresse" {...address.props} />
              </Form.Field>

              <Form.Field width={8}>
                <Form.Input label="Ville" {...city.props} />
              </Form.Field>

              <Form.Field width={8}>
                <Form.Input label="Téléphone" {...phone.props} />
              </Form.Field>
            </Form.Group>

            <Button
              onClick={handleCreate}
              loading={isCreating}
              disabled={isCreating}
              color="green"
            >
              Créer
            </Button>
          </Form>
        </Column>
      </Row>
    </div>
  );
};

export default RestaurantsCreation;
