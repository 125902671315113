import { Switch } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { RouteWithSubRoutes } from "../../resources/helpers";
import "../../styles/Analysis.scss";

const AnalysisView = ({ routes }) => {
  const history = useHistory();

  return (
    <div id="analysis">
      <Menu pointing secondary>
        <Menu.Item
          name="Livreurs"
          active={history.location.pathname === "/analysis/deliverymen"}
          onClick={() => history.push("/analysis/deliverymen")}
        />
        <Menu.Item
          name="Restaurants"
          active={history.location.pathname === "/analysis/restaurants"}
          onClick={() => history.push("/analysis/restaurants")}
        />
        <Menu.Item
          name="Facturation"
          active={
            history.location.pathname === "/analysis/restaurants/invoices"
          }
          onClick={() => history.push("/analysis/restaurants/invoices")}
        />
      </Menu>

      <Switch>
        {routes.map((route, index) => (
          <RouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
};

export default AnalysisView;
