import { useState } from "react";
import { Search } from "semantic-ui-react";
import { useDebounce, useRequest } from "../../resources/hooks";
import * as DeliveryAreaService from "../../services/deliveryAreas";

const MIN_CHARACTER_INPUT = 5;

const AddressSearch = ({ userAddress, onSelect }) => {
  const [addressLabel, setAddressLabel] = useState(userAddress);

  const searchAddress = useRequest({
    service: DeliveryAreaService.searchAddress,
    requestOnInit: false,
  });

  useDebounce(addressLabel, {
    minimumChars: MIN_CHARACTER_INPUT,
    onTimeout: () => {
      searchAddress.request({ params: { address: addressLabel } });
    },
  });

  const handleSelection = (address) => {
    onSelect(address);
  };

  return (
    <Search
      loading={searchAddress.loading}
      onResultSelect={(e, { result }) => handleSelection(result.title)}
      onSearchChange={(e, { value }) => setAddressLabel(value)}
      results={searchAddress.response?.map((address) => ({
        title: address.label,
      }))}
      minCharacters={MIN_CHARACTER_INPUT}
      placeholder="Rechercher une adresse"
      noResultsMessage="Aucune adresse correspondante 😅"
      fluid
      defaultValue={userAddress ?? undefined}
    />
  );
};

export default AddressSearch;
