import Row from "./Row";
import { Form, Button } from "semantic-ui-react";
import "../../styles/common.scss";
import { useState } from "react";

const HourRange = ({ initialStart, initialEnd, handleSubmit }) => {
  const [start, setStart] = useState(initialStart);
  const [end, setEnd] = useState(initialEnd);
  const [startError, setStartError] = useState(null);
  const [endError, setEndError] = useState(null);

  const submit = () => {
    let hasErrors = false;

    if (!/^\d{2}:\d{2}$/.test(start)) {
      setStartError("Doit respecter le format HH:MM");
      hasErrors = true;
    }

    if (!/^\d{2}:\d{2}$/.test(end)) {
      setEndError("Doit respecter le format HH:MM");
      hasErrors = true;
    }

    if (hasErrors) return;

    const [startHours, startMinutes] = start.split(":");
    const [endHours, endMinutes] = end.split(":");

    if (
      startHours < 0 ||
      startHours > 23 ||
      startMinutes < 0 ||
      startMinutes > 59
    ) {
      setStartError("Heures < 24 & Minutes < 60");
      hasErrors = true;
    }

    if (endHours < 0 || endHours > 23 || endMinutes < 0 || endMinutes > 59) {
      setEndError("Heures < 24 & Minutes < 60");
      hasErrors = true;
    }

    if (hasErrors) return;

    const startDate = new Date(new Date().setHours(startHours, startMinutes));
    const endDate = new Date(new Date().setHours(endHours, endMinutes));

    if (startDate >= endDate) {
      setStartError("Doit précéder l'heure de fin");
      hasErrors = true;
    }

    if (hasErrors) return;

    handleSubmit(start, end);
  };

  return (
    <Row className="hour-range-editor">
      <Form.Input
        label="Ouverture"
        value={start}
        onChange={(e, d) => {
          setStart(d.value);
          setStartError(null);
        }}
        placeholder="11:00"
        error={startError}
        autoFocus
      />

      <Form.Input
        label="Fermeture"
        value={end}
        onChange={(e, d) => {
          setEnd(d.value);
          setEndError(null);
        }}
        placeholder="16:00"
        error={endError}
      />

      <Button
        onClick={submit}
        icon="check"
        color="green"
        className="submit-hour-range"
      />
    </Row>
  );
};

export default HourRange;
