import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchRestaurantInvoices({ startDate, endDate }) {
  const { data } = await axios.get(`/api/analysis/invoices/restaurants`, {
    params: { startDate, endDate },
  });

  return data;
}
