import React, { useEffect, useState } from "react";
import { Button, Table, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Row, WithLoader, SortableItem } from "../common";
import { getRestaurantId } from "../../resources/helpers";
import {
  fetchProductCategories,
  deleteProductCategory,
  reorderProductCategories,
} from "../../services/productCategories";
import {
  ProductCategoriesUpdate,
  ProductCategoriesCreate,
} from "../productCategories";
import { useRequest } from "../../resources/hooks";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const ProductCategoriesTable = ({ history, location, create }) => {
  const restaurantId = getRestaurantId(location);

  const redirectToProducts = (categoryId) => {
    history.push(
      `/restaurants/${restaurantId}/categories/${categoryId}/products`,
      {
        restaurantId,
      }
    );
  };

  const [isCreating, setIsCreating] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const getAll = useRequest({
    service: () => fetchProductCategories(restaurantId),
  });
  const remove = useRequest({
    service: () => deleteProductCategory(deleteId),
    requestOnInit: false,
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(getAll.response);
  }, [getAll.response]);

  const handleDelete = async () => {
    await remove.request();
    setDeleteId(null);
    getAll.request();
  };

  useEffect(() => {
    if (create) setIsCreating(true);
  }, [create]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  async function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = categories.findIndex(
        (category) => category.id === active.id
      );
      const newIndex = categories.findIndex(
        (category) => category.id === over.id
      );

      const newOrder = arrayMove(categories, oldIndex, newIndex);

      setCategories(newOrder);
      await reorderProductCategories({
        productCategories: newOrder,
      });
      await getAll.request();
    }
  }

  return (
    <WithLoader
      loading={getAll.loading || remove.loading}
      text="Récupération des catégories"
    >
      <div className="data-container">
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Catégorie</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={categories}
                strategy={verticalListSortingStrategy}
              >
                {categories?.length > 0 &&
                  categories.map((category) => (
                    <Table.Row key={category.id}>
                      <Table.Cell>
                        <SortableItem
                          key={category.id}
                          id={category.id}
                          style={{
                            cursor: "grab",
                          }}
                        >
                          <Icon name="bars" size="large" />
                          {category.label}
                        </SortableItem>
                      </Table.Cell>
                      <Table.Cell>
                        <Row>
                          <Button
                            icon
                            color="violet"
                            labelPosition="left"
                            onClick={() => redirectToProducts(category.id)}
                          >
                            <Icon name="food" />
                            Produits
                          </Button>
                          <Button
                            icon
                            color="blue"
                            labelPosition="left"
                            onClick={() => setUpdateId(category.id)}
                          >
                            <Icon name="edit" />
                            Editer
                          </Button>
                          <Button
                            icon
                            color="red"
                            labelPosition="left"
                            onClick={() => {
                              if (deleteId === category.id) {
                                handleDelete();
                              } else {
                                setDeleteId(category.id);
                                setTimeout(() => setDeleteId(null), 3000);
                              }
                            }}
                          >
                            <Icon name="delete" />
                            {deleteId === category.id
                              ? "Confirmer ?"
                              : "Supprimer"}
                          </Button>
                        </Row>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </SortableContext>
            </DndContext>
          </Table.Body>
        </Table>
      </div>

      {updateId && (
        <ProductCategoriesUpdate
          categoryId={updateId}
          closeModal={({ withReload }) => {
            setUpdateId(null);
            if (withReload) {
              getAll.request();
            }
          }}
        />
      )}
      {isCreating && (
        <ProductCategoriesCreate
          closeModal={({ withRedirect }) => {
            setIsCreating(null);
            if (withRedirect) {
              history.push(withRedirect);
            }
          }}
        />
      )}
    </WithLoader>
  );
};

export default withRouter(ProductCategoriesTable);
