import React, { useEffect, useState } from "react";
import { Button, Table, Icon, Label } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { NoDataMessage, Row, WithLoader } from "components/common";
import {
  getCategoryId,
  getProductMargin,
  getRestaurantId,
} from "resources/helpers";
import { deleteProduct, fetchProducts } from "services/products";
import { useRequest } from "resources/hooks";
import { ProductCreate, ProductUpdate } from "./";
import { centimesToAmount } from "../../resources/helpers/PriceHelper";

const ProductsTable = ({ history, location, match, routes, create }) => {
  const restaurantId = getRestaurantId(location);
  const categoryId = getCategoryId(location);

  const [isCreating, setIsCreating] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const getAll = useRequest({
    service: () => fetchProducts({ categoryId }),
  });
  const remove = useRequest({
    service: () => deleteProduct({ productId: deleteId }),
    requestOnInit: false,
  });

  const products = getAll.response;
  const handleDelete = async () => {
    await remove.request();
    setDeleteId(null);
    getAll.request();
  };

  useEffect(() => {
    if (create) setIsCreating(true);
  }, [create]);

  const redirectToProductShow = (productId) => {
    history.push(
      `/restaurants/${restaurantId}/categories/${categoryId}/products/${productId}/show`,
      {
        restaurantId,
        categoryId,
        productId,
      }
    );
  };

  return (
    <WithLoader loading={getAll.loading} text="Récupération des produits">
      <div className="data-container">
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Prix RestosBussy</Table.HeaderCell>
              <Table.HeaderCell>Prix restaurant</Table.HeaderCell>
              <Table.HeaderCell>Disponible ?</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {products?.length > 0 &&
              products.map((product) => (
                <Table.Row key={product.id}>
                  <Table.Cell>
                    {product.publicUrl ? (
                      <img
                        src={product.publicUrl}
                        className="product-image"
                        alt="product-illustration"
                      />
                    ) : (
                      <NoDataMessage center>N/A</NoDataMessage>
                    )}
                  </Table.Cell>
                  <Table.Cell>{product.label}</Table.Cell>
                  <Table.Cell>{product.description}</Table.Cell>
                  <Table.Cell>{`${centimesToAmount(product.price)} €`}</Table.Cell>
                  <Table.Cell>
                    <Row
                      align="center"
                      childrenMargin="tiny"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <span>{`${centimesToAmount(product.originalPrice)} €`} </span>
                      <Label size="tiny" color="green">
                        {getProductMargin(product.price, product.originalPrice)}
                        %
                      </Label>
                    </Row>
                  </Table.Cell>
                  <Table.Cell>{product.available ? "✅" : "❌"}</Table.Cell>
                  <Table.Cell>
                    <Row>
                      <Button
                        icon="edit"
                        color="blue"
                        onClick={() => setUpdateId(product.id)}
                      />
                      <Button
                        icon
                        color="blue"
                        labelPosition="left"
                        onClick={() => redirectToProductShow(product.id)}
                      >
                        <Icon name="zoom-in" />
                        Détails
                      </Button>
                      <Button
                        icon
                        color="red"
                        onClick={() => {
                          if (deleteId === product.id) {
                            handleDelete();
                          } else {
                            setDeleteId(product.id);
                            setTimeout(() => setDeleteId(null), 3000);
                          }
                        }}
                      >
                        {deleteId === product.id ? (
                          <span style={{ whiteSpace: "nowrap" }}>
                            Confirmer ?
                          </span>
                        ) : (
                          <Icon name="delete" />
                        )}
                      </Button>
                    </Row>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>

      {updateId && (
        <ProductUpdate
          productId={updateId}
          closeModal={({ withReload }) => {
            setUpdateId(null);
            if (withReload) {
              getAll.request();
            }
          }}
        />
      )}

      {isCreating && (
        <ProductCreate
          closeModal={({ withRedirect }) => {
            setIsCreating(null);
            if (withRedirect) {
              history.push(withRedirect);
            }
          }}
        />
      )}
    </WithLoader>
  );
};

export default withRouter(ProductsTable);
