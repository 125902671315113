import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { WithLoader } from "../common";
import { useRequest, useInput } from "../../resources/hooks";
import {
  fetchProductCategory,
  updateProductCategory,
} from "../../services/productCategories";

const ProductCategoriesUpdate = ({ categoryId, closeModal }) => {
  const get = useRequest({
    service: () => fetchProductCategory(categoryId),
  });
  const update = useRequest({
    service: updateProductCategory,
    requestOnInit: false,
  });
  // TODO: log error
  const label = useInput("");

  const inputs = [label];

  useEffect(() => {
    label.setValue(get.response?.label);
  }, [get.response]);

  const handleUpdate = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (hasErrors) return;

    const result = await update.request({
      params: {
        categoryId,
        label: label.value,
      },
    });

    if (result.response) closeModal({ withReload: true });
  };

  const [open, setOpen] = useState(true);

  return (
    <Modal onClose={() => setOpen(false)} open={open} size="tiny">
      <Modal.Header>Modifier une catégorie</Modal.Header>

      <Modal.Content>
        <WithLoader
          loading={get.loading}
          text="Récupération de la catégorie"
          modalMode
        >
          <Form>
            <Form.Group widths={6}>
              <Form.Field width={6}>
                <Form.Input label="Nom de catégorie" {...label.props} />
              </Form.Field>
            </Form.Group>
          </Form>
        </WithLoader>
      </Modal.Content>

      <Modal.Actions>
        <Button color="black" loading={update.loading} onClick={closeModal}>
          Retour
        </Button>
        <Button
          content="Valider"
          labelPosition="right"
          icon="checkmark"
          onClick={handleUpdate}
          positive
          loading={update.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default withRouter(ProductCategoriesUpdate);
