export const getPaymentMethod = (status) => {
  switch (status) {
    case "ONLINE_BANK_CARD":
      return paymentMethods.card_online;
    case "MANUAL":
      return paymentMethods.manual;
    case "MANUAL_BANK_CARD":
      return paymentMethods.card_manual;
    case "CASH":
      return paymentMethods.cash;
    default:
      return paymentMethods.unknown;
  }
};

export const paymentMethods = {
  card_online: {
    label: "CB en ligne",
    code: "card_online",
    status: "valid",
    type: "online",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#67bc75",
    },
    invertedStyle: {
      color: "#67bc75",
      borderColor: "#67bc75",
    },
  },
  manual: {
    label: "Manuel",
    code: "manual",
    status: "warning",
    type: "manual",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  card_manual: {
    label: "CB sur place",
    code: "card_manual",
    status: "warning",
    type: "manual",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  cash: {
    label: "Espèce",
    code: "cash",
    status: "warning",
    type: "manual",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#f5bd1f",
    },
    invertedStyle: {
      color: "#f5bd1f",
      borderColor: "#f5bd1f",
    },
  },
  unknown: {
    label: "?",
    state: "passive",
    code: "unknown",
    type: "unknown",
    style: {
      color: "white",
      border: "none",
      backgroundColor: "#bdbdbd",
    },
    invertedStyle: {
      color: "#e3e3e3",
      borderColor: "#e3e3e3",
    },
  },
};
