export const getProductOptionsTotal = (
  productOptions,
  { restaurantView } = { restaurantView: false }
) =>
  restaurantView
    ? productOptions.reduce(
      (r, productOption) => r + productOption.originalPrice,
      0
    )
    : productOptions.reduce((r, productOption) => r + productOption.price, 0);

export const getProductTotal = (
  product,
  { restaurantView } = { restaurantView: false }
) => {
  return product.status === "CANCELED"
    ? 0
    : ((restaurantView ? product.originalPrice : product.price) +
      getProductOptionsTotal(product.orderProductOptions, {
        restaurantView,
      })) *
    product.quantity;
};

export const getTotal = (
  products,
  { restaurantView } = { restaurantView: false }
) => {
  return products.reduce(
    (r, product) => r + getProductTotal(product, { restaurantView }),
    0
  );
};

export const getOrderTotal = (
  order,
  { restaurantView } = { restaurantView: false }
) => {
  return getTotal(order.orderProducts, { restaurantView }) + getTip(order) + getDeliveryFee(order) - getDiscount(order);
};

export const getTip = (order) => {
  return order.orderExtraCosts?.find((oec) => oec.type === "TIP")?.amount || 0;
};

export const getDiscount = (order) => {
  return (
    order.orderExtraCosts?.find((oec) => oec.type === "DISCOUNT")?.amount || 0
  );
};

export const getDeliveryFee = (order) => {
  return (
    order.orderExtraCosts?.find((oec) => oec.type === "DELIVERY_FEE")?.amount ||
    0
  );
};
