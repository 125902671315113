// import Image from "next/image";
import { useState } from "react";
import { Button, Checkbox, Form, Icon, TextArea } from "semantic-ui-react";
import { Column, Price, Row, Divider, CircularButton } from "components/common";
import { renderCentimesAsAmount } from "resources/helpers/PriceHelper";

const MAX_COMMENTS_LENGTH = 200;

const countChecked = (option, selectedChoiceIds) => {
  const availableOptionIds = option.productOptionChoices.map(
    (choice) => choice.id
  );
  return selectedChoiceIds.filter((selected) =>
    availableOptionIds.some(
      (availableOptionId) => availableOptionId === selected
    )
  ).length;
};

const ProductModal = ({ open, closeModal, product, onProductAdd }) => {
  const handleAddProduct = async () => {
    onProductAdd({
      productId: product.id,
      restaurantId: product.restaurantId,
      comments,
      quantity: desiredQuantity,
      optionChoiceIds: selectedChoiceIds,
    });

    closeModal();
  };

  const [selectedChoiceIds, setSelectedChoiceIds] = useState([]);

  const handleChoiceUpdate = (option, selectedChoiceId, checked) => {
    if (option.type === "SINGLE") {
      if (selectedChoiceIds.includes(selectedChoiceId)) {
        setSelectedChoiceIds(
          selectedChoiceIds.filter((selectedId) => !selectedId)
        );
      } else {
        const availableOptionIds = option.productOptionChoices.map(
          (choice) => choice.id
        );

        let update = selectedChoiceIds.filter(
          (selected) => !availableOptionIds.includes(selected)
        );
        update = [...update, selectedChoiceId];
        setSelectedChoiceIds(update);
      }
    } else if (option.type === "MULTIPLE") {
      // Check if maximum choices have been made
      if (checked && option.maximum) {
        const availableOptionIds = option.productOptionChoices.map(
          (choice) => choice.id
        );
        const countCheckedChoices = selectedChoiceIds.filter((selected) =>
          availableOptionIds.some(
            (availableOptionId) => availableOptionId === selected
          )
        ).length;

        // TODO: error/warning message
        if (countCheckedChoices >= option.maximum) return;
      }

      if (checked) {
        setSelectedChoiceIds([...selectedChoiceIds, selectedChoiceId]);
      } else {
        setSelectedChoiceIds(
          selectedChoiceIds.filter(
            (selectedId) => selectedId !== selectedChoiceId
          )
        );
      }
    }
  };

  const [comments, setComments] = useState("");

  const handleCommentsUpdate = (text) => {
    if (text.length <= MAX_COMMENTS_LENGTH) {
      setComments(text);
    }
  };

  const [desiredQuantity, setDesiredQuantity] = useState(1);

  const handleQuantityUpdate = (delta) => {
    if (delta < 0 && desiredQuantity <= 1) return;
    setDesiredQuantity(desiredQuantity + delta);
  };

  const productHasErrors = () => {
    const hasErrors = product?.productOptions?.some((option) => {
      if (option.required) {
        if (option.type === "MULTIPLE") {
          if (
            option.minimum &&
            countChecked(option, selectedChoiceIds) < option.minimum
          ) {
            return true;
          }
          if (
            option.maximum &&
            countChecked(option, selectedChoiceIds) > option.maximum
          ) {
            return true;
          }
        } else if (option.type === "SINGLE") {
          if (countChecked(option, selectedChoiceIds) < 1) {
            return true;
          }
        }
      } else {
        if (
          option.minimum &&
          countChecked(option, selectedChoiceIds) < option.minimum
        ) {
          return true;
        }
        if (
          option.maximum &&
          countChecked(option, selectedChoiceIds) > option.maximum
        ) {
          return true;
        }
      }

      return false;
    });

    return hasErrors;
  };

  return (
    product && (
      <div
        className="product-modal"
        style={{ display: open ? "flex" : "none" }}
      >
        <Column className="content" childrenMargin="medium">
          <Column className="header" childrenMargin="small">
            <CircularButton
              className="close-icon"
              style={{ top: "-20px", right: "-36px" }}
              onClick={closeModal}
            >
              <Icon name="close" fitted />
            </CircularButton>

            <Row justify="space-between" style={{ marginTop: "1rem" }}>
              <span className="title">{product.label}</span>

              <Price fontSize={1.5}>
                {renderCentimesAsAmount(product.price)}
              </Price>
            </Row>

            <Row>
              <span className="ingredients">{product.description}</span>
            </Row>
          </Column>

          <div className="body">
            <Column className="options" childrenMargin="medium">
              {product?.productOptions.map((option) => (
                <Form key={option.id}>
                  <Form.Field className="option-label">
                    <Row justify="space-between">
                      <b>{option.label}</b>
                      <div style={{ display: "flex", gap: "3px" }}>
                        <span>
                          {option.type === "SINGLE"
                            ? "Choix unique"
                            : `${
                                option.maximum
                                  ? `maximum ${option.maximum}`
                                  : ""
                              } ${
                                option.maximum && option.minimum ? "- " : ""
                              }${
                                option.minimum
                                  ? `minimum ${option.minimum}`
                                  : ""
                              }`}
                        </span>
                        {option.required && <span>- requis</span>}
                      </div>
                    </Row>
                  </Form.Field>
                  <Divider noMargin />
                  <div className="choices">
                    {option.productOptionChoices.map((choice, choiceIndex) => (
                      <Form.Field key={choice.label}>
                        <Checkbox
                          readOnly={!choice.available}
                          disabled={!choice.available}
                          radio={option.type === "SINGLE" && option.required}
                          label={choice.label}
                          name={option.label}
                          value={choice.label}
                          checked={selectedChoiceIds.includes(choice.id)}
                          onChange={(e, { checked }) =>
                            handleChoiceUpdate(option, choice.id, checked)
                          }
                        />
                        {choice.price > 0 && (
                          <span className="choice-price">
                            {renderCentimesAsAmount(choice.price, {
                              minimumFractionDigits: 0,
                            })}
                          </span>
                        )}
                      </Form.Field>
                    ))}
                  </div>
                </Form>
              ))}

              <Form>
                <Form.Field className="comment-label">
                  <b>Une demande particulière ?</b>
                </Form.Field>
                <Form.Field
                  className=""
                  error={comments.length === MAX_COMMENTS_LENGTH}
                >
                  <TextArea
                    placeholder="Dites nous tout !"
                    value={comments}
                    onChange={(e, { value }) => handleCommentsUpdate(value)}
                  />
                </Form.Field>
              </Form>
            </Column>
          </div>

          <Row className="footer" justify="space-between" align="center">
            <Row align="center" gap="1rem">
              <CircularButton
                inverted
                disabled={desiredQuantity <= 1}
                onClick={() => handleQuantityUpdate(-1)}
              >
                -
              </CircularButton>
              <span className="desired-quantity">{desiredQuantity}</span>
              <CircularButton onClick={() => handleQuantityUpdate(+1)}>
                +
              </CircularButton>
            </Row>

            <Button
              className="add-product-button"
              onClick={handleAddProduct}
              disabled={productHasErrors()}
            >
              Ajouter au panier
            </Button>
          </Row>
        </Column>
      </div>
    )
  );
};

export default ProductModal;
